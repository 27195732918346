import axios from 'axios';

const OPENAI_API_URL = 'https://api.openai.com/v1/chat/completions';
const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

const MAX_RETRIES = 5;
const INITIAL_RETRY_DELAY = 1000; // 1 second

const axiosInstance = axios.create({
  baseURL: OPENAI_API_URL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${OPENAI_API_KEY}`,
  },
});

const exponentialBackoff = (retryCount) => {
  return Math.pow(2, retryCount) * INITIAL_RETRY_DELAY;
};

export const generatePostContent = async (topic, summary) => {
  let retryCount = 0;

  while (retryCount < MAX_RETRIES) {
    try {
      const response = await axiosInstance.post('', {
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content:
              'You are a helpful assistant that generates blog post content.',
          },
          {
            role: 'user',
            content: `Generate a blog post about "${topic}". Here's a summary of what I want: "${summary}". Please provide a well-structured post with an introduction, main points, and a conclusion.`,
          },
        ],
        max_tokens: 500,
      });

      return response.data.choices[0].message.content.trim();
    } catch (error) {
      if (error.response && error.response.status === 429) {
        retryCount++;
        const delay = exponentialBackoff(retryCount);
        console.log(`Rate limited. Retrying in ${delay / 1000} seconds...`);
        await new Promise((resolve) => setTimeout(resolve, delay));
      } else {
        console.error('Error generating content with OpenAI:', error);
        throw new Error('Failed to generate content');
      }
    }
  }

  throw new Error('Max retries reached. Failed to generate content.');
};
