import React, { useState, useEffect } from 'react';
import { FaTrash, FaCheck, FaStar } from 'react-icons/fa';
import { useAuth } from '../contexts/AuthContext';
import '../styles/feedbackManagement.css';
import {
  collection,
  query,
  orderBy,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  serverTimestamp,
} from 'firebase/firestore';
import { db } from '../firebase';

// Match the types from FeedbackForm
const FEEDBACK_TYPES = {
  feedback: 'General Feedback',
  suggestion: 'Suggestion',
  feature: 'Feature Request',
  error: 'Error Report',
};

const RATING_AREAS = {
  usability: 'Ease of Use',
  design: 'Design & Layout',
  performance: 'Performance',
  features: 'Features & Functionality',
};

function FeedbackManagement() {
  const [feedbacks, setFeedbacks] = useState([]);
  const [selectedFeedback, setSelectedFeedback] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser } = useAuth();

  // Fetch feedbacks
  const loadFeedbacks = async () => {
    if (!currentUser?.isAdmin) {
      setError('Admin access required');
      setLoading(false);
      return;
    }

    try {
      const feedbacksRef = collection(db, 'feedback');
      const q = query(feedbacksRef, orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(q);

      const feedbackData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
      }));

      setFeedbacks(feedbackData);
      setError(null);
    } catch (err) {
      console.error('Error loading feedbacks:', err);
      setError('Failed to load feedbacks');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadFeedbacks();
  }, [currentUser]);

  // Mark feedback as read
  const handleMarkAsRead = async (feedbackId) => {
    try {
      const feedbackRef = doc(db, 'feedback', feedbackId);
      await updateDoc(feedbackRef, {
        status: 'read',
        updatedAt: serverTimestamp(),
        updatedBy: currentUser.uid,
      });
      await loadFeedbacks();
    } catch (err) {
      console.error('Error marking as read:', err);
      alert('Failed to update feedback status');
    }
  };

  // Delete feedback
  const handleDelete = async (feedbackId) => {
    if (!window.confirm('Are you sure you want to delete this feedback?'))
      return;

    try {
      await deleteDoc(doc(db, 'feedback', feedbackId));
      await loadFeedbacks();
      if (selectedFeedback?.id === feedbackId) {
        setSelectedFeedback(null);
      }
    } catch (err) {
      console.error('Error deleting feedback:', err);
      alert('Failed to delete feedback');
    }
  };

  // Render star ratings
  const renderRatings = (ratings) => {
    if (!ratings) return null;

    return Object.entries(ratings).map(([key, value]) => (
      <div key={key} className="rating-item">
        <span className="rating-label">{RATING_AREAS[key]}:</span>
        <div className="rating-stars">
          {[...Array(5)].map((_, i) => (
            <FaStar key={i} className={i < value ? 'star active' : 'star'} />
          ))}
        </div>
      </div>
    ));
  };

  if (loading) return <div className="loading">Loading feedbacks...</div>;
  if (error) return <div className="error">{error}</div>;
  if (!currentUser?.isAdmin)
    return <div className="error">Admin access required</div>;

  return (
    <div className="feedback-management">
      {/* Feedback List */}
      <div className="feedback-list">
        {feedbacks.map((feedback) => (
          <div
            key={feedback.id}
            className={`feedback-item ${
              feedback.status === 'read' ? 'read' : ''
            }`}
            onClick={() => setSelectedFeedback(feedback)}
          >
            <div className="feedback-header">
              <span className="feedback-type">
                {FEEDBACK_TYPES[feedback.type]}
              </span>
              <span className="feedback-date">
                {feedback.createdAt?.toLocaleDateString()}
              </span>
            </div>

            <div className="feedback-preview">
              {feedback.message.substring(0, 100)}
              {feedback.message.length > 100 ? '...' : ''}
            </div>

            <div className="feedback-meta">
              <span className="feedback-email">{feedback.userEmail}</span>
              <span className="feedback-status">{feedback.status}</span>
            </div>

            <div className="feedback-actions">
              {feedback.status !== 'read' && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleMarkAsRead(feedback.id);
                  }}
                  title="Mark as Read"
                >
                  <FaCheck />
                </button>
              )}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete(feedback.id);
                }}
                title="Delete"
                className="delete-btn"
              >
                <FaTrash />
              </button>
            </div>
          </div>
        ))}
      </div>

      {/* Feedback Details */}
      {selectedFeedback && (
        <div className="feedback-detail">
          <h3>Feedback Details</h3>
          <div className="detail-content">
            <div className="detail-header">
              <span className="type">
                {FEEDBACK_TYPES[selectedFeedback.type]}
              </span>
              <span className="date">
                {selectedFeedback.createdAt?.toLocaleString()}
              </span>
            </div>

            <div className="user-info">
              <h4>User Information</h4>
              <p>Email: {selectedFeedback.userEmail}</p>
              <p>User ID: {selectedFeedback.userId}</p>
              <p>Page URL: {selectedFeedback.url}</p>
              <p>User Agent: {selectedFeedback.userAgent}</p>
            </div>

            <div className="message-content">
              <h4>Message</h4>
              <p>{selectedFeedback.message}</p>
            </div>

            {selectedFeedback.ratings && (
              <div className="ratings-section">
                <h4>Ratings</h4>
                {renderRatings(selectedFeedback.ratings)}
              </div>
            )}

            {selectedFeedback.screenshot && (
              <div className="screenshot-section">
                <h4>Screenshot</h4>
                <div className="screenshot-preview">
                  <a
                    href={selectedFeedback.screenshot}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={selectedFeedback.screenshot}
                      alt="Feedback Screenshot"
                      loading="lazy"
                    />
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default FeedbackManagement;
