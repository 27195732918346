import React, { useState, useEffect } from 'react';
import { getAllPosts } from '../services/firestoreService';
import DOMPurify from 'dompurify';

function Blog() {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    setLoading(true);
    setError(null);
    try {
      const fetchedPosts = await getAllPosts();
      setBlogPosts(fetchedPosts);
    } catch (err) {
      console.error('Error fetching posts:', err);
      setError('Failed to fetch posts. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handlePostClick = (post) => {
    setSelectedPost(post);
  };

  const handleClosePost = () => {
    setSelectedPost(null);
  };

  const formatContent = (content) => {
    const sanitizedContent = DOMPurify.sanitize(content);
    return <div dangerouslySetInnerHTML={{ __html: sanitizedContent }} />;
  };

  const getSnippet = (content) => {
    const cleanContent = DOMPurify.sanitize(content, { ALLOWED_TAGS: [] });
    return cleanContent.length > 150
      ? cleanContent.slice(0, 150) + '...'
      : cleanContent;
  };

  if (loading) return <div className="loading">Loading posts...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className="blog-page">
      <section className="hero section">
        <div className="container">
          <h1 className="section-title">OECSL Blog</h1>
          <p className="subtitle">
            Stay updated with the latest trends and insights in engineering and
            computer science
          </p>
        </div>
      </section>

      <section className="blog-posts section">
        <div className="container">
          <div className="blog-grid">
            {blogPosts.map((post) => (
              <div
                key={post.id}
                className="blog-card"
                onClick={() => handlePostClick(post)}
              >
                <img
                  src={post.imageUrl || 'default-thumbnail.jpg'}
                  alt={post.title}
                  className="blog-thumbnail"
                />
                <div className="blog-card-content">
                  <h2 className="blog-title">{post.title}</h2>
                  <p className="blog-snippet">{getSnippet(post.content)}</p>
                  <button className="read-more-btn">Read More</button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {selectedPost && (
        <div className="post-preview-overlay" onClick={handleClosePost}>
          <div className="post-preview" onClick={(e) => e.stopPropagation()}>
            <button className="close-btn" onClick={handleClosePost}>
              ×
            </button>
            <h2 className="post-title">{selectedPost.title}</h2>
            <p className="post-date">{selectedPost.date}</p>
            {selectedPost.imageUrl && (
              <img
                src={selectedPost.imageUrl}
                alt={selectedPost.title}
                className="post-image"
              />
            )}
            <div className="post-content">
              {formatContent(selectedPost.content)}
            </div>
            {selectedPost.ctaUrl && (
              <a
                href={selectedPost.ctaUrl}
                className="cta-button"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More
              </a>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default Blog;
