import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../firebase';
import '../styles.css';

function Events() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    setLoading(true);
    setError(null);
    try {
      const eventsCollection = collection(db, 'events');
      const eventQuery = query(eventsCollection, orderBy('date', 'asc'));
      const eventSnapshot = await getDocs(eventQuery);
      const eventList = eventSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(eventList);
    } catch (err) {
      console.error('Error fetching events:', err);
      setError('Failed to fetch events. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const formatEventDate = (date) => {
    const options = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  if (loading) return <div className="loading">Loading events...</div>;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <div className="events-page">
      <section className="hero section">
        <div className="container">
          <h1 className="section-title">Upcoming Events</h1>
          <p className="subtitle">
            Expand your network, gain knowledge, and find inspiration at our
            carefully curated events.
          </p>
        </div>
      </section>

      <section className="event-list section">
        <div className="container">
          <div className="card-grid">
            {events.map((event) => {
              const eventDate = new Date(event.date);
              const isCompleted = eventDate < new Date();
              return (
                <div
                  key={event.id}
                  className={`card event-card ${
                    isCompleted ? 'completed' : ''
                  }`}
                >
                  <div className="event-image-container">
                    {event.imageUrl && (
                      <img
                        src={event.imageUrl}
                        alt={event.title}
                        className="event-image"
                      />
                    )}
                    {isCompleted && (
                      <div className="completed-overlay">Completed</div>
                    )}
                  </div>
                  <div className="card-content">
                    <h2 className="event-title">{event.title}</h2>
                    <div className="event-datetime">
                      <i className="far fa-calendar-alt"></i>
                      <span className="event-date">
                        {formatEventDate(event.date)}
                      </span>
                    </div>
                    <p className="event-description">{event.description}</p>
                    {event.location && (
                      <p className="event-location">
                        <i className="fas fa-map-marker-alt"></i>{' '}
                        {event.location}
                      </p>
                    )}
                    <div className="event-links">
                      {event.reservationLink && (
                        <a
                          href={event.reservationLink}
                          className="btn btn-primary event-btn"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Reserve Your Spot
                        </a>
                      )}
                      {event.liveStreamLink && (
                        <a
                          href={event.liveStreamLink}
                          className="btn btn-secondary event-btn"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Watch Live Stream
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Events;
