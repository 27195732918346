import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import {
  Link,
  useNavigate,
  useSearchParams,
  useLocation,
} from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';
import {
  validateInviteCode,
  createInvitationRequest,
} from '../services/firestoreService';
import '../styles/Register.css';
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  setDoc,
  increment,
  arrayUnion,
  serverTimestamp,
  getDoc,
} from 'firebase/firestore';
import { db, auth } from '../firebase';

function Register() {
  const [searchParams] = useSearchParams();
  const [showRequestForm, setShowRequestForm] = useState(false);
  const [requestData, setRequestData] = useState({
    email: '',
    name: '',
    loading: false,
    error: '',
    success: '',
  });

  const [formData, setFormData] = useState({
    invitationCode: searchParams.get('invite') || '',
    error: '',
    success: '',
    loading: false,
    isCodeValid: false,
    validatedCode: null,
  });

  const { signInWithGoogle } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  // Validate invitation code from URL
  useEffect(() => {
    const inviteCode = searchParams.get('invite');
    if (inviteCode) {
      setFormData((prev) => ({ ...prev, loading: true }));
      validateInviteCode(inviteCode)
        .then((result) => {
          console.log('Validation result:', result);
          if (result.valid) {
            console.log('Code is valid:', result.codeData);
            setFormData((prev) => ({
              ...prev,
              invitationCode: inviteCode,
              isCodeValid: true,
              validatedCode: result.docId,
              error: '',
              loading: false,
            }));
          } else {
            console.log('Code is invalid:', result.message);
            setFormData((prev) => ({
              ...prev,
              invitationCode: inviteCode,
              isCodeValid: false,
              validatedCode: null,
              error: result.message,
              loading: false,
            }));
          }
        })
        .catch((error) => {
          console.error('Validation error:', error);
          setFormData((prev) => ({
            ...prev,
            invitationCode: inviteCode,
            isCodeValid: false,
            validatedCode: null,
            error: 'Error validating invitation code',
            loading: false,
          }));
        });
    }
  }, [searchParams]);

  // Validate invitation code when manually entered
  useEffect(() => {
    if (!searchParams.get('invite') && formData.invitationCode) {
      validateInviteCode(formData.invitationCode)
        .then((result) => {
          setFormData((prev) => ({
            ...prev,
            isCodeValid: result.valid,
            validatedCode: result.valid ? result.docId : null,
            error: result.valid ? '' : result.message,
          }));
        })
        .catch((error) => {
          setFormData((prev) => ({
            ...prev,
            isCodeValid: false,
            validatedCode: null,
            error: error.message,
          }));
        });
    }
  }, [formData.invitationCode, searchParams]);

  useEffect(() => {
    // Check for message from login redirect
    if (location.state?.message) {
      setFormData((prev) => ({
        ...prev,
        error: location.state.message,
      }));
      // Clear the location state
      window.history.replaceState({}, document.title);
    }
  }, [location]);

  const handleGoogleSignIn = async () => {
    try {
      if (!formData.isCodeValid) {
        setFormData((prev) => ({
          ...prev,
          error:
            'Please enter a valid invitation code. Need one? You can request an invitation code below.',
        }));
        return;
      }

      setFormData((prev) => ({ ...prev, loading: true, error: '' }));

      // Call signInWithGoogle and handle the result
      const result = await signInWithGoogle();
      console.log('Google sign-in result:', result);

      if (!result || !result.user) {
        throw new Error('Failed to get user data from Google sign-in');
      }

      // Check if this is a new user
      const userDoc = await getDoc(doc(db, 'users', result.user.uid));
      const isNewUser = !userDoc.exists();

      if (isNewUser) {
        // Create new user document
        await setDoc(doc(db, 'users', result.user.uid), {
          email: result.user.email,
          displayName: result.user.displayName,
          photoURL: result.user.photoURL,
          createdAt: serverTimestamp(),
          invitationCode: formData.invitationCode.toUpperCase(),
          skills: {},
          socialLinks: {},
          countriesServed: [],
          profileComplete: false,
          emailVerified: result.user.emailVerified,
        });

        // Update invitation code usage
        if (formData.validatedCode) {
          await updateDoc(doc(db, 'invitationCodes', formData.validatedCode), {
            usedCount: increment(1),
            usedBy: arrayUnion(result.user.uid),
            lastUsedAt: serverTimestamp(),
          });
        }
      }

      // Navigate to dashboard
      navigate('/user-dashboard');
    } catch (error) {
      console.error('Google sign-in error:', error);
      setFormData((prev) => ({
        ...prev,
        error: error.message || 'Failed to sign in with Google',
        loading: false,
      }));
    } finally {
      setFormData((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleRequestInvitation = async (e) => {
    e.preventDefault();
    try {
      setRequestData((prev) => ({ ...prev, loading: true, error: '' }));

      await createInvitationRequest({
        email: requestData.email,
        name: requestData.name,
        requestDate: new Date().toISOString(),
      });

      setRequestData((prev) => ({
        ...prev,
        success:
          'Invitation request sent successfully! We will contact you soon.',
        loading: false,
      }));

      // Hide form after successful submission
      setTimeout(() => {
        setShowRequestForm(false);
        setRequestData({
          email: '',
          name: '',
          loading: false,
          error: '',
          success: '',
        });
      }, 3000);
    } catch (error) {
      setRequestData((prev) => ({
        ...prev,
        error: error.message || 'Failed to submit request',
        loading: false,
      }));
    }
  };

  return (
    <div className="register-container">
      <div className="register-card">
        <h2 className="register-title">Sign Up</h2>
        {formData.error && (
          <div className="error-message">
            {formData.error}
            {formData.error.includes('valid invitation code') && (
              <button
                onClick={() => setShowRequestForm(true)}
                className="link-button"
                style={{ marginLeft: '8px' }}
              >
                Request one here
              </button>
            )}
          </div>
        )}
        {formData.success && (
          <div className="success-message">{formData.success}</div>
        )}

        {!showRequestForm ? (
          <div className="signup-steps">
            <div className="invitation-code-section">
              <div className="form-group">
                <label htmlFor="invitation-code">Invitation Code</label>
                <div className="invitation-code-wrapper">
                  <input
                    type="text"
                    id="invitation-code"
                    value={formData.invitationCode}
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        invitationCode: e.target.value,
                        error: '',
                      }))
                    }
                    required
                    className={`form-input ${
                      formData.isCodeValid ? 'valid' : ''
                    }`}
                    placeholder="Enter your invitation code"
                  />
                  {formData.isCodeValid && (
                    <div className="verification-badge">
                      <span className="checkmark">✓</span>
                      Verified
                    </div>
                  )}
                </div>
                {!formData.invitationCode && (
                  <div className="form-tip">
                    Don&apos;t have an invitation code?{' '}
                    <button
                      onClick={() => setShowRequestForm(true)}
                      className="link-button"
                    >
                      Request one here
                    </button>
                  </div>
                )}
              </div>
            </div>

            <div className="google-signin-section">
              <button
                onClick={handleGoogleSignIn}
                className="btn btn-google btn-block"
                disabled={formData.loading || !formData.isCodeValid}
              >
                <FaGoogle className="google-icon" />
                {formData.loading ? 'Signing up...' : 'Sign up with Google'}
              </button>
            </div>
          </div>
        ) : (
          <div className="request-form-section">
            <h3>Request Invitation Code</h3>
            {requestData.error && (
              <div className="error-message">{requestData.error}</div>
            )}
            {requestData.success && (
              <div className="success-message">{requestData.success}</div>
            )}
            <form onSubmit={handleRequestInvitation}>
              <div className="form-group">
                <label htmlFor="name">Full Name</label>
                <input
                  type="text"
                  id="name"
                  value={requestData.name}
                  onChange={(e) =>
                    setRequestData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                  required
                  className="form-input"
                />
              </div>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  value={requestData.email}
                  onChange={(e) =>
                    setRequestData((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                  required
                  className="form-input"
                />
              </div>
              <div className="form-buttons">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={requestData.loading}
                >
                  {requestData.loading ? 'Sending...' : 'Submit Request'}
                </button>
                <button
                  type="button"
                  onClick={() => setShowRequestForm(false)}
                  className="btn btn-secondary"
                >
                  Back to Sign Up
                </button>
              </div>
            </form>
          </div>
        )}

        <div className="register-footer">
          <p>
            Already have an account?{' '}
            <Link to="/login" className="login-link">
              Log In
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Register;
