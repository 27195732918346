import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { getCatalogs, updateUser } from '../services/firestoreService';
import { storage } from '../firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import '../styles/profileWizard.css';
import ProfileWizardMascot from './ProfileWizardMascot';
import PropTypes from 'prop-types';
import { countries } from 'countries-list';
import {
  FaUser,
  FaImage,
  FaEnvelope,
  FaPhone,
  FaWhatsapp,
  FaLinkedin,
  FaTwitter,
  FaFacebook,
  FaInstagram,
  FaCalendarAlt,
  FaInfoCircle,
  FaChartLine,
  FaQuoteRight,
  FaShieldAlt,
  FaLink,
  FaTrophy,
} from 'react-icons/fa';
import WorldMap from './WorldMap';
import {
  calculateProfileCompletion,
  getProfileCompletionDetails,
} from '../utils/profileUtils';

const steps = [
  'Personal Details',
  'Contact Details',
  'Skills',
  'Proficiency Level',
  'Tools & Technologies',
  'Languages',
  'Platforms & Services',
  'Work Experience',
  'Portfolio Gallery',
  'Testimonials',
  'Countries Served',
  'Awards & Certifications',
  'Availability',
  'Analytics',
  'Call to Action',
];

const languageOptions = [
  'English',
  'Spanish',
  'French',
  'German',
  'Chinese',
  'Japanese',
  'Arabic',
  'Hindi',
  'Portuguese',
  'Russian',
  'Italian',
  'Korean',
  'Dutch',
  'Turkish',
  'Swedish',
  'Polish',
  'Vietnamese',
  'Thai',
  'Indonesian',
  'Greek',
  'Tamil',
  'Sinhala',
];

// Add this constant for fixed category order
const categoryOrder = {
  skills: [
    'Graphic Design',
    'Content Writing & Copywriting',
    'Development & Programming',
    'Marketing & Advertising',
    'Video & Multimedia Production',
    'Business & Consulting Services',
  ],
  technologies: [
    'Graphic Design Tools',
    'Development Tools & Technologies',
    'Writing & Content Tools',
    'Marketing Tools',
    'Video Editing & Multimedia Tools',
    'Frontend Development',
    'Backend Development',
    'Mobile Development',
    'Game Development',
    'AI & Machine Learning',
    'Cloud & DevOps Technologies',
  ],
  platforms: [
    'Web & eCommerce Platforms',
    'Social Media Platforms',
    'Email Marketing Platforms',
    'Project Management & Collaboration Tools',
    'Cloud Hosting & Storage Platforms',
    'Payment & Invoicing Platforms',
  ],
};

// Add this helper component for consistent notice styling
const SectionNotice = ({ icon, title, description, details }) => (
  <div className="section-notice">
    {icon}
    <div>
      {title && <p className="notice-title">{title}</p>}
      <p>{description}</p>
      {details && <p className="notice-details">{details}</p>}
    </div>
  </div>
);

// Add PropTypes validation
SectionNotice.propTypes = {
  icon: PropTypes.element.isRequired,
  title: PropTypes.string,
  description: PropTypes.string.isRequired,
  details: PropTypes.string,
};

// Add default props
SectionNotice.defaultProps = {
  title: '',
  details: '',
};

function ProfileWizard({ userProfile, onComplete }) {
  const { currentUser } = useAuth();
  const [step, setStep] = useState(0);
  const [profile, setProfile] = useState({
    ...userProfile,
    availabilityTypes: userProfile.availabilityTypes || [],
    portfolioItems: userProfile.portfolioItems || [],
  });
  const [catalogs, setCatalogs] = useState({});
  const [completionScore, setCompletionScore] = useState(0);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [editingPortfolioItem, setEditingPortfolioItem] = useState(null);
  const [tooltipContent, setTooltipContent] = useState('');

  useEffect(() => {
    fetchCatalogs();
    calculateCompletionScore();
  }, [profile]);

  const fetchCatalogs = async () => {
    try {
      const fetchedCatalogs = await getCatalogs();
      if (fetchedCatalogs) {
        setCatalogs(fetchedCatalogs);
      }
    } catch (error) {
      console.error('Error fetching catalogs:', error);
    } finally {
      setLoading(false);
    }
  };

  const calculateCompletionScore = () => {
    const completion = getProfileCompletionDetails(profile);
    setCompletionScore(completion.percentage);
  };

  const handleChange = (e) => {
    setProfile({ ...profile, [e.target.name]: e.target.value });
  };

  const handleMultiSelect = (field, category, value) => {
    if (field === 'availabilityTypes') {
      setProfile((prevProfile) => ({
        ...prevProfile,
        availabilityTypes: prevProfile.availabilityTypes?.includes(value)
          ? prevProfile.availabilityTypes.filter((type) => type !== value)
          : [...(prevProfile.availabilityTypes || []), value],
      }));
    } else {
      setProfile((prevProfile) => {
        const currentItems = prevProfile[field]?.[category] || [];
        if (currentItems.includes(value)) {
          return {
            ...prevProfile,
            [field]: {
              ...prevProfile[field],
              [category]: currentItems.filter((item) => item !== value),
            },
          };
        } else if (currentItems.length < 5) {
          return {
            ...prevProfile,
            [field]: {
              ...prevProfile[field],
              [category]: [...currentItems, value],
            },
          };
        }
        return prevProfile;
      });
    }
  };

  const handleSave = async () => {
    try {
      let updatedProfile = { ...profile };

      // Handle file upload for profile picture
      if (profile.profilePicture instanceof File) {
        const storageRef = ref(
          storage,
          `profile-pictures/${currentUser.uid}/${profile.profilePicture.name}`,
        );
        await uploadBytes(storageRef, profile.profilePicture);
        const downloadURL = await getDownloadURL(storageRef);
        updatedProfile.profilePictureUrl = downloadURL;
        delete updatedProfile.profilePicture;
      }

      await updateUser(currentUser.uid, updatedProfile);
      alert('Progress saved successfully!');
    } catch (error) {
      console.error('Error saving profile:', error);
      if (error.code === 'storage/unauthorized') {
        alert(
          'Error: You do not have permission to upload files. Please contact support.',
        );
      } else {
        alert('Failed to save progress. Please try again.');
      }
    }
  };

  const handleComplete = () => {
    onComplete(profile);
  };

  const handleProficiencyChange = (skill, level) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      proficiencyLevels: {
        ...prevProfile.proficiencyLevels,
        [skill]: level,
      },
    }));
  };

  const handleLanguageChange = (index, field, value) => {
    setProfile((prevProfile) => {
      const updatedLanguages = [...(prevProfile.languages || [])];
      updatedLanguages[index] = { ...updatedLanguages[index], [field]: value };
      return { ...prevProfile, languages: updatedLanguages };
    });
  };

  const handleRemoveLanguage = (index) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      languages: prevProfile.languages.filter((_, i) => i !== index),
    }));
  };

  const handleAddLanguage = () => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      languages: [
        ...(prevProfile.languages || []),
        { name: '', proficiency: '', specialUseCase: '' },
      ],
    }));
  };

  const handleWorkExperienceChange = (index, field, value) => {
    setProfile((prevProfile) => {
      const updatedExperiences = [...(prevProfile.workExperience || [])];
      updatedExperiences[index] = {
        ...updatedExperiences[index],
        [field]: value,
      };
      return { ...prevProfile, workExperience: updatedExperiences };
    });
  };

  const handleRemoveWorkExperience = (index) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      workExperience: prevProfile.workExperience.filter((_, i) => i !== index),
    }));
  };

  const handleAddWorkExperience = () => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      workExperience: [
        ...(prevProfile.workExperience || []),
        { jobTitle: '', company: '', duration: '', description: '' },
      ],
    }));
  };

  const handlePortfolioItemChange = (index, field, value) => {
    setProfile((prevProfile) => {
      const updatedPortfolioItems = [...(prevProfile.portfolioItems || [])];
      updatedPortfolioItems[index] = {
        ...updatedPortfolioItems[index],
        [field]: value,
      };
      return { ...prevProfile, portfolioItems: updatedPortfolioItems };
    });
  };

  const handlePortfolioItemMediaUpload = async (index, file) => {
    if (file) {
      const storageRef = ref(
        storage,
        `portfolio/${currentUser.uid}/${file.name}`,
      );
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      handlePortfolioItemChange(index, 'mediaUrl', downloadURL);
    }
  };

  const handleRemovePortfolioItem = (index) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      portfolioItems: prevProfile.portfolioItems.filter((_, i) => i !== index),
    }));
  };

  const handleAddPortfolioItem = () => {
    setEditingPortfolioItem({
      title: '',
      description: '',
      toolsUsed: '',
      results: '',
      projectUrl: '',
      mediaUrl: '',
      index: (profile.portfolioItems || []).length,
    });
  };

  const handleTestimonialChange = (index, field, value) => {
    setProfile((prevProfile) => {
      const updatedTestimonials = [...(prevProfile.testimonials || [])];
      updatedTestimonials[index] = {
        ...updatedTestimonials[index],
        [field]: value,
      };
      return { ...prevProfile, testimonials: updatedTestimonials };
    });
  };

  const handleTestimonialVideoUpload = async (index, file) => {
    if (file) {
      const storageRef = ref(
        storage,
        `testimonials/${currentUser.uid}/${file.name}`,
      );
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      handleTestimonialChange(index, 'videoUrl', downloadURL);
    }
  };

  const handleRemoveTestimonial = (index) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      testimonials: prevProfile.testimonials.filter((_, i) => i !== index),
    }));
  };

  const handleAddTestimonial = () => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      testimonials: [
        ...(prevProfile.testimonials || []),
        { clientName: '', quote: '', rating: 0 },
      ],
    }));
  };

  const handleAwardCertificationChange = (index, field, value) => {
    setProfile((prevProfile) => {
      const updatedAwards = [...(prevProfile.awardsAndCertifications || [])];
      updatedAwards[index] = { ...updatedAwards[index], [field]: value };
      return { ...prevProfile, awardsAndCertifications: updatedAwards };
    });
  };

  const handleAwardCertificationUpload = async (index, file) => {
    if (file) {
      const storageRef = ref(storage, `awards/${currentUser.uid}/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      handleAwardCertificationChange(index, 'certificateUrl', downloadURL);
    }
  };

  const handleRemoveAwardCertification = (index) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      awardsAndCertifications: prevProfile.awardsAndCertifications.filter(
        (_, i) => i !== index,
      ),
    }));
  };

  const handleAddAwardCertification = () => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      awardsAndCertifications: [
        ...(prevProfile.awardsAndCertifications || []),
        { name: '', issuingAuthority: '', dateAwarded: '', description: '' },
      ],
    }));
  };

  const renderCategoryItems = (field, category, items) => (
    <div className="category-items">
      {items.map((item) => (
        <label
          key={item}
          className={`item-label ${
            profile[field]?.[category]?.includes(item) ? 'selected' : ''
          }`}
          onClick={() => handleMultiSelect(field, category, item)}
        >
          <span>{item}</span>
        </label>
      ))}
    </div>
  );

  const renderCategorizedItems = (field) => (
    <>
      {categoryOrder[field].map((category) => (
        <div key={category} className="category">
          <h4>{category}</h4>
          {renderCategoryItems(
            field,
            category,
            catalogs[field]?.[category] || [],
          )}
        </div>
      ))}
    </>
  );

  const renderStep = () => {
    switch (step) {
      case 0:
        return renderPersonalDetails();
      case 1:
        return renderContactDetails();
      case 2:
        return renderSkills();
      case 3:
        return renderProficiencyLevel();
      case 4:
        return renderToolsAndTechnologies();
      case 5:
        return renderLanguages();
      case 6:
        return renderPlatformsAndServices();
      case 7:
        return renderWorkExperience();
      case 8:
        return renderPortfolioGallery();
      case 9:
        return renderTestimonials();
      case 10:
        return renderCountriesServed();
      case 11:
        return renderAwardsAndCertifications();
      case 12:
        return renderAvailability();
      case 13:
        return renderAnalytics();
      case 14:
        return renderCallToAction();
      default:
        return <div>Unknown step</div>;
    }
  };

  // Add this function before renderPersonalDetails
  const handleRemoveImage = (field) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      [`${field}Url`]: null,
    }));
  };

  const renderPersonalDetails = () => (
    <div className="wizard-step">
      <h3>Personal Details</h3>
      <SectionNotice
        icon={<FaInfoCircle />}
        description="This information will be displayed on your public profile"
        details="Your name and bio help clients understand who you are and what you do"
      />
      <input
        type="text"
        name="fullName"
        placeholder="Full Name"
        value={profile.fullName || ''}
        onChange={handleChange}
      />
      <textarea
        name="bio"
        placeholder="Tell us about yourself..."
        value={profile.bio || ''}
        onChange={handleChange}
        rows="4"
      />

      {/* Image Upload Section */}
      <div className="image-upload-container">
        <div className="image-upload">
          <label>Profile Picture</label>
          <div className="image-instructions">
            <p>Recommended: Square image (1:1 ratio)</p>
            <p>File type: JPG, PNG, or WebP</p>
            <p>Max size: 2MB</p>
            <p>Min dimensions: 400x400 pixels</p>
          </div>
          <div className="image-preview">
            {profile.profilePictureUrl ? (
              <>
                <img
                  src={profile.profilePictureUrl}
                  alt="Profile"
                  className="preview-image"
                />
                <button
                  className="remove-image-btn"
                  onClick={() => handleRemoveImage('profilePicture')}
                  type="button"
                >
                  ×
                </button>
              </>
            ) : (
              <div className="upload-placeholder">
                <FaUser size={40} />
                <span>Upload Profile Picture</span>
              </div>
            )}
          </div>
          <input
            type="file"
            id="profilePicture"
            accept="image/jpeg,image/png,image/webp"
            onChange={(e) =>
              validateAndHandleFileChange('profilePicture', e.target.files[0])
            }
            style={{ display: 'none' }}
          />
          <label htmlFor="profilePicture" className="upload-btn">
            Choose Image
          </label>
        </div>

        <div className="image-upload">
          <label>Cover Image</label>
          <div className="image-instructions">
            <p>Recommended: 16:9 ratio</p>
            <p>File type: JPG, PNG, or WebP</p>
            <p>Max size: 4MB</p>
            <p>Min dimensions: 1200x675 pixels</p>
          </div>
          <div className="image-preview cover-preview">
            {profile.coverImageUrl ? (
              <>
                <img
                  src={profile.coverImageUrl}
                  alt="Cover"
                  className="preview-image"
                />
                <button
                  className="remove-image-btn"
                  onClick={() => handleRemoveImage('coverImage')}
                  type="button"
                >
                  ×
                </button>
              </>
            ) : (
              <div className="upload-placeholder">
                <FaImage size={40} />
                <span>Upload Cover Image</span>
              </div>
            )}
          </div>
          <input
            type="file"
            id="coverImage"
            accept="image/jpeg,image/png,image/webp"
            onChange={(e) =>
              validateAndHandleFileChange('coverImage', e.target.files[0])
            }
            style={{ display: 'none' }}
          />
          <label htmlFor="coverImage" className="upload-btn">
            Choose Image
          </label>
        </div>
      </div>

      <input
        type="text"
        name="tagline"
        placeholder="Tagline / Headline"
        value={profile.tagline || ''}
        onChange={handleChange}
      />
      <input
        type="text"
        name="location"
        placeholder="Location (City, Country)"
        value={profile.location || ''}
        onChange={handleChange}
      />

      <select
        name="yearsOfExperience"
        value={profile.yearsOfExperience || ''}
        onChange={handleChange}
      >
        <option value="">Years of Experience</option>
        <option value="0-2">0-2 years</option>
        <option value="3-5">3-5 years</option>
        <option value="5-10">5-10 years</option>
        <option value="10+">10+ years</option>
      </select>
    </div>
  );

  const validateAndHandleFileChange = async (field, file) => {
    if (!file) return;

    // Check file type
    const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
    if (!validTypes.includes(file.type)) {
      alert('Please upload a valid image file (JPG, PNG, or WebP)');
      return;
    }

    // Check file size
    const maxSize =
      field === 'profilePicture' ? 2 * 1024 * 1024 : 4 * 1024 * 1024; // 2MB or 4MB
    if (file.size > maxSize) {
      alert(`File size must be less than ${maxSize / (1024 * 1024)}MB`);
      return;
    }

    // Check image dimensions
    const img = new Image();
    const imageUrl = URL.createObjectURL(file);

    img.onload = async () => {
      URL.revokeObjectURL(imageUrl);

      if (field === 'profilePicture') {
        if (img.width < 400 || img.height < 400) {
          alert('Profile picture must be at least 400x400 pixels');
          return;
        }
        // Check if image is roughly square
        const ratio = img.width / img.height;
        if (ratio < 0.9 || ratio > 1.1) {
          alert('Profile picture should be roughly square (1:1 ratio)');
          return;
        }
      } else {
        if (img.width < 1200 || img.height < 675) {
          alert('Cover image must be at least 1200x675 pixels');
          return;
        }
      }

      // If all validations pass, handle the file change
      await handleFileChange(field, file);
    };

    img.src = imageUrl;
  };

  const handleFileChange = async (field, file) => {
    if (file) {
      const storageRef = ref(
        storage,
        `user-images/${currentUser.uid}/${field}/${file.name}`,
      );
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      setProfile((prevProfile) => ({
        ...prevProfile,
        [`${field}Url`]: downloadURL,
      }));
    }
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateSriLankaPhone = (phone) => {
    // Allows formats: 0771234567, 071-123-4567, +94771234567, +94-77-123-4567
    const re = /^(?:(?:\+94)|0)[-]?(?:7[0-8]|11)[-]?\d{7}$/;
    return re.test(phone.replace(/\s+/g, ''));
  };

  const formatSriLankaPhone = (phone) => {
    // Remove all non-digit characters
    const digits = phone.replace(/\D/g, '');

    // Format based on input length and starting digits
    if (digits.startsWith('94')) {
      return digits.replace(/(\d{2})(\d{2})(\d{3})(\d{4})/, '+$1-$2-$3-$4');
    } else if (digits.startsWith('0')) {
      return digits.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }
    return phone;
  };

  const renderContactDetails = () => (
    <div className="wizard-step">
      <h3>Contact Details</h3>

      {/* Privacy Notice */}
      <SectionNotice
        icon={<FaShieldAlt />}
        title="Privacy Control"
        description="Manage your contact information visibility"
        details="You can control which contact details are publicly visible in the 'Call to Action' section"
      />

      <div className="contact-section">
        <h4>Primary Contact</h4>
        <div className="contact-grid">
          <div className="form-group">
            <label>
              <FaEnvelope /> Email Address *
            </label>
            <input
              type="email"
              name="email"
              placeholder="your@email.com"
              value={profile.email || ''}
              onChange={handleChange}
              required
            />
            <span className="field-note">
              Your primary contact email for account notifications
            </span>
          </div>

          <div className="form-group">
            <label>
              <FaPhone /> Phone Number
            </label>
            <div className="phone-input-container">
              <input
                type="tel"
                name="phone"
                placeholder="071-234-5678"
                value={profile.phone || ''}
                onChange={(e) => {
                  const formattedPhone = formatSriLankaPhone(e.target.value);
                  handleChange({
                    target: { name: 'phone', value: formattedPhone },
                  });
                }}
              />
              <div className="input-instructions">
                <div className="format-example">
                  <FaInfoCircle />
                  <span>Format: 071-234-5678 or +94-71-234-5678</span>
                </div>
                <span className="field-note">
                  Sri Lankan phone numbers only
                </span>
              </div>
            </div>
          </div>

          <div className="form-group">
            <label>
              <FaWhatsapp /> WhatsApp
            </label>
            <div className="phone-input-container">
              <input
                type="tel"
                name="whatsapp"
                placeholder="071-234-5678"
                value={profile.whatsapp || ''}
                onChange={(e) => {
                  const formattedPhone = formatSriLankaPhone(e.target.value);
                  handleChange({
                    target: { name: 'whatsapp', value: formattedPhone },
                  });
                }}
              />
              <div className="input-instructions">
                <div className="format-example">
                  <FaInfoCircle />
                  <span>Same format as phone number</span>
                </div>
                <span className="field-note">
                  Can be different from phone number
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-section">
        <h4>Social Media</h4>
        <SectionNotice
          icon={<FaLink />}
          description="Add your professional social media profiles"
          details="Use complete profile URLs for proper linking"
        />
        <div className="contact-grid">
          <div className="form-group">
            <label>
              <FaLinkedin /> LinkedIn
            </label>
            <input
              type="url"
              name="linkedin"
              placeholder="LinkedIn Profile URL"
              value={profile.linkedin || ''}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>
              <FaTwitter /> Twitter
            </label>
            <input
              type="url"
              name="twitter"
              placeholder="Twitter Profile URL"
              value={profile.twitter || ''}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>
              <FaFacebook /> Facebook
            </label>
            <input
              type="url"
              name="facebook"
              placeholder="Facebook Profile URL"
              value={profile.facebook || ''}
              onChange={handleChange}
            />
          </div>

          <div className="form-group">
            <label>
              <FaInstagram /> Instagram
            </label>
            <input
              type="url"
              name="instagram"
              placeholder="Instagram Profile URL"
              value={profile.instagram || ''}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="contact-section">
        <h4>Scheduling</h4>
        <SectionNotice
          icon={<FaCalendarAlt />}
          description="Add your booking link for client meetings"
          details="Recommended: Use Calendly for automated scheduling"
        />
        <div className="form-group">
          <label>
            <FaCalendarAlt /> Calendly Booking Link
          </label>
          <input
            type="url"
            name="calendly"
            placeholder="Calendly URL"
            value={profile.calendly || ''}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );

  const renderSkills = () => (
    <div className="wizard-step">
      <h3>Skills</h3>
      <SectionNotice
        icon={<FaInfoCircle />}
        description="Select up to 5 skills from each category"
        details="This helps you highlight your core expertise in each area"
      />
      {renderCategorizedItems('skills')}
    </div>
  );

  const renderProficiencyLevel = () => (
    <div className="wizard-step">
      <h3>Proficiency Level</h3>
      <SectionNotice
        icon={<FaInfoCircle />}
        description="Rate your proficiency in each selected skill"
        details="Be honest with your ratings - it helps set the right expectations"
      />
      {Object.keys(profile.skills || {}).map((category) => (
        <div key={category} className="proficiency-category">
          <h4>{category}</h4>
          {profile.skills[category].map((skill, index) => (
            <div key={index} className="proficiency-item">
              <label>{skill}</label>
              <input
                type="range"
                min="1"
                max="5"
                value={profile.proficiencyLevels?.[skill] || 1}
                onChange={(e) => handleProficiencyChange(skill, e.target.value)}
              />
              <span>{profile.proficiencyLevels?.[skill] || 1}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  const renderToolsAndTechnologies = () => (
    <div className="wizard-step">
      <h3>Tools & Technologies</h3>
      <SectionNotice
        icon={<FaInfoCircle />}
        description="Select the tools and technologies you're experienced with"
        details="Choose technologies you actively use and are confident working with"
      />
      {/* Add loading state */}
      {loading ? (
        <div className="loading-state">Loading technologies...</div>
      ) : (
        <>
          {/* Add error handling */}
          {!catalogs?.technologies && (
            <div className="error-message">
              No technologies found. Please try refreshing the page.
            </div>
          )}

          {/* Render categories in order */}
          {categoryOrder.technologies.map((category) => (
            <div key={category} className="category">
              <h4>{category}</h4>
              {catalogs?.technologies?.[category]?.length > 0 ? (
                <div className="category-items">
                  {catalogs.technologies[category].map((item) => (
                    <label
                      key={item}
                      className={`item-label ${
                        profile.technologies?.[category]?.includes(item)
                          ? 'selected'
                          : ''
                      }`}
                      onClick={() =>
                        handleMultiSelect('technologies', category, item)
                      }
                    >
                      <span>{item}</span>
                    </label>
                  ))}
                </div>
              ) : (
                <div className="no-items-message">
                  No items available in this category
                </div>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );

  const renderLanguages = () => (
    <div className="wizard-step">
      <h3>Languages</h3>
      <SectionNotice
        icon={<FaInfoCircle />}
        description="Add languages you can communicate in"
        details="Include proficiency level and any special use cases (e.g., Technical Documentation, Business Communication)"
      />
      <div className="languages-container">
        {(profile.languages || []).map((lang, index) => (
          <div key={index} className="language-item">
            <div className="language-main">
              <div className="language-select">
                <label>Language</label>
                <select
                  value={lang.name || ''}
                  onChange={(e) =>
                    handleLanguageChange(index, 'name', e.target.value)
                  }
                >
                  <option value="">Select Language</option>
                  {languageOptions.map((language) => (
                    <option key={language} value={language}>
                      {language}
                    </option>
                  ))}
                </select>
              </div>

              <div className="language-proficiency">
                <label>Proficiency</label>
                <select
                  value={lang.proficiency || ''}
                  onChange={(e) =>
                    handleLanguageChange(index, 'proficiency', e.target.value)
                  }
                >
                  <option value="">Select Level</option>
                  <option value="Basic">Basic</option>
                  <option value="Conversational">Conversational</option>
                  <option value="Professional">Professional</option>
                  <option value="Fluent">Fluent</option>
                  <option value="Native">Native</option>
                </select>
              </div>
            </div>

            <div className="language-details">
              <label>Special Use Case (Optional)</label>
              <input
                type="text"
                value={lang.specialUseCase || ''}
                onChange={(e) =>
                  handleLanguageChange(index, 'specialUseCase', e.target.value)
                }
                placeholder="e.g., Technical Writing, Business Communication"
              />
            </div>

            <button
              className="remove-language-btn"
              onClick={() => handleRemoveLanguage(index)}
              type="button"
            >
              Remove Language
            </button>
          </div>
        ))}

        <button
          className="add-language-btn"
          onClick={handleAddLanguage}
          type="button"
        >
          <span>+</span> Add Language
        </button>
      </div>
    </div>
  );

  const renderPlatformsAndServices = () => (
    <div className="wizard-step">
      <h3>Platforms & Services</h3>
      <SectionNotice
        icon={<FaInfoCircle />}
        description="Select platforms and services you're experienced with"
        details="Choose platforms and services you actively use and are confident working with"
      />
      {renderCategorizedItems('platforms')}
    </div>
  );

  const renderWorkExperience = () => (
    <div className="wizard-step">
      <h3>Work Experience</h3>
      <SectionNotice
        icon={<FaInfoCircle />}
        description="Add your relevant work experience"
        details="Focus on experiences that showcase your expertise in your chosen skills"
      />
      <div className="work-experience-container">
        {(profile.workExperience || []).map((exp, index) => (
          <div key={index} className="work-experience-item">
            <div className="experience-header">
              <input
                type="text"
                value={exp.jobTitle || ''}
                onChange={(e) =>
                  handleWorkExperienceChange(index, 'jobTitle', e.target.value)
                }
                placeholder="Job Title"
                className="job-title-input"
              />
              <input
                type="text"
                value={exp.company || ''}
                onChange={(e) =>
                  handleWorkExperienceChange(index, 'company', e.target.value)
                }
                placeholder="Company / Organization"
                className="company-input"
              />
            </div>

            <div className="date-section">
              <div className="date-input-group">
                <label>Start Date</label>
                <div className="month-year-input">
                  <input
                    type="text"
                    value={exp.startMonth || ''}
                    onChange={(e) =>
                      handleWorkExperienceChange(
                        index,
                        'startMonth',
                        e.target.value,
                      )
                    }
                    placeholder="MM"
                    maxLength="2"
                    className="month-input"
                  />
                  <span className="date-separator">/</span>
                  <input
                    type="text"
                    value={exp.startYear || ''}
                    onChange={(e) =>
                      handleWorkExperienceChange(
                        index,
                        'startYear',
                        e.target.value,
                      )
                    }
                    placeholder="YYYY"
                    maxLength="4"
                    className="year-input"
                  />
                </div>
              </div>

              <div className="date-input-group">
                <label>End Date</label>
                <div className="end-date-container">
                  <div
                    className="month-year-input"
                    style={{ opacity: exp.currentlyWorking ? 0.5 : 1 }}
                  >
                    <input
                      type="text"
                      value={exp.endMonth || ''}
                      onChange={(e) =>
                        handleWorkExperienceChange(
                          index,
                          'endMonth',
                          e.target.value,
                        )
                      }
                      placeholder="MM"
                      maxLength="2"
                      className="month-input"
                      disabled={exp.currentlyWorking}
                    />
                    <span className="date-separator">/</span>
                    <input
                      type="text"
                      value={exp.endYear || ''}
                      onChange={(e) =>
                        handleWorkExperienceChange(
                          index,
                          'endYear',
                          e.target.value,
                        )
                      }
                      placeholder="YYYY"
                      maxLength="4"
                      className="year-input"
                      disabled={exp.currentlyWorking}
                    />
                  </div>
                  <label className="currently-working">
                    <input
                      type="checkbox"
                      checked={exp.currentlyWorking || false}
                      onChange={(e) =>
                        handleWorkExperienceChange(
                          index,
                          'currentlyWorking',
                          e.target.checked,
                        )
                      }
                    />
                    <span>I currently work here</span>
                  </label>
                </div>
              </div>
            </div>

            <textarea
              value={exp.description || ''}
              onChange={(e) =>
                handleWorkExperienceChange(index, 'description', e.target.value)
              }
              placeholder="Description of Role / Achievements"
              className="experience-description"
            />

            <button
              className="remove-experience-btn"
              onClick={() => handleRemoveWorkExperience(index)}
              type="button"
            >
              Remove Experience
            </button>
          </div>
        ))}

        <button
          className="add-experience-btn"
          onClick={handleAddWorkExperience}
          type="button"
        >
          <span>+</span> Add Work Experience
        </button>
      </div>
    </div>
  );

  const handleEditPortfolioItem = (index) => {
    const itemToEdit = profile.portfolioItems[index];
    setEditingPortfolioItem({ ...itemToEdit, index });
  };

  const handleImageUpload = async (file) => {
    if (file) {
      const storageRef = ref(
        storage,
        `portfolio/${currentUser.uid}/${file.name}`,
      );
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      setEditingPortfolioItem((prev) => ({
        ...prev,
        mediaUrl: downloadURL,
      }));
    }
  };

  const handleSaveEditedPortfolioItem = () => {
    const updatedItems = [...profile.portfolioItems];
    updatedItems[editingPortfolioItem.index] = {
      ...editingPortfolioItem,
    };
    setProfile({ ...profile, portfolioItems: updatedItems });
    setEditingPortfolioItem(null);
  };

  const renderPortfolioGallery = () => (
    <div className="wizard-step">
      <h3>Portfolio Gallery</h3>
      <SectionNotice
        icon={<FaInfoCircle />}
        description="Showcase your best work"
        details="Add images, descriptions, and links to your completed projects"
      />

      <div className="portfolio-gallery">
        {(profile.portfolioItems || []).map((item, index) => (
          <div key={index} className="portfolio-card">
            <div className="portfolio-card-image">
              {item.mediaUrl ? (
                <img src={item.mediaUrl} alt={item.title} />
              ) : (
                <div className="image-placeholder">No Image</div>
              )}
            </div>
            <div className="portfolio-card-details">
              <h4>{item.title}</h4>
              <p>{item.description}</p>
              <p>Tools Used: {item.toolsUsed}</p>
              <p>Results: {item.results}</p>
              {item.projectUrl && (
                <a
                  href={item.projectUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  View Project
                </a>
              )}
              <button
                className="btn btn-secondary"
                onClick={() => handleEditPortfolioItem(index)}
              >
                Edit
              </button>
              <button
                className="btn btn-danger"
                onClick={() => handleRemovePortfolioItem(index)}
              >
                Remove
              </button>
            </div>
          </div>
        ))}
      </div>

      <button className="add-portfolio-btn" onClick={handleAddPortfolioItem}>
        <span>+</span> Add Portfolio Item
      </button>

      {editingPortfolioItem && (
        <div className="edit-portfolio-modal">
          <div className="portfolio-form">
            <h3>
              {editingPortfolioItem.index < profile.portfolioItems.length
                ? 'Edit'
                : 'Add'}{' '}
              Portfolio Item
            </h3>

            <div className="portfolio-form-content">
              <div className="portfolio-form-section">
                <label>Project Title *</label>
                <input
                  type="text"
                  value={editingPortfolioItem.title}
                  onChange={(e) =>
                    setEditingPortfolioItem({
                      ...editingPortfolioItem,
                      title: e.target.value,
                    })
                  }
                  placeholder="e.g., E-commerce Website Redesign"
                  required
                />
                <span className="field-hint">
                  A clear, concise title for your project
                </span>
              </div>

              <div className="portfolio-form-section">
                <label>Project Description *</label>
                <textarea
                  value={editingPortfolioItem.description}
                  onChange={(e) =>
                    setEditingPortfolioItem({
                      ...editingPortfolioItem,
                      description: e.target.value,
                    })
                  }
                  placeholder="Describe the project, your role, and key features..."
                  rows="4"
                  required
                />
                <span className="field-hint">
                  Recommended: 100-300 characters
                </span>
              </div>

              <div className="portfolio-form-section">
                <label>Tools & Technologies Used *</label>
                <input
                  type="text"
                  value={editingPortfolioItem.toolsUsed}
                  onChange={(e) =>
                    setEditingPortfolioItem({
                      ...editingPortfolioItem,
                      toolsUsed: e.target.value,
                    })
                  }
                  placeholder="e.g., React.js, Node.js, MongoDB"
                />
                <span className="field-hint">
                  List main technologies separated by commas
                </span>
              </div>

              <div className="portfolio-form-section">
                <label>Project Results</label>
                <input
                  type="text"
                  value={editingPortfolioItem.results}
                  onChange={(e) =>
                    setEditingPortfolioItem({
                      ...editingPortfolioItem,
                      results: e.target.value,
                    })
                  }
                  placeholder="e.g., 50% increase in user engagement"
                />
                <span className="field-hint">
                  Quantifiable achievements or outcomes
                </span>
              </div>

              <div className="portfolio-form-section">
                <label>Project URL</label>
                <input
                  type="url"
                  value={editingPortfolioItem.projectUrl}
                  onChange={(e) =>
                    setEditingPortfolioItem({
                      ...editingPortfolioItem,
                      projectUrl: e.target.value,
                    })
                  }
                  placeholder="https://..."
                />
                <span className="field-hint">
                  Live project link or demo URL (if available)
                </span>
              </div>

              <div className="portfolio-form-section">
                <label>Project Image *</label>
                <div className="image-upload-instructions">
                  <p>Recommended: 16:9 ratio (landscape)</p>
                  <p>File type: JPG, PNG, or WebP</p>
                  <p>Max size: 2MB</p>
                  <p>Min dimensions: 1200x675 pixels</p>
                </div>
                <div className="portfolio-image-preview">
                  {editingPortfolioItem.mediaUrl ? (
                    <div className="preview-container">
                      <img src={editingPortfolioItem.mediaUrl} alt="Preview" />
                      <button
                        onClick={() =>
                          setEditingPortfolioItem({
                            ...editingPortfolioItem,
                            mediaUrl: '',
                          })
                        }
                        className="remove-image-btn"
                      >
                        Remove Image
                      </button>
                    </div>
                  ) : (
                    <div className="upload-placeholder">
                      <FaImage size={30} />
                      <span>Upload Project Image</span>
                    </div>
                  )}
                </div>
                <input
                  type="file"
                  onChange={(e) =>
                    validateAndHandlePortfolioImage(e.target.files[0])
                  }
                  accept="image/jpeg,image/png,image/webp"
                  className="file-input"
                />
              </div>
            </div>

            <div className="portfolio-form-actions">
              <button
                className="btn-save"
                onClick={handleSaveEditedPortfolioItem}
                disabled={!isPortfolioFormValid()}
              >
                Save
              </button>
              <button
                className="btn-cancel"
                onClick={() => setEditingPortfolioItem(null)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  const renderTestimonials = () => (
    <div className="wizard-step">
      <h3>Testimonials</h3>
      <SectionNotice
        icon={<FaInfoCircle />}
        description="Add client testimonials to build trust"
        details="Include the client's name, role, and their feedback about your work"
      />

      <div className="testimonials-section">
        <div className="coming-soon-overlay">
          <div className="coming-soon-content">
            <FaQuoteRight size={40} />
            <h3>Coming Soon</h3>
            <p>Client testimonials and reviews will be available soon!</p>
          </div>
        </div>

        <div className="testimonials-content">
          {(profile.testimonials || []).map((testimonial, index) => (
            <div key={index} className="testimonial-item">
              <div className="testimonial-header">
                <input
                  type="text"
                  value={testimonial.clientName || ''}
                  onChange={(e) =>
                    handleTestimonialChange(index, 'clientName', e.target.value)
                  }
                  placeholder="Client Name"
                />
                <input
                  type="text"
                  value={testimonial.clientPosition || ''}
                  onChange={(e) =>
                    handleTestimonialChange(
                      index,
                      'clientPosition',
                      e.target.value,
                    )
                  }
                  placeholder="Client Position / Company (Optional)"
                />
              </div>

              <div className="testimonial-content">
                <textarea
                  value={testimonial.quote || ''}
                  onChange={(e) =>
                    handleTestimonialChange(index, 'quote', e.target.value)
                  }
                  placeholder="Testimonial Quote"
                  rows="4"
                />
              </div>

              <div className="testimonial-footer">
                <div className="rating-input">
                  <label>Rating</label>
                  <input
                    type="number"
                    value={testimonial.rating || ''}
                    onChange={(e) =>
                      handleTestimonialChange(index, 'rating', e.target.value)
                    }
                    placeholder="1-5"
                    min="1"
                    max="5"
                  />
                </div>

                <button
                  className="remove-testimonial-btn"
                  onClick={() => handleRemoveTestimonial(index)}
                >
                  Remove
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);

    if (term.length < 1) {
      setSearchResults([]);
      return;
    }

    const results = Object.entries(countries)
      .filter(([_, country]) => country.name.toLowerCase().includes(term))
      .slice(0, 5);
    setSearchResults(results);
  };

  const handleSearchResultClick = (countryCode) => {
    if (!countryCode) return;

    setProfile((prev) => ({
      ...prev,
      countriesServed: [...(prev.countriesServed || []), countryCode],
    }));
    setSearchTerm('');
    setSearchResults([]);
  };

  const handleCountryRemove = (countryCode) => {
    if (!countryCode) return;

    setProfile((prev) => ({
      ...prev,
      countriesServed: (prev.countriesServed || []).filter(
        (code) => code !== countryCode,
      ),
    }));
  };

  // Update the handleCountryClick function
  const handleCountryClick = (countryCode) => {
    if (profile.countriesServed?.includes(countryCode)) {
      handleCountryRemove(countryCode);
    } else {
      handleSearchResultClick(countryCode);
    }
  };

  const renderCountriesServed = () => {
    return (
      <div className="wizard-step">
        <h3>Countries Served</h3>
        <SectionNotice
          icon={<FaInfoCircle />}
          description="Select countries where you can provide services"
          details="This helps clients understand your service coverage area"
        />

        <div className="countries-container">
          <div className="country-search-section">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearch}
              placeholder="Search and select countries..."
              className="country-search-input"
            />
            {searchResults.length > 0 && (
              <ul className="search-results">
                {searchResults.map(([code, country]) => (
                  <li
                    key={code}
                    onClick={() => handleSearchResultClick(code)}
                    className="search-result-item"
                  >
                    {country.name}
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className="selected-countries">
            <h4>Selected Countries</h4>
            <div className="selected-countries-list">
              {profile.countriesServed?.map((code) => (
                <div key={code} className="country-tag">
                  <span>{countries[code]?.name || code}</span>
                  <button
                    onClick={() => handleCountryRemove(code)}
                    title="Remove country"
                  >
                    ×
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderAwardsAndCertifications = () => (
    <div className="wizard-step">
      <h3>Awards & Certifications</h3>
      <SectionNotice
        icon={<FaInfoCircle />}
        description="Showcase your professional achievements"
        details="Add certifications, awards, and recognition from your industry"
      />

      <div className="awards-section">
        <div className="coming-soon-overlay">
          <div className="coming-soon-content">
            <FaTrophy size={40} />
            <h3>Coming Soon</h3>
            <p>Awards and certifications management will be available soon!</p>
          </div>
        </div>

        <div className="awards-content">
          {(profile.awardsAndCertifications || []).map((item, index) => (
            <div key={index} className="award-certification-item">
              <input
                type="text"
                value={item.name || ''}
                onChange={(e) =>
                  handleAwardCertificationChange(index, 'name', e.target.value)
                }
                placeholder="Certification / Award Name"
              />
              <input
                type="text"
                value={item.issuingAuthority || ''}
                onChange={(e) =>
                  handleAwardCertificationChange(
                    index,
                    'issuingAuthority',
                    e.target.value,
                  )
                }
                placeholder="Issuing Authority"
              />
              <input
                type="date"
                value={item.dateAwarded || ''}
                onChange={(e) =>
                  handleAwardCertificationChange(
                    index,
                    'dateAwarded',
                    e.target.value,
                  )
                }
              />
              <textarea
                value={item.description || ''}
                onChange={(e) =>
                  handleAwardCertificationChange(
                    index,
                    'description',
                    e.target.value,
                  )
                }
                placeholder="Description / Relevance"
              />
              <input
                type="file"
                onChange={(e) =>
                  handleAwardCertificationUpload(index, e.target.files[0])
                }
                accept="application/pdf,image/*"
              />
              <button onClick={() => handleRemoveAwardCertification(index)}>
                Remove
              </button>
            </div>
          ))}
          <button onClick={handleAddAwardCertification}>
            Add Award / Certification
          </button>
        </div>
      </div>
    </div>
  );

  const renderAvailability = () => (
    <div className="wizard-step">
      <h3>Availability</h3>
      <SectionNotice
        icon={<FaInfoCircle />}
        description="Set your availability status and working hours"
        details="Keep this updated to help clients know when you're available for work"
      />
      <div className="availability-section">
        <div className="availability-status">
          <h4>Status</h4>
          <div className="toggle-switch">
            <input
              type="checkbox"
              id="availabilityStatus"
              checked={profile.availabilityStatus === 'available'}
              onChange={(e) =>
                setProfile((prev) => ({
                  ...prev,
                  availabilityStatus: e.target.checked
                    ? 'available'
                    : 'not available',
                }))
              }
            />
            <label htmlFor="availabilityStatus">
              <span className="toggle-label">
                {profile.availabilityStatus === 'available'
                  ? 'Available'
                  : 'Not Available'}
              </span>
            </label>
          </div>
        </div>

        <div className="rates-section">
          <h4>Hourly Rate (USD)</h4>
          <div className="rate-input-group">
            <input
              type="number"
              name="hourlyRate"
              value={profile.hourlyRate || ''}
              onChange={handleChange}
              placeholder="Enter your hourly rate"
              min="0"
              step="0.01"
              className="rate-input"
            />
            <span className="currency-symbol">$/hr</span>
          </div>
        </div>

        <div className="availability-types">
          <h4>Availability Types</h4>
          <div className="types-grid">
            {[
              'Full-Time',
              'Part-Time',
              'Contract',
              'Freelance',
              'Project-Based',
              'Consulting',
            ].map((type) => (
              <label key={type} className="type-checkbox">
                <input
                  type="checkbox"
                  checked={profile.availabilityTypes?.includes(type) || false}
                  onChange={() =>
                    handleMultiSelect('availabilityTypes', null, type)
                  }
                />
                <span>{type}</span>
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  );

  const renderAnalytics = () => (
    <div className="wizard-step">
      <h3>Analytics</h3>
      <SectionNotice
        icon={<FaInfoCircle />}
        description="Add metrics that showcase your experience"
        details="Include project counts, success rates, and other relevant statistics"
      />

      {/* Analytics section with proper overlay positioning */}
      <div className="analytics-section" style={{ position: 'relative' }}>
        <div className="coming-soon-overlay">
          <div className="coming-soon-content">
            <FaChartLine size={40} />
            <h3>Coming Soon</h3>
            <p>
              Advanced analytics and metrics tracking will be available soon!
            </p>
          </div>
        </div>

        <div className="analytics-content">
          <div className="analytics-grid">
            <div className="analytics-item">
              <label>Projects Completed</label>
              <input
                type="number"
                value={profile.projectCount || ''}
                onChange={(e) =>
                  handleChange({
                    target: { name: 'projectCount', value: e.target.value },
                  })
                }
                placeholder="0"
                min="0"
              />
            </div>

            <div className="analytics-item">
              <label>Average Rating</label>
              <input
                type="number"
                value={profile.averageClientRating || ''}
                onChange={(e) =>
                  handleChange({
                    target: {
                      name: 'averageClientRating',
                      value: e.target.value,
                    },
                  })
                }
                placeholder="0.0"
                step="0.1"
                min="0"
                max="5"
              />
            </div>

            <div className="analytics-item">
              <label>Clients Served</label>
              <input
                type="number"
                value={profile.totalClientsServed || ''}
                onChange={(e) =>
                  handleChange({
                    target: {
                      name: 'totalClientsServed',
                      value: e.target.value,
                    },
                  })
                }
                placeholder="0"
                min="0"
              />
            </div>
          </div>

          <div className="analytics-metrics">
            <label>Key Achievements & Metrics</label>
            <textarea
              value={profile.specificMetrics || ''}
              onChange={(e) =>
                handleChange({
                  target: { name: 'specificMetrics', value: e.target.value },
                })
              }
              placeholder="e.g., Generated 10k leads across 3 campaigns, Improved site performance by 40%"
              rows="4"
            />
          </div>
        </div>
      </div>
    </div>
  );

  const renderCallToAction = () => (
    <div className="wizard-step">
      <h3>Call to Action</h3>
      <SectionNotice
        icon={<FaInfoCircle />}
        description="Control your profile visibility and contact preferences"
        details="Choose which contact information to display publicly on your profile"
      />
      <div className="visibility-section">
        <div className="visibility-group">
          <h4>Contact Information</h4>
          <div className="toggle-grid">
            <label className="visibility-toggle">
              <input
                type="checkbox"
                checked={profile.showEmail || false}
                onChange={(e) =>
                  handleChange({
                    target: { name: 'showEmail', value: e.target.checked },
                  })
                }
              />
              <span>Show Email</span>
            </label>

            <label className="visibility-toggle">
              <input
                type="checkbox"
                checked={profile.showWhatsapp || false}
                onChange={(e) =>
                  handleChange({
                    target: { name: 'showWhatsapp', value: e.target.checked },
                  })
                }
              />
              <span>Show WhatsApp</span>
            </label>

            <label className="visibility-toggle">
              <input
                type="checkbox"
                checked={profile.showPhone || false}
                onChange={(e) =>
                  handleChange({
                    target: { name: 'showPhone', value: e.target.checked },
                  })
                }
              />
              <span>Show Contact Number</span>
            </label>
          </div>
        </div>

        <div className="visibility-group">
          <h4>Social Media Links</h4>
          <div className="toggle-grid">
            <label className="visibility-toggle">
              <input
                type="checkbox"
                checked={profile.showFacebook || false}
                onChange={(e) =>
                  handleChange({
                    target: { name: 'showFacebook', value: e.target.checked },
                  })
                }
              />
              <span>Show Facebook</span>
            </label>

            <label className="visibility-toggle">
              <input
                type="checkbox"
                checked={profile.showTwitter || false}
                onChange={(e) =>
                  handleChange({
                    target: { name: 'showTwitter', value: e.target.checked },
                  })
                }
              />
              <span>Show Twitter</span>
            </label>

            <label className="visibility-toggle">
              <input
                type="checkbox"
                checked={profile.showInstagram || false}
                onChange={(e) =>
                  handleChange({
                    target: { name: 'showInstagram', value: e.target.checked },
                  })
                }
              />
              <span>Show Instagram</span>
            </label>

            <label className="visibility-toggle">
              <input
                type="checkbox"
                checked={profile.showLinkedIn || false}
                onChange={(e) =>
                  handleChange({
                    target: { name: 'showLinkedIn', value: e.target.checked },
                  })
                }
              />
              <span>Show LinkedIn</span>
            </label>
          </div>
        </div>

        <div className="visibility-group">
          <h4>Call to Action Buttons</h4>
          <input
            type="text"
            value={profile.primaryCTA || ''}
            onChange={(e) =>
              handleChange({
                target: { name: 'primaryCTA', value: e.target.value },
              })
            }
            placeholder="Primary CTA Button Text (e.g., Contact Me)"
            className="cta-input"
          />

          <input
            type="text"
            value={profile.secondaryCTA || ''}
            onChange={(e) =>
              handleChange({
                target: { name: 'secondaryCTA', value: e.target.value },
              })
            }
            placeholder="Secondary CTA Button Text (e.g., Download Portfolio PDF)"
            className="cta-input"
          />
        </div>
      </div>
    </div>
  );

  const validateAndHandlePortfolioImage = async (file) => {
    if (!file) return;

    // Check file type
    const validTypes = ['image/jpeg', 'image/png', 'image/webp'];
    if (!validTypes.includes(file.type)) {
      alert('Please upload a valid image file (JPG, PNG, or WebP)');
      return;
    }

    // Check file size (2MB)
    if (file.size > 2 * 1024 * 1024) {
      alert('Image must be less than 2MB');
      return;
    }

    // Check dimensions
    const img = new Image();
    const imageUrl = URL.createObjectURL(file);

    img.onload = async () => {
      URL.revokeObjectURL(imageUrl);

      if (img.width < 1200 || img.height < 675) {
        alert('Image must be at least 1200x675 pixels');
        return;
      }

      // If validation passes, handle the upload
      await handleImageUpload(file);
    };

    img.src = imageUrl;
  };

  const isPortfolioFormValid = () => {
    if (!editingPortfolioItem) return false;

    return (
      editingPortfolioItem.title?.trim() &&
      editingPortfolioItem.description?.trim() &&
      editingPortfolioItem.toolsUsed?.trim() &&
      editingPortfolioItem.mediaUrl
    );
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="profile-wizard">
      <h2>Complete Your Profile</h2>
      <ProfileWizardMascot step={step} />
      <div className="wizard-progress">
        <div className="progress-bar">
          <div
            className="progress"
            style={{ width: `${completionScore}%` }}
          ></div>
        </div>
        <span>{completionScore}% Complete</span>
      </div>
      <div className="wizard-nav">
        {steps.map((stepName, index) => (
          <button
            key={stepName}
            className={`step-button ${step === index ? 'active' : ''}`}
            onClick={() => setStep(index)}
            style={{
              backgroundColor: index <= step ? '#00f0ff' : '#1a1f2e',
              color: index <= step ? '#0a0e17' : '#a0a0a0',
            }}
          >
            {stepName}
          </button>
        ))}
      </div>
      {renderStep()}
      <div className="wizard-controls">
        <button onClick={() => setStep(Math.max(0, step - 1))}>Previous</button>
        <button onClick={handleSave}>Save Progress</button>
        <button onClick={() => setStep(Math.min(steps.length - 1, step + 1))}>
          Next
        </button>
        {step === steps.length - 1 && (
          <button onClick={handleComplete}>Complete Profile</button>
        )}
      </div>
    </div>
  );
}

ProfileWizard.propTypes = {
  userProfile: PropTypes.object.isRequired,
  onComplete: PropTypes.func.isRequired,
};

export default ProfileWizard;
