import axios from 'axios';

const FACEBOOK_API_URL = 'https://graph.facebook.com/v12.0';

const getAccessToken = async () => {
  try {
    const response = await axios.get('/api/facebook-token');
    return response.data.access_token;
  } catch (error) {
    console.error('Error fetching access token:', error);
    throw error;
  }
};

export const fetchFacebookPost = async (url) => {
  try {
    const postId = extractPostId(url);
    const accessToken = await getAccessToken();
    const response = await axios.get(`${FACEBOOK_API_URL}/${postId}`, {
      params: {
        fields: 'id,message,full_picture,from',
        access_token: accessToken,
      },
    });

    return {
      title: response.data.message.split('\n')[0] || '',
      content: response.data.message || '',
      author: response.data.from.name || '',
      imageUrl: response.data.full_picture || '',
    };
  } catch (error) {
    console.error('Error fetching Facebook post:', error);
    throw error;
  }
};

const extractPostId = (url) => {
  // Implement logic to extract post ID from Facebook URL
  // This will depend on the format of the URL
  // For example: https://www.facebook.com/username/posts/1234567890
  // You'd extract '1234567890' as the post ID
};
