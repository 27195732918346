import React, { useRef, useEffect, useState, Suspense } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Html } from '@react-three/drei';
import * as THREE from 'three';
import PropTypes from 'prop-types';
import { getCountryPosition, getCountryName } from '../utils/mapUtils';
import '../styles/WorldGlobe.css';

/* eslint-disable react/no-unknown-property */

const Earth = ({ selectedCountries }) => {
  const globeRef = useRef();
  const [earthTexture, setEarthTexture] = useState(null);
  const [isTextureLoaded, setIsTextureLoaded] = useState(false);
  const [textureError, setTextureError] = useState(null);

  useEffect(() => {
    const textureLoader = new THREE.TextureLoader();

    const loadTexture = async () => {
      try {
        const texture = await new Promise((resolve, reject) => {
          textureLoader.load(
            '/images/earth_atmos_2048.jpg',
            (loadedTexture) => {
              loadedTexture.needsUpdate = true;
              loadedTexture.colorSpace = THREE.SRGBColorSpace;
              loadedTexture.minFilter = THREE.LinearMipMapLinearFilter;
              loadedTexture.magFilter = THREE.LinearFilter;
              loadedTexture.generateMipmaps = true;
              loadedTexture.anisotropy = 16;
              loadedTexture.flipY = true;
              resolve(loadedTexture);
            },
            undefined,
            (error) => reject(error),
          );
        });

        setEarthTexture(texture);
        setIsTextureLoaded(true);
        console.log('Texture loaded successfully');
      } catch (error) {
        console.error('Error loading texture:', error);
        setTextureError(error);
      }
    };

    loadTexture();

    // Cleanup function
    return () => {
      if (earthTexture) {
        earthTexture.dispose();
      }
    };
  }, []);

  // Show loading state if texture is not ready
  if (!isTextureLoaded) {
    return (
      <Html center>
        <div style={{ color: '#00f0ff' }}>Loading globe texture...</div>
      </Html>
    );
  }

  // Show error state if texture failed to load
  if (textureError) {
    return (
      <Html center>
        <div style={{ color: '#ff0000' }}>Error loading globe texture</div>
      </Html>
    );
  }

  return (
    <>
      <ambientLight intensity={1} />
      <pointLight position={[10, 10, 10]} intensity={2.0} />
      <pointLight position={[-10, -10, -10]} intensity={1.0} />

      <mesh ref={globeRef} rotation={[0, -Math.PI / 2, 0]}>
        <sphereGeometry args={[1, 64, 64]} />
        <meshPhongMaterial
          map={earthTexture}
          shininess={5}
          specular={new THREE.Color(0x333333)}
          color={0xffffff}
          opacity={1}
          transparent={false}
          side={THREE.FrontSide}
          needsUpdate={true}
        />
      </mesh>

      {/* Country markers with enhanced ripple effect */}
      {selectedCountries?.map((countryCode) => {
        const position = getCountryPosition(countryCode);
        if (!position) return null;

        const adjustedPosition = [position[0], position[1], position[2]];

        return (
          <group key={countryCode}>
            {/* Main marker dot - slightly larger */}
            <mesh position={adjustedPosition}>
              <sphereGeometry args={[0.008, 16, 16]} />
              <meshBasicMaterial color="#00f0ff" transparent opacity={1} />
            </mesh>

            {/* First ripple */}
            <mesh position={adjustedPosition} className="marker-pulse-inner">
              <sphereGeometry args={[0.015, 16, 16]} />
              <meshBasicMaterial
                color="#00f0ff"
                transparent
                opacity={0.4}
                depthWrite={false}
              />
            </mesh>

            {/* Second ripple */}
            <mesh position={adjustedPosition} className="marker-pulse-outer">
              <sphereGeometry args={[0.025, 16, 16]} />
              <meshBasicMaterial
                color="#00f0ff"
                transparent
                opacity={0.3}
                depthWrite={false}
              />
            </mesh>

            {/* Third ripple - wider */}
            <mesh
              position={adjustedPosition}
              className="marker-pulse-outer-wide"
            >
              <sphereGeometry args={[0.035, 16, 16]} />
              <meshBasicMaterial
                color="#00f0ff"
                transparent
                opacity={0.2}
                depthWrite={false}
              />
            </mesh>

            {/* Fourth ripple - widest wave */}
            <mesh position={adjustedPosition} className="marker-pulse-wave">
              <sphereGeometry args={[0.05, 16, 16]} />
              <meshBasicMaterial
                color="#00f0ff"
                transparent
                opacity={0.1}
                depthWrite={false}
              />
            </mesh>

            {/* Country label */}
            <Html
              position={[
                adjustedPosition[0] * 1.15,
                adjustedPosition[1] * 1.15,
                adjustedPosition[2] * 1.15,
              ]}
              center
              style={{
                color: '#00f0ff',
                fontSize: '10px',
                fontWeight: 'bold',
                textShadow: '0 0 3px rgba(0,0,0,0.8)',
                pointerEvents: 'none',
                userSelect: 'none',
              }}
            >
              {getCountryName(countryCode)}
            </Html>
          </group>
        );
      })}
    </>
  );
};

Earth.propTypes = {
  selectedCountries: PropTypes.arrayOf(PropTypes.string),
};

const WorldGlobe = ({ selectedCountries = [] }) => {
  return (
    <div className="globe-container">
      <Canvas
        camera={{
          position: [0, 0, 2.5],
          fov: 45,
          near: 0.1,
          far: 1000,
        }}
        style={{
          background:
            'radial-gradient(circle at center, #0a0e17 0%, #050709 100%)',
        }}
        gl={{
          antialias: true,
          alpha: true,
          preserveDrawingBuffer: true,
        }}
      >
        <Suspense fallback={null}>
          <Earth selectedCountries={selectedCountries} />
        </Suspense>
        <OrbitControls
          enableZoom={false}
          enablePan={false}
          rotateSpeed={0.5}
          autoRotate
          autoRotateSpeed={0.5}
          minPolarAngle={Math.PI / 3}
          maxPolarAngle={Math.PI / 1.5}
        />
      </Canvas>
    </div>
  );
};

WorldGlobe.propTypes = {
  selectedCountries: PropTypes.arrayOf(PropTypes.string),
};

export default WorldGlobe;
