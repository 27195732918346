import { countries } from 'countries-list';

// Comprehensive list of all country coordinates
export const countryPositions = {
  // Europe
  AD: { lat: 42.5, lng: 1.5, name: 'Andorra' },
  AL: { lat: 41, lng: 20, name: 'Albania' },
  AT: { lat: 47.3333, lng: 13.3333, name: 'Austria' },
  BA: { lat: 44, lng: 18, name: 'Bosnia and Herzegovina' },
  BE: { lat: 50.8333, lng: 4, name: 'Belgium' },
  BG: { lat: 43, lng: 25, name: 'Bulgaria' },
  BY: { lat: 53, lng: 28, name: 'Belarus' },
  CH: { lat: 47, lng: 8, name: 'Switzerland' },
  CZ: { lat: 49.75, lng: 15.5, name: 'Czech Republic' },
  DE: { lat: 51, lng: 9, name: 'Germany' },
  DK: { lat: 56, lng: 10, name: 'Denmark' },
  EE: { lat: 59, lng: 26, name: 'Estonia' },
  ES: { lat: 40, lng: -4, name: 'Spain' },
  FI: { lat: 64, lng: 26, name: 'Finland' },
  FR: { lat: 46, lng: 2, name: 'France' },
  GB: { lat: 54, lng: -2, name: 'United Kingdom' },
  GR: { lat: 39, lng: 22, name: 'Greece' },
  HR: { lat: 45.1667, lng: 15.5, name: 'Croatia' },
  HU: { lat: 47, lng: 20, name: 'Hungary' },
  IE: { lat: 53, lng: -8, name: 'Ireland' },
  IS: { lat: 65, lng: -18, name: 'Iceland' },
  IT: { lat: 42.8333, lng: 12.8333, name: 'Italy' },
  LI: { lat: 47.1667, lng: 9.5333, name: 'Liechtenstein' },
  LT: { lat: 56, lng: 24, name: 'Lithuania' },
  LU: { lat: 49.75, lng: 6.1667, name: 'Luxembourg' },
  LV: { lat: 57, lng: 25, name: 'Latvia' },
  MC: { lat: 43.7333, lng: 7.4, name: 'Monaco' },
  MD: { lat: 47, lng: 29, name: 'Moldova' },
  ME: { lat: 42, lng: 19, name: 'Montenegro' },
  MK: { lat: 41.8333, lng: 22, name: 'North Macedonia' },
  MT: { lat: 35.8333, lng: 14.5833, name: 'Malta' },
  NL: { lat: 52.5, lng: 5.75, name: 'Netherlands' },
  NO: { lat: 62, lng: 10, name: 'Norway' },
  PL: { lat: 52, lng: 20, name: 'Poland' },
  PT: { lat: 39.5, lng: -8, name: 'Portugal' },
  RO: { lat: 46, lng: 25, name: 'Romania' },
  RS: { lat: 44, lng: 21, name: 'Serbia' },
  RU: { lat: 60, lng: 100, name: 'Russia' },
  SE: { lat: 62, lng: 15, name: 'Sweden' },
  SI: { lat: 46, lng: 15, name: 'Slovenia' },
  SK: { lat: 48.6667, lng: 19.5, name: 'Slovakia' },
  UA: { lat: 49, lng: 32, name: 'Ukraine' },
  VA: { lat: 41.9, lng: 12.45, name: 'Vatican City' },

  // North America
  US: { lat: 38, lng: -97, name: 'United States' },
  CA: { lat: 60, lng: -95, name: 'Canada' },
  MX: { lat: 23, lng: -102, name: 'Mexico' },
  GL: { lat: 72, lng: -40, name: 'Greenland' },
  BM: { lat: 32.3333, lng: -64.75, name: 'Bermuda' },
  GT: { lat: 15.5, lng: -90.25, name: 'Guatemala' },
  BZ: { lat: 17.25, lng: -88.75, name: 'Belize' },
  SV: { lat: 13.8333, lng: -88.9167, name: 'El Salvador' },
  HN: { lat: 15, lng: -86.5, name: 'Honduras' },
  NI: { lat: 13, lng: -85, name: 'Nicaragua' },
  CR: { lat: 10, lng: -84, name: 'Costa Rica' },
  PA: { lat: 9, lng: -80, name: 'Panama' },

  // South America
  AR: { lat: -34, lng: -64, name: 'Argentina' },
  BO: { lat: -17, lng: -65, name: 'Bolivia' },
  BR: { lat: -10, lng: -55, name: 'Brazil' },
  CL: { lat: -30, lng: -71, name: 'Chile' },
  CO: { lat: 4, lng: -72, name: 'Colombia' },
  EC: { lat: -2, lng: -77.5, name: 'Ecuador' },
  GY: { lat: 5, lng: -59, name: 'Guyana' },
  PE: { lat: -10, lng: -76, name: 'Peru' },
  PY: { lat: -23, lng: -58, name: 'Paraguay' },
  SR: { lat: 4, lng: -56, name: 'Suriname' },
  UY: { lat: -33, lng: -56, name: 'Uruguay' },
  VE: { lat: 8, lng: -66, name: 'Venezuela' },

  // Asia
  AF: { lat: 33, lng: 65, name: 'Afghanistan' },
  AE: { lat: 24, lng: 54, name: 'United Arab Emirates' },
  AM: { lat: 40, lng: 45, name: 'Armenia' },
  AZ: { lat: 40.5, lng: 47.5, name: 'Azerbaijan' },
  BD: { lat: 24, lng: 90, name: 'Bangladesh' },
  BH: { lat: 26, lng: 50.55, name: 'Bahrain' },
  BN: { lat: 4.5, lng: 114.6667, name: 'Brunei' },
  BT: { lat: 27.5, lng: 90.5, name: 'Bhutan' },
  CN: { lat: 35, lng: 105, name: 'China' },
  GE: { lat: 42, lng: 43.5, name: 'Georgia' },
  HK: { lat: 22.25, lng: 114.1667, name: 'Hong Kong' },
  ID: { lat: -5, lng: 120, name: 'Indonesia' },
  IN: { lat: 20, lng: 77, name: 'India' },
  IQ: { lat: 33, lng: 44, name: 'Iraq' },
  IR: { lat: 32, lng: 53, name: 'Iran' },
  IL: { lat: 31.5, lng: 34.75, name: 'Israel' },
  JP: { lat: 36, lng: 138, name: 'Japan' },
  JO: { lat: 31, lng: 36, name: 'Jordan' },
  KH: { lat: 13, lng: 105, name: 'Cambodia' },
  KP: { lat: 40, lng: 127, name: 'North Korea' },
  KR: { lat: 37, lng: 127.5, name: 'South Korea' },
  KW: { lat: 29.3375, lng: 47.6581, name: 'Kuwait' },
  KZ: { lat: 48, lng: 68, name: 'Kazakhstan' },
  LA: { lat: 18, lng: 105, name: 'Laos' },
  LB: { lat: 33.8333, lng: 35.8333, name: 'Lebanon' },
  LK: { lat: 7, lng: 81, name: 'Sri Lanka' },
  MM: { lat: 22, lng: 98, name: 'Myanmar' },
  MN: { lat: 46, lng: 105, name: 'Mongolia' },
  MY: { lat: 2.5, lng: 112.5, name: 'Malaysia' },
  NP: { lat: 28, lng: 84, name: 'Nepal' },
  OM: { lat: 21, lng: 57, name: 'Oman' },
  PH: { lat: 13, lng: 122, name: 'Philippines' },
  PK: { lat: 30, lng: 70, name: 'Pakistan' },
  PS: { lat: 32, lng: 35.25, name: 'Palestine' },
  QA: { lat: 25.5, lng: 51.25, name: 'Qatar' },
  SA: { lat: 25, lng: 45, name: 'Saudi Arabia' },
  SG: { lat: 1.3667, lng: 103.8, name: 'Singapore' },
  SY: { lat: 35, lng: 38, name: 'Syria' },
  TH: { lat: 15, lng: 100, name: 'Thailand' },
  TJ: { lat: 39, lng: 71, name: 'Tajikistan' },
  TM: { lat: 40, lng: 60, name: 'Turkmenistan' },
  TW: { lat: 23.5, lng: 121, name: 'Taiwan' },
  UZ: { lat: 41, lng: 64, name: 'Uzbekistan' },
  VN: { lat: 16, lng: 106, name: 'Vietnam' },
  YE: { lat: 15, lng: 48, name: 'Yemen' },

  // Africa
  AO: { lat: -12.5, lng: 18.5, name: 'Angola' },
  BF: { lat: 13, lng: -2, name: 'Burkina Faso' },
  BI: { lat: -3.5, lng: 30, name: 'Burundi' },
  BJ: { lat: 9.5, lng: 2.25, name: 'Benin' },
  BW: { lat: -22, lng: 24, name: 'Botswana' },
  CD: { lat: 0, lng: 25, name: 'Democratic Republic of the Congo' },
  CF: { lat: 7, lng: 21, name: 'Central African Republic' },
  CG: { lat: -1, lng: 15, name: 'Republic of the Congo' },
  CI: { lat: 8, lng: -5, name: "Côte d'Ivoire" },
  CM: { lat: 6, lng: 12, name: 'Cameroon' },
  DJ: { lat: 11.5, lng: 43, name: 'Djibouti' },
  DZ: { lat: 36, lng: 3, name: 'Algeria' },
  EG: { lat: 27, lng: 30, name: 'Egypt' },
  ER: { lat: 15, lng: 39, name: 'Eritrea' },
  ET: { lat: 8, lng: 38, name: 'Ethiopia' },
  GA: { lat: -1, lng: 11.75, name: 'Gabon' },
  GH: { lat: 8, lng: -2, name: 'Ghana' },
  GM: { lat: 13.4667, lng: -16.5667, name: 'Gambia' },
  GN: { lat: 11, lng: -10, name: 'Guinea' },
  GQ: { lat: 2, lng: 10, name: 'Equatorial Guinea' },
  GW: { lat: 12, lng: -15, name: 'Guinea-Bissau' },
  KE: { lat: 1, lng: 38, name: 'Kenya' },
  LR: { lat: 6.5, lng: -9.5, name: 'Liberia' },
  LS: { lat: -29.5, lng: 28.5, name: 'Lesotho' },
  LY: { lat: 25, lng: 17, name: 'Libya' },
  MA: { lat: 32, lng: -5, name: 'Morocco' },
  MG: { lat: -20, lng: 47, name: 'Madagascar' },
  ML: { lat: 17, lng: -4, name: 'Mali' },
  MR: { lat: 20, lng: -12, name: 'Mauritania' },
  MW: { lat: -13.5, lng: 34, name: 'Malawi' },
  MZ: { lat: -18.25, lng: 35, name: 'Mozambique' },
  NA: { lat: -22, lng: 17, name: 'Namibia' },
  NE: { lat: 16, lng: 8, name: 'Niger' },
  NG: { lat: 10, lng: 8, name: 'Nigeria' },
  RW: { lat: -2, lng: 30, name: 'Rwanda' },
  SD: { lat: 15, lng: 30, name: 'Sudan' },
  SS: { lat: 8, lng: 30, name: 'South Sudan' },
  SL: { lat: 8.5, lng: -11.5, name: 'Sierra Leone' },
  SN: { lat: 14, lng: -14, name: 'Senegal' },
  SO: { lat: 10, lng: 49, name: 'Somalia' },
  SZ: { lat: -26.5, lng: 31.5, name: 'Eswatini' },
  TD: { lat: 15, lng: 19, name: 'Chad' },
  TG: { lat: 8, lng: 1.1667, name: 'Togo' },
  TN: { lat: 34, lng: 9, name: 'Tunisia' },
  TZ: { lat: -6, lng: 35, name: 'Tanzania' },
  UG: { lat: 1, lng: 32, name: 'Uganda' },
  ZA: { lat: -29, lng: 24, name: 'South Africa' },
  ZM: { lat: -15, lng: 30, name: 'Zambia' },
  ZW: { lat: -20, lng: 30, name: 'Zimbabwe' },

  // Oceania
  AU: { lat: -27, lng: 133, name: 'Australia' },
  FJ: { lat: -18, lng: 175, name: 'Fiji' },
  NZ: { lat: -41, lng: 174, name: 'New Zealand' },
  PG: { lat: -6, lng: 147, name: 'Papua New Guinea' },
  SB: { lat: -8, lng: 159, name: 'Solomon Islands' },
  VU: { lat: -16, lng: 167, name: 'Vanuatu' },
};

export const getCountryPosition = (countryCode, radius = 1.01) => {
  const country = countryPositions[countryCode];
  if (!country) {
    console.warn(`No position data for country code: ${countryCode}`);
    return null;
  }

  // Convert lat/lng to radians
  const lat = country.lat * (Math.PI / 150);
  const lng = country.lng * (Math.PI / 150);

  // Convert to Cartesian coordinates with X-axis rotation
  // Rotate coordinates 90 degrees around X-axis
  const x = radius * Math.cos(lat) * Math.sin(lng);
  const y = radius * Math.sin(lat);
  const z = -radius * Math.cos(lat) * Math.cos(lng);

  return [x, y, z];
};

export const getCountryName = (countryCode) => {
  return (
    countryPositions[countryCode]?.name ||
    countries[countryCode]?.name ||
    countryCode
  );
};

// Helper function to check if country code exists
export const isValidCountryCode = (countryCode) => {
  return countryCode in countryPositions || countryCode in countries;
};

// Helper function to get country coordinates
export const getCountryCoordinates = (countryCode) => {
  const country = countryPositions[countryCode];
  if (!country) return null;
  return { lat: country.lat, lng: country.lng };
};
