import React, { useState } from 'react';
import { BADGES, BADGE_CATEGORIES } from '../data/badges';
import '../styles/BadgeManagement.css';
import { FaSearch, FaFilter, FaPlus, FaEdit, FaTrash } from 'react-icons/fa';

function BadgeManagement() {
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [viewMode, setViewMode] = useState('grid');

  const filteredBadges = Object.values(BADGES).filter((badge) => {
    const matchesCategory =
      selectedCategory === 'all' || badge.category === selectedCategory;
    const matchesSearch =
      badge.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      badge.description.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesCategory && matchesSearch;
  });

  const badgeStats = {
    total: Object.keys(BADGES).length,
    byCategory: Object.values(BADGES).reduce((acc, badge) => {
      acc[badge.category] = (acc[badge.category] || 0) + 1;
      return acc;
    }, {}),
  };

  return (
    <div className="badge-management">
      <div className="badge-management-header">
        <div className="badge-stats">
          <h2>Badge Management</h2>
          <div className="stats-pills">
            <span className="stat-pill">Total Badges: {badgeStats.total}</span>
            <span className="stat-pill">
              Categories: {Object.keys(badgeStats.byCategory).length}
            </span>
          </div>
        </div>
        <button className="create-badge-btn">
          <FaPlus /> New Badge
        </button>
      </div>

      <div className="badge-controls">
        <div className="search-filter">
          <div className="search-wrapper">
            <FaSearch className="search-icon" />
            <input
              type="text"
              placeholder="Search badges..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="badge-search"
            />
          </div>
          <div className="filter-wrapper">
            <FaFilter className="filter-icon" />
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="category-filter"
            >
              <option value="all">All Categories</option>
              {Object.values(BADGE_CATEGORIES).map((category) => (
                <option key={category} value={category}>
                  {category} ({badgeStats.byCategory[category] || 0})
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="view-toggle">
          <button
            className={`view-btn ${viewMode === 'grid' ? 'active' : ''}`}
            onClick={() => setViewMode('grid')}
          >
            Grid
          </button>
          <button
            className={`view-btn ${viewMode === 'list' ? 'active' : ''}`}
            onClick={() => setViewMode('list')}
          >
            List
          </button>
        </div>
      </div>

      <div className={`badges-container ${viewMode}`}>
        {filteredBadges.map((badge) => (
          <div key={badge.id} className="badge-card">
            <div className="badge-header">
              <span className="badge-icon">{badge.icon}</span>
              <div className="badge-title">
                <h3>{badge.name}</h3>
                <span className="badge-category">{badge.category}</span>
              </div>
            </div>

            <div className="badge-content">
              <p className="badge-description">{badge.description}</p>
            </div>

            <div className="badge-actions">
              <button className="btn-edit">
                <FaEdit /> Edit
              </button>
              <button className="btn-delete">
                <FaTrash /> Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      {filteredBadges.length === 0 && (
        <div className="no-results">
          <p>No badges found matching your criteria</p>
        </div>
      )}
    </div>
  );
}

export default BadgeManagement;
