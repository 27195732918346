export const BADGE_CATEGORIES = {
  PROFILE_COMPLETION: 'Profile Completion',
  GLOBAL_REACH: 'Global Reach',
  SERVICES: 'Services',
  COMMUNITY: 'Community',
  INVITATION: 'Invitation',
  RECOGNITION: 'Recognition',
};

export const BADGES = {
  // Profile Completion Series
  profile_starter: {
    id: 'profile_starter',
    category: BADGE_CATEGORIES.PROFILE_COMPLETION,
    name: 'Profile Starter',
    description: 'Completed basic profile information',
    icon: '📝',
    criteria: {
      requiredFields: ['name', 'profession', 'bio'],
    },
  },
  profile_pro: {
    id: 'profile_pro',
    category: BADGE_CATEGORIES.PROFILE_COMPLETION,
    name: 'Profile Pro',
    description: 'Added profile picture and cover image',
    icon: '🎯',
    criteria: {
      requiredFields: ['profilePictureUrl', 'coverImageUrl'],
    },
  },
  profile_master: {
    id: 'profile_master',
    category: BADGE_CATEGORIES.PROFILE_COMPLETION,
    name: 'Profile Master',
    description: 'Completed all profile sections',
    icon: '🌟',
    criteria: {
      completionPercentage: 100,
    },
  },

  // Global Reach Series
  global_starter: {
    id: 'global_starter',
    category: BADGE_CATEGORIES.GLOBAL_REACH,
    name: 'Global Starter',
    description: 'Serves in 5 countries',
    icon: '🌍',
    criteria: {
      countriesCount: 5,
    },
  },
  global_explorer: {
    id: 'global_explorer',
    category: BADGE_CATEGORIES.GLOBAL_REACH,
    name: 'Global Explorer',
    description: 'Serves in 10 countries',
    icon: '🌎',
    criteria: {
      countriesCount: 10,
    },
  },
  global_master: {
    id: 'global_master',
    category: BADGE_CATEGORIES.GLOBAL_REACH,
    name: 'Global Master',
    description: 'Serves in 15 or more countries',
    icon: '🌏',
    criteria: {
      countriesCount: 15,
    },
  },

  // Services Series
  service_starter: {
    id: 'service_starter',
    category: BADGE_CATEGORIES.SERVICES,
    name: 'Service Starter',
    description: 'Added first service',
    icon: '🛠️',
    criteria: {
      servicesCount: 1,
    },
  },
  service_pro: {
    id: 'service_pro',
    category: BADGE_CATEGORIES.SERVICES,
    name: 'Service Pro',
    description: 'Added 5 services',
    icon: '⚡',
    criteria: {
      servicesCount: 5,
    },
  },
  service_master: {
    id: 'service_master',
    category: BADGE_CATEGORIES.SERVICES,
    name: 'Service Master',
    description: 'Added 10 services',
    icon: '🔥',
    criteria: {
      servicesCount: 10,
    },
  },

  // Community Recognition Series
  loved_starter: {
    id: 'loved_starter',
    category: BADGE_CATEGORIES.RECOGNITION,
    name: 'Rising Star',
    description: 'Received 10 profile loves',
    icon: '💝',
    criteria: {
      lovesCount: 10,
    },
  },
  loved_pro: {
    id: 'loved_pro',
    category: BADGE_CATEGORIES.RECOGNITION,
    name: 'Community Favorite',
    description: 'Received 50 profile loves',
    icon: '💖',
    criteria: {
      lovesCount: 50,
    },
  },
  loved_master: {
    id: 'loved_master',
    category: BADGE_CATEGORIES.RECOGNITION,
    name: 'Community Icon',
    description: 'Received 100 profile loves',
    icon: '💫',
    criteria: {
      lovesCount: 100,
    },
  },

  // Invitation Series
  invitation_starter: {
    id: 'invitation_starter',
    category: BADGE_CATEGORIES.INVITATION,
    name: 'Community Builder',
    description: 'First successful invitation',
    icon: '🤝',
    criteria: {
      invitationsUsed: 1,
    },
  },
  invitation_pro: {
    id: 'invitation_pro',
    category: BADGE_CATEGORIES.INVITATION,
    name: 'Community Leader',
    description: '3 successful invitations',
    icon: '👥',
    criteria: {
      invitationsUsed: 3,
    },
  },
  invitation_master: {
    id: 'invitation_master',
    category: BADGE_CATEGORIES.INVITATION,
    name: 'Community Champion',
    description: 'All 5 invitations used successfully',
    icon: '👑',
    criteria: {
      invitationsUsed: 5,
    },
  },
};

export const checkBadgeEligibility = (badge, userData) => {
  if (!badge || !badge.criteria || !userData) return false;

  const { criteria } = badge;

  if (criteria.requiredFields) {
    return criteria.requiredFields.every((field) => userData[field]);
  }

  if (criteria.completionPercentage) {
    return (
      (userData.profileCompletionPercentage || 0) >=
      criteria.completionPercentage
    );
  }

  if (criteria.countriesCount) {
    return (userData.countriesServed?.length || 0) >= criteria.countriesCount;
  }

  if (criteria.servicesCount) {
    return (userData.servicesCount || 0) >= criteria.servicesCount;
  }

  if (criteria.lovesCount) {
    return (userData.loveCount || 0) >= criteria.lovesCount;
  }

  if (criteria.invitationsUsed) {
    return (userData.successfulInvites || 0) >= criteria.invitationsUsed;
  }

  return false;
};
