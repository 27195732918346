import { db, auth } from '../firebase';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  getDoc,
  setDoc,
  query,
  where,
  increment,
  arrayUnion,
  serverTimestamp,
  orderBy,
} from 'firebase/firestore';

// User Management
const MAX_RETRIES = 3;
const RETRY_DELAY = 1000; // 1 second

export const getAllUsers = async (retryCount = 0) => {
  try {
    const usersCollection = collection(db, 'users');
    const userSnapshot = await getDocs(usersCollection);
    return userSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error(`Error fetching users (attempt ${retryCount + 1}):`, error);
    if (retryCount < MAX_RETRIES) {
      console.log(`Retrying in ${RETRY_DELAY / 1000} seconds...`);
      await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
      return getAllUsers(retryCount + 1);
    } else {
      throw error;
    }
  }
};

export const addUser = async (userData) => {
  const userRef = await db.collection('users').add({
    ...userData,
    isModerator: false,
    isProfilePublic: true, // Default to public
    createdAt: new Date(),
  });
  return userRef;
};

export const updateUser = async (userId, userData) => {
  try {
    const currentUserRole = await getUserRole();
    const isAdmin = currentUserRole === 'admin';
    const isSelfUpdate = auth.currentUser?.uid === userId;

    // Only admins can update admin/moderator status
    if (userData.isAdmin !== undefined || userData.isModerator !== undefined) {
      if (!isAdmin) {
        throw new Error('Only administrators can modify user roles');
      }
    }

    // Users can only update their own profiles unless they're admins
    if (!isAdmin && !isSelfUpdate) {
      throw new Error('You can only update your own profile');
    }

    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      ...userData,
      updatedAt: serverTimestamp(),
      updatedBy: auth.currentUser.uid,
    });

    return true;
  } catch (error) {
    console.error('Error in updateUser:', error);
    throw error;
  }
};

export const deleteUser = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    await deleteDoc(userRef);
    return true;
  } catch (error) {
    console.error('Error in deleteUser:', error);
    throw error;
  }
};

// Event Management
export const getAllEvents = async () => {
  const eventsCollection = collection(db, 'events');
  const eventSnapshot = await getDocs(eventsCollection);
  return eventSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const addEvent = async (eventData) => {
  return await addDoc(collection(db, 'events'), eventData);
};

export const updateEvent = async (eventId, eventData) => {
  const eventRef = doc(db, 'events', eventId);
  return await updateDoc(eventRef, eventData);
};

export const deleteEvent = async (eventId) => {
  return await deleteDoc(doc(db, 'events', eventId));
};

// Post Management
export const getAllPosts = async () => {
  const postsCollection = collection(db, 'posts');
  const postSnapshot = await getDocs(postsCollection);
  return postSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};

export const addPost = async (postData) => {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) throw new Error('Not authenticated');

    const postRef = await addDoc(collection(db, 'posts'), {
      ...postData,
      createdBy: currentUser.uid,
      createdAt: serverTimestamp(),
    });

    return postRef;
  } catch (error) {
    console.error('Error in addPost:', error);
    throw error;
  }
};

export const updatePost = async (postId, postData) => {
  try {
    const postRef = doc(db, 'posts', postId);
    await updateDoc(postRef, {
      ...postData,
      updatedAt: serverTimestamp(),
      updatedBy: auth.currentUser.uid,
    });
    return true;
  } catch (error) {
    console.error('Error updating post:', error);
    throw error;
  }
};

export const deletePost = async (postId) => {
  try {
    await deleteDoc(doc(db, 'posts', postId));
    return true;
  } catch (error) {
    console.error('Error deleting post:', error);
    throw error;
  }
};

// Page Content Management
export const getPageContent = async () => {
  const contentDoc = await getDoc(doc(db, 'pageContent', 'main'));
  return contentDoc.exists() ? contentDoc.data() : null;
};

export const updatePageContent = async (contentData) => {
  const contentRef = doc(db, 'pageContent', 'main');
  return await setDoc(contentRef, contentData, { merge: true });
};

// Dashboard Data
export const getDashboardData = async () => {
  const users = await getAllUsers();
  const events = await getAllEvents();
  const posts = await getAllPosts();
  const pageContent = await getPageContent();

  return {
    userCount: users.length,
    eventCount: events.length,
    postCount: posts.length,
    pageContentSections: pageContent ? Object.keys(pageContent).length : 0,
  };
};

// Catalog Management
export const getCatalogs = async () => {
  const catalogsRef = doc(db, 'catalogs', 'all');
  const catalogsSnap = await getDoc(catalogsRef);
  return catalogsSnap.exists() ? catalogsSnap.data() : null;
};

export const updateCatalog = async (category, subCategory, items) => {
  const catalogsRef = doc(db, 'catalogs', 'all');
  await setDoc(
    catalogsRef,
    { [category]: { [subCategory]: items } },
    { merge: true },
  );
};

// Fetch user profile data
export const fetchUserProfile = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      return {
        ...userData,
        skills: userData.skills || {},
        technologies: userData.technologies || {},
        platforms: userData.platforms || {},
        proficiencyLevels: userData.proficiencyLevels || {},
      };
    }
    return null;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    throw error;
  }
};

// Fetch user services
export const fetchUserServices = async (userId) => {
  const servicesQuery = query(
    collection(db, 'services'),
    where('userId', '==', userId),
  );
  const servicesSnapshot = await getDocs(servicesQuery);
  return servicesSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
};

// Invitation Management
const generateInviteCode = () => {
  return Math.random().toString(36).substring(2, 10).toUpperCase();
};

export const createInvitationCode = async (userId) => {
  if (!userId) {
    throw new Error('User ID is required to create an invitation code');
  }

  try {
    const code = Math.random().toString(36).substring(2, 8).toUpperCase();
    const codeData = {
      code: code,
      createdBy: userId,
      createdAt: serverTimestamp(),
      expiresAt: serverTimestamp(), // Will be set to 30 days from now
      usedCount: 0,
      maxUses: 5,
      usedBy: [],
      isActive: true,
    };

    const docRef = await addDoc(collection(db, 'invitationCodes'), codeData);

    // Update the expiration date to 30 days from creation
    await updateDoc(docRef, {
      expiresAt: new Date(Date.now() + 30 * 24 * 60 * 60 * 1000),
    });

    return { code, docId: docRef.id };
  } catch (error) {
    console.error('Error creating invitation code:', error);
    throw new Error('Failed to create invitation code');
  }
};

export const validateInviteCode = async (code) => {
  if (!code) {
    return { valid: false, message: 'Invitation code is required' };
  }

  const formattedCode = code.trim().toUpperCase();
  console.log('Attempting to validate code:', formattedCode);

  try {
    // Query by code field instead of document ID
    const codesRef = collection(db, 'invitationCodes');
    const q = query(codesRef, where('code', '==', formattedCode));
    const querySnapshot = await getDocs(q);

    console.log('Query snapshot size:', querySnapshot.size);

    if (querySnapshot.empty) {
      return {
        valid: false,
        message: 'Invalid invitation code',
        docId: null,
      };
    }

    const codeDoc = querySnapshot.docs[0];
    const codeData = codeDoc.data();
    console.log('Found code data:', codeData);

    // Check if code has expired
    if (codeData.expiresAt && codeData.expiresAt.toDate() < new Date()) {
      return {
        valid: false,
        message: 'This invitation code has expired',
        docId: null,
      };
    }

    // Check if code has reached its usage limit
    if (codeData.maxUses && codeData.usedCount >= codeData.maxUses) {
      return {
        valid: false,
        message: 'This invitation code has reached its usage limit',
        docId: null,
      };
    }

    return {
      valid: true,
      message: 'Valid invitation code',
      docId: codeDoc.id,
      codeData: codeData,
    };
  } catch (error) {
    console.error('Error validating invitation code:', error);
    throw new Error('Error validating invitation code');
  }
};

export const useInvitationCode = async (codeId, userId) => {
  if (!codeId || !userId) {
    throw new Error('Both code ID and user ID are required');
  }

  try {
    const codeRef = doc(db, 'invitationCodes', codeId);
    const codeDoc = await getDoc(codeRef);

    if (!codeDoc.exists()) {
      throw new Error('Invalid invitation code');
    }

    const codeData = codeDoc.data();

    // Additional validations
    if (!codeData.isActive) {
      throw new Error('This code has been deactivated');
    }

    if (codeData.usedBy.includes(userId)) {
      throw new Error('You have already used this code');
    }

    await updateDoc(codeRef, {
      usedCount: increment(1),
      usedBy: arrayUnion(userId),
      lastUsedAt: serverTimestamp(),
      isActive: codeData.maxUses
        ? codeData.usedCount + 1 < codeData.maxUses
        : true,
    });

    return true;
  } catch (error) {
    console.error('Error using invitation code:', error);
    throw error;
  }
};

export const getInviteCodeStats = async (userId) => {
  const codesQuery = query(
    collection(db, 'invitationCodes'),
    where('userId', '==', userId),
  );
  const snapshot = await getDocs(codesQuery);
  return snapshot.docs.map((doc) => doc.data());
};

// Add this helper function
export const checkInviteCodeExists = async (code) => {
  if (!code) return false;

  const formattedCode = code.trim().toUpperCase();
  console.log('Checking if code exists:', formattedCode);

  try {
    const codesRef = collection(db, 'invitationCodes');
    const q = query(codesRef, where('code', '==', formattedCode));
    const querySnapshot = await getDocs(q);

    const exists = !querySnapshot.empty;
    console.log('Code exists:', exists);
    return exists;
  } catch (error) {
    console.error('Error checking invitation code:', error);
    return false;
  }
};

// Add a function to create a test invitation code
export const createTestInvitationCode = async () => {
  const code = 'TEST123';
  console.log('Creating test invitation code:', code);

  try {
    const codeRef = doc(db, 'invitationCodes', code);
    const codeData = {
      code: code,
      userId: 'admin',
      usedCount: 0,
      maxUses: 5,
      usedBy: [],
      createdAt: new Date().toISOString(),
    };

    await setDoc(codeRef, codeData);

    // Verify the code was created
    const verifyDoc = await getDoc(codeRef);
    if (!verifyDoc.exists()) {
      throw new Error('Failed to verify test code creation');
    }

    console.log('Successfully created and verified test code');
    return code;
  } catch (error) {
    console.error('Error creating test code:', error);
    throw new Error('Failed to create test invitation code');
  }
};

// Add this helper function for debugging
export const debugInviteCodes = async () => {
  try {
    const codesCollection = collection(db, 'invitationCodes');
    const codesSnapshot = await getDocs(codesCollection);

    console.log('All invitation codes in database:');
    codesSnapshot.forEach((doc) => {
      console.log('Code:', doc.id);
      console.log('Data:', doc.data());
    });

    return codesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error('Error fetching invitation codes:', error);
    return [];
  }
};

// Add these new functions to handle invitation requests

// Create invitation request
export const createInvitationRequest = async (requestData) => {
  try {
    const inviteRequestRef = collection(db, 'invitationRequests');
    const docRef = await addDoc(inviteRequestRef, {
      ...requestData,
      status: 'pending',
      createdAt: serverTimestamp(),
      metadata: {
        userAgent: window.navigator.userAgent,
        platform: window.navigator.platform,
      },
    });

    console.log('Invitation request created with ID:', docRef.id);
    return docRef.id;
  } catch (error) {
    console.error('Error creating invitation request:', error);
    throw new Error('Failed to submit invitation request');
  }
};

// Get invitation request status
export const getInvitationRequestStatus = async (email) => {
  try {
    const requestsRef = collection(db, 'invitationRequests');
    const q = query(requestsRef, where('email', '==', email));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return null;
    }

    // Return the most recent request
    const requests = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return requests.sort((a, b) => b.createdAt - a.createdAt)[0];
  } catch (error) {
    console.error('Error getting invitation request status:', error);
    throw new Error('Failed to check invitation request status');
  }
};

// Check if email has pending request
export const hasPendingInvitationRequest = async (email) => {
  try {
    const requestsRef = collection(db, 'invitationRequests');
    const q = query(
      requestsRef,
      where('email', '==', email),
      where('status', '==', 'pending'),
    );
    const querySnapshot = await getDocs(q);

    return !querySnapshot.empty;
  } catch (error) {
    console.error('Error checking pending invitation request:', error);
    throw new Error('Failed to check pending invitation request');
  }
};

// Update invitation request status (admin only)
export const updateInvitationRequestStatus = async (
  requestId,
  newStatus,
  invitationCode = null,
) => {
  try {
    const requestRef = doc(db, 'invitationRequests', requestId);
    const updateData = {
      status: newStatus,
      updatedAt: serverTimestamp(),
    };

    if (invitationCode) {
      updateData.invitationCode = invitationCode;
    }

    await updateDoc(requestRef, updateData);

    return true;
  } catch (error) {
    console.error('Error updating invitation request status:', error);
    throw new Error('Failed to update invitation request status');
  }
};

// Get all invitation requests (admin only)
export const getAllInvitationRequests = async () => {
  try {
    const requestsRef = collection(db, 'invitationRequests');
    const querySnapshot = await getDocs(requestsRef);

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error('Error getting all invitation requests:', error);
    throw new Error('Failed to fetch invitation requests');
  }
};

// Delete invitation request (admin only)
export const deleteInvitationRequest = async (requestId) => {
  try {
    await deleteDoc(doc(db, 'invitationRequests', requestId));
    return true;
  } catch (error) {
    console.error('Error deleting invitation request:', error);
    throw new Error('Failed to delete invitation request');
  }
};

// Check if invitation code exists and is valid
export const validateInvitationCode = async (code) => {
  try {
    const inviteRef = doc(db, 'invitations', code);
    const inviteDoc = await getDoc(inviteRef);

    if (!inviteDoc.exists()) {
      return { valid: false, message: 'Invalid invitation code' };
    }

    const inviteData = inviteDoc.data();
    if (inviteData.used) {
      return {
        valid: false,
        message: 'This invitation code has already been used',
      };
    }

    return { valid: true, data: inviteData };
  } catch (error) {
    console.error('Error validating invitation code:', error);
    throw new Error('Failed to validate invitation code');
  }
};

// Add this function to fetch complete user profile
export const fetchPublicProfile = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (!userDoc.exists()) {
      throw new Error('Profile not found');
    }

    const userData = userDoc.data();
    // Only return data that should be public
    const publicProfile = {
      name: userData.name,
      fullName: userData.fullName,
      bio: userData.bio,
      profession: userData.profession,
      location: userData.location,
      profilePictureUrl: userData.profilePictureUrl,
      coverImageUrl: userData.coverImageUrl,
      skills: userData.skills,
      badges: userData.badges,
      technologies: userData.technologies,
      platforms: userData.platforms,
      languages: userData.languages,
      availabilityStatus: userData.availabilityStatus,
      availabilityTypes: userData.availabilityTypes,
      countriesServed: userData.countriesServed,
      workExperience: userData.workExperience,
      portfolioItems: userData.portfolioItems,

      // Only include contact info if user has opted to show it
      ...(userData.showEmail && { email: userData.email }),
      ...(userData.showPhone && { phone: userData.phone }),
      ...(userData.showWhatsapp && { whatsapp: userData.whatsapp }),

      // Only include social links if user has opted to show them
      ...(userData.showLinkedIn && { linkedin: userData.linkedin }),
      ...(userData.showTwitter && { twitter: userData.twitter }),
      ...(userData.showFacebook && { facebook: userData.facebook }),
      ...(userData.showInstagram && { instagram: userData.instagram }),

      // Include visibility settings (needed for UI rendering decisions)
      showEmail: userData.showEmail,
      showPhone: userData.showPhone,
      showWhatsapp: userData.showWhatsapp,
      showLinkedIn: userData.showLinkedIn,
      showTwitter: userData.showTwitter,
      showFacebook: userData.showFacebook,
      showInstagram: userData.showInstagram,
    };

    return publicProfile;
  } catch (error) {
    console.error('Error fetching public profile:', error);
    throw error;
  }
};

// Add these functions for messages
export const fetchUserMessages = async (userId) => {
  try {
    const messagesQuery = query(
      collection(db, 'messages'),
      where('to', '==', userId),
      orderBy('createdAt', 'desc'),
    );

    const snapshot = await getDocs(messagesQuery);
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate(),
    }));
  } catch (error) {
    console.error('Error fetching messages:', error);
    throw error;
  }
};

export const markMessageAsRead = async (messageId) => {
  try {
    const messageRef = doc(db, 'messages', messageId);
    await updateDoc(messageRef, {
      read: true,
    });
  } catch (error) {
    console.error('Error marking message as read:', error);
    throw error;
  }
};

// Add these functions for badge management

export const getUserBadges = async (userId) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', userId));
    if (userDoc.exists()) {
      return userDoc.data().badges || [];
    }
    return [];
  } catch (error) {
    console.error('Error fetching user badges:', error);
    throw error;
  }
};

export const updateUserBadges = async (userId, badges) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      badges: badges,
      lastBadgeUpdate: serverTimestamp(),
    });
  } catch (error) {
    console.error('Error updating user badges:', error);
    throw error;
  }
};

export const getBadgeDetails = async (badgeId) => {
  try {
    const badgeDoc = await getDoc(doc(db, 'badges', badgeId));
    if (badgeDoc.exists()) {
      return badgeDoc.data();
    }
    return null;
  } catch (error) {
    console.error('Error fetching badge details:', error);
    throw error;
  }
};

// Add a helper function to verify admin status
export const verifyAdminStatus = async () => {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.log('No authenticated user found');
      return false;
    }

    const userRef = doc(db, 'users', currentUser.uid);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      console.log('User document not found');
      return false;
    }

    const userData = userSnap.data();
    // Explicitly check for boolean true
    return userData.isAdmin === true;
  } catch (error) {
    console.error('Error in verifyAdminStatus:', error);
    return false;
  }
};

// Add this new helper function for checking user roles
export const getUserRole = async (userId) => {
  try {
    const currentUser = auth.currentUser;
    console.log('Current user:', currentUser?.uid);

    const userRef = doc(db, 'users', userId || currentUser?.uid);
    console.log('Checking role for user:', userId || currentUser?.uid);

    const userSnap = await getDoc(userRef);
    console.log('User document exists:', userSnap.exists());

    if (!userSnap.exists()) {
      console.log('No user document found');
      return null;
    }

    const userData = userSnap.data();
    console.log('User data:', userData);
    console.log('isAdmin:', userData.isAdmin);
    console.log('isModerator:', userData.isModerator);

    if (userData.isAdmin === true) {
      console.log('User is admin');
      return 'admin';
    }
    if (userData.isModerator === true) {
      console.log('User is moderator');
      return 'moderator';
    }
    console.log('User is regular user');
    return 'user';
  } catch (error) {
    console.error('Error in getUserRole:', error);
    return null;
  }
};

// Add these functions to your existing firestoreService.js

export const fetchFeedbacks = async () => {
  try {
    const feedbacksRef = collection(db, 'feedback');
    const q = query(feedbacksRef, orderBy('createdAt', 'desc'));
    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate() || null,
      noteAddedAt: doc.data().noteAddedAt?.toDate() || null,
    }));
  } catch (error) {
    console.error('Error in fetchFeedbacks:', error);
    throw error;
  }
};

export const updateFeedbackStatus = async (feedbackId, status) => {
  try {
    const feedbackRef = doc(db, 'feedback', feedbackId);
    await updateDoc(feedbackRef, {
      status,
      updatedAt: serverTimestamp(),
      updatedBy: auth.currentUser.uid,
    });
  } catch (error) {
    console.error('Error updating feedback status:', error);
    throw error;
  }
};

export const addFeedbackNote = async (feedbackId, note) => {
  try {
    const feedbackRef = doc(db, 'feedback', feedbackId);
    await updateDoc(feedbackRef, {
      adminNote: note,
      noteAddedAt: serverTimestamp(),
      noteAddedBy: auth.currentUser.uid,
    });
  } catch (error) {
    console.error('Error adding feedback note:', error);
    throw error;
  }
};

export const deleteFeedback = async (feedbackId) => {
  try {
    await deleteDoc(doc(db, 'feedback', feedbackId));
  } catch (error) {
    console.error('Error deleting feedback:', error);
    throw error;
  }
};

// Add this helper function to initialize admin user
export const initializeAdminUser = async (userId) => {
  try {
    const userRef = doc(db, 'users', userId);
    await setDoc(
      userRef,
      {
        isAdmin: true,
        isModerator: true,
        role: 'admin',
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      },
      { merge: true },
    );

    console.log('Admin user initialized successfully');
    return true;
  } catch (error) {
    console.error('Error initializing admin user:', error);
    throw error;
  }
};

// Simplified admin check that trusts AuthContext
const isAdminUser = async () => {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) return false;

    const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
    return userDoc.exists() && userDoc.data().isAdmin === true;
  } catch (error) {
    console.error('Error checking admin status:', error);
    return false;
  }
};

// Add this function to help debug admin status
export const debugAdminStatus = async () => {
  try {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.log('No current user');
      return;
    }

    const userRef = doc(db, 'users', currentUser.uid);
    const userDoc = await getDoc(userRef);
    console.log('User document:', userDoc.data());

    // If not admin, make admin for testing
    if (!userDoc.data()?.isAdmin) {
      console.log('Setting user as admin...');
      await updateDoc(userRef, {
        isAdmin: true,
        updatedAt: serverTimestamp(),
      });
      console.log('User is now admin');
    }
  } catch (error) {
    console.error('Error in debugAdminStatus:', error);
  }
};

export const makeUserAdmin = async (userId) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    isAdmin: true,
    isModerator: true,
  });
};

// Call it with your user ID
// makeUserAdmin('your-user-id');

export const fixAdminStatus = async (userId) => {
  const userRef = doc(db, 'users', userId);
  await updateDoc(userRef, {
    isAdmin: true, // This will be stored as boolean true
    isModerator: true,
  });
};

// Call it with your user ID
// fixAdminStatus('TrbAVKNIuTc4yCBRwfxIXlIxH0G2');

// Add this function to update the catalogs
export const updateCatalogs = async () => {
  const catalogsRef = doc(db, 'catalogs', 'all');

  const newTechnologies = {
    'Frontend Development': [
      'React.js',
      'Vue.js',
      'Angular',
      'Next.js',
      'TypeScript',
      'JavaScript',
      'HTML5',
      'CSS3',
      'Sass/SCSS',
      'Tailwind CSS',
      'Material UI',
      'Bootstrap',
      'Redux',
      'WebPack',
      'Vite',
    ],
    'Backend Development': [
      'Node.js',
      'Python',
      'Java',
      'PHP',
      'Ruby',
      'C#',
      'Go',
      'Express.js',
      'Django',
      'Laravel',
      'Spring Boot',
      'ASP.NET',
      'GraphQL',
      'REST API',
      'MongoDB',
      'PostgreSQL',
      'MySQL',
      'Redis',
    ],
    'Mobile Development': [
      'React Native',
      'Flutter',
      'iOS (Swift)',
      'Android (Kotlin)',
      'Android (Java)',
      'Xamarin',
      'Ionic',
      'PWA',
      'Mobile UI/UX',
      'App Performance',
      'Mobile Security',
    ],
    'Game Development': [
      'Unity',
      'Unreal Engine',
      'Godot',
      'C++',
      'Game Design',
      'WebGL',
      'Three.js',
      'Phaser.js',
      '2D Game Dev',
      '3D Game Dev',
      'Game Physics',
      'Game AI',
    ],
    'AI & Machine Learning': [
      'TensorFlow',
      'PyTorch',
      'Scikit-learn',
      'Computer Vision',
      'NLP',
      'Deep Learning',
      'Machine Learning',
      'Data Science',
      'Neural Networks',
      'AI Model Training',
      'OpenAI Integration',
      'Chatbot Development',
    ],
    'Cloud & DevOps Technologies': [
      'AWS',
      'Azure',
      'Google Cloud',
      'Docker',
      'Kubernetes',
      'CI/CD',
      'Jenkins',
      'Git',
      'GitHub Actions',
      'Terraform',
      'Ansible',
      'Linux',
      'Shell Scripting',
      'Monitoring',
      'Cloud Security',
    ],
  };

  try {
    // Get current catalogs
    const catalogsSnap = await getDoc(catalogsRef);
    const currentCatalogs = catalogsSnap.exists()
      ? catalogsSnap.data()
      : { technologies: {} };

    // Merge new technologies with existing ones
    const updatedTechnologies = {
      ...(currentCatalogs.technologies || {}),
      ...newTechnologies,
    };

    // Update Firestore
    await setDoc(
      catalogsRef,
      {
        technologies: updatedTechnologies,
      },
      { merge: true },
    );

    console.log('Catalogs updated successfully');
    return true;
  } catch (error) {
    console.error('Error updating catalogs:', error);
    return false;
  }
};

// Add these functions
export const getPublicProfiles = async () => {
  try {
    const profilesRef = collection(db, 'users');
    // Remove the orderBy to simplify the query first
    const q = query(profilesRef, where('publicProfile', '==', true));

    console.log('Fetching public profiles...'); // Debug log
    const snapshot = await getDocs(q);
    console.log('Found profiles:', snapshot.size); // Debug log

    const profiles = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    console.log('Processed profiles:', profiles.length); // Debug log
    return profiles;
  } catch (error) {
    console.error('Error fetching public profiles:', error);
    console.error('Error details:', error.message); // More detailed error
    throw error;
  }
};

export const updateProfileVisibility = async (userId, isPublic) => {
  try {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      publicProfile: isPublic,
      updatedAt: serverTimestamp(),
    });
    return true;
  } catch (error) {
    console.error('Error updating profile visibility:', error);
    throw error;
  }
};

export const getProfilesBySkill = async (skill) => {
  try {
    const profilesRef = collection(db, 'users');
    const q = query(
      profilesRef,
      where('publicProfile', '==', true),
      where('skills', 'array-contains', skill),
    );
    const snapshot = await getDocs(q);
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  } catch (error) {
    console.error('Error fetching profiles by skill:', error);
    throw error;
  }
};
