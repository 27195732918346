import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import {
  getAllPosts,
  addPost,
  updatePost,
  deletePost,
} from '../services/firestoreService';
import { fetchFacebookPost } from '../services/facebookService';
import { generatePostContent } from '../services/openaiService';

function PostManagement() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newPost, setNewPost] = useState({
    title: '',
    content: '',
    author: '',
    imageUrl: '',
    ctaUrl: '',
  });
  const [facebookUrl, setFacebookUrl] = useState('');
  const [editingPost, setEditingPost] = useState(null);
  const [aiTopic, setAiTopic] = useState('');
  const [aiSummary, setAiSummary] = useState('');

  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    if (editingPost) {
      setNewPost({ ...editingPost });
    } else {
      setNewPost({
        title: '',
        content: '',
        author: '',
        imageUrl: '',
        ctaUrl: '',
      });
    }
  }, [editingPost]);

  const fetchPosts = async () => {
    setLoading(true);
    setError(null);
    try {
      const fetchedPosts = await getAllPosts();
      setPosts(fetchedPosts);
    } catch (err) {
      console.error('Error fetching posts:', err);
      setError('Failed to fetch posts. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleAddPost = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await addPost(newPost);
      setNewPost({
        title: '',
        content: '',
        author: '',
        imageUrl: '',
        ctaUrl: '',
      });
      await fetchPosts();
    } catch (err) {
      console.error('Error adding post:', err);
      setError('Failed to add post. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePost = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await updatePost(editingPost.id, editingPost);
      setEditingPost(null);
      await fetchPosts();
    } catch (err) {
      console.error('Error updating post:', err);
      setError('Failed to update post. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeletePost = async (id) => {
    if (window.confirm('Are you sure you want to delete this post?')) {
      setLoading(true);
      setError(null);
      try {
        await deletePost(id);
        await fetchPosts();
      } catch (err) {
        console.error('Error deleting post:', err);
        setError('Failed to delete post. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleFetchFacebookPost = async () => {
    setLoading(true);
    setError(null);
    try {
      const fetchedPost = await fetchFacebookPost(facebookUrl);
      setNewPost({
        title: fetchedPost.title || '',
        content: fetchedPost.content || '',
        author: fetchedPost.author || '',
        imageUrl: fetchedPost.imageUrl || '',
        ctaUrl: fetchedPost.ctaUrl || '',
      });
      setFacebookUrl('');
    } catch (err) {
      console.error('Error fetching Facebook post:', err);
      setError(
        'Failed to fetch Facebook post. Please check the URL and try again.',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleGenerateContent = async () => {
    setLoading(true);
    setError(null);
    try {
      const generatedContent = await generatePostContent(aiTopic, aiSummary);
      setNewPost((prevPost) => ({
        ...prevPost,
        title: aiTopic,
        content: generatedContent,
      }));
    } catch (err) {
      console.error('Error generating content:', err);
      setError(
        'Failed to generate content. Please try again later or contact support if the problem persists.',
      );
    } finally {
      setLoading(false);
    }
  };

  const handleContentChange = (content) => {
    if (editingPost) {
      setEditingPost({ ...editingPost, content });
    } else {
      setNewPost({ ...newPost, content });
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'image',
  ];

  const getContentSnippet = (content, maxLength = 100) => {
    const strippedContent = content.replace(/<[^>]+>/g, '');
    return strippedContent.length > maxLength
      ? strippedContent.substring(0, maxLength) + '...'
      : strippedContent;
  };

  if (loading) return <div>Loading posts...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="post-management">
      <h2 className="section-title">Post Management</h2>
      <div className="post-management-layout">
        <div className="post-form-section">
          <h3>{editingPost ? 'Edit Post' : 'Create New Post'}</h3>
          <form onSubmit={editingPost ? handleUpdatePost : handleAddPost}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="post-title">Title</label>
                <input
                  id="post-title"
                  type="text"
                  placeholder="Post Title"
                  value={editingPost ? editingPost.title : newPost.title}
                  onChange={(e) =>
                    editingPost
                      ? setEditingPost({
                          ...editingPost,
                          title: e.target.value,
                        })
                      : setNewPost({ ...newPost, title: e.target.value })
                  }
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="post-author">Author</label>
                <input
                  id="post-author"
                  type="text"
                  placeholder="Author"
                  value={editingPost ? editingPost.author : newPost.author}
                  onChange={(e) =>
                    editingPost
                      ? setEditingPost({
                          ...editingPost,
                          author: e.target.value,
                        })
                      : setNewPost({ ...newPost, author: e.target.value })
                  }
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <label htmlFor="post-content">Content</label>
              <ReactQuill
                id="post-content"
                theme="snow"
                value={editingPost ? editingPost.content : newPost.content}
                onChange={handleContentChange}
                modules={modules}
                formats={formats}
                placeholder="Post Content"
              />
            </div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="post-image">Image URL</label>
                <input
                  id="post-image"
                  type="text"
                  placeholder="Image URL"
                  value={editingPost ? editingPost.imageUrl : newPost.imageUrl}
                  onChange={(e) =>
                    editingPost
                      ? setEditingPost({
                          ...editingPost,
                          imageUrl: e.target.value,
                        })
                      : setNewPost({ ...newPost, imageUrl: e.target.value })
                  }
                />
              </div>
              <div className="form-group">
                <label htmlFor="post-cta">CTA Button URL</label>
                <input
                  id="post-cta"
                  type="text"
                  placeholder="CTA Button URL"
                  value={editingPost ? editingPost.ctaUrl : newPost.ctaUrl}
                  onChange={(e) =>
                    editingPost
                      ? setEditingPost({
                          ...editingPost,
                          ctaUrl: e.target.value,
                        })
                      : setNewPost({ ...newPost, ctaUrl: e.target.value })
                  }
                />
              </div>
            </div>
            {(editingPost?.imageUrl || newPost.imageUrl) && (
              <img
                src={editingPost ? editingPost.imageUrl : newPost.imageUrl}
                alt="Post preview"
                className="image-preview"
              />
            )}
            <div className="form-actions">
              <button type="submit" className="btn" disabled={loading}>
                {editingPost ? 'Update Post' : 'Add Post'}
              </button>
              {editingPost && (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setEditingPost(null)}
                  disabled={loading}
                >
                  Cancel Edit
                </button>
              )}
            </div>
          </form>
        </div>
        <div className="post-tools-section">
          <div className="tool-card">
            <h4>Import from Facebook</h4>
            <input
              type="text"
              placeholder="Facebook Post URL"
              value={facebookUrl}
              onChange={(e) => setFacebookUrl(e.target.value)}
            />
            <button
              className="btn"
              onClick={handleFetchFacebookPost}
              disabled={loading || !facebookUrl}
            >
              Fetch Facebook Post
            </button>
          </div>
          <div className="tool-card">
            <h4>Generate AI Content</h4>
            <input
              type="text"
              placeholder="Topic"
              value={aiTopic}
              onChange={(e) => setAiTopic(e.target.value)}
            />
            <textarea
              placeholder="Summary"
              value={aiSummary}
              onChange={(e) => setAiSummary(e.target.value)}
            ></textarea>
            <button
              className="btn"
              onClick={handleGenerateContent}
              disabled={loading || !aiTopic || !aiSummary}
            >
              Generate Content
            </button>
          </div>
        </div>
      </div>
      <div className="posts-table-section">
        <h3>Existing Posts</h3>
        <div className="table-responsive">
          <table className="posts-table">
            <thead>
              <tr>
                <th>Title</th>
                <th>Author</th>
                <th>Content</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {posts.map((post) => (
                <tr key={post.id}>
                  <td>{post.title}</td>
                  <td>{post.author}</td>
                  <td>{getContentSnippet(post.content)}</td>
                  <td>
                    <button
                      className="btn btn-sm"
                      onClick={() => setEditingPost({ ...post })}
                      disabled={loading}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => handleDeletePost(post.id)}
                      disabled={loading}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default PostManagement;
