import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { db } from '../firebase';
import { collection, query, where, getDocs, orderBy } from 'firebase/firestore';
import {
  FaSearch,
  FaFilter,
  FaStar,
  FaHeart,
  FaLock,
  FaEnvelope,
  FaPhone,
  FaWhatsapp,
  FaMapMarkerAlt,
  FaBriefcase,
  FaUsers,
  FaTrophy,
  FaSort,
  FaFilter as FaFilterSolid,
  FaImage,
  FaTimes,
  FaChevronDown,
  FaCheck,
  FaUser,
  FaArrowRight,
} from 'react-icons/fa';
import '../styles/skillExploration.css';
import { getPublicProfiles, getCatalogs } from '../services/firestoreService';
import SkillExplorationSEO from './SkillExplorationSEO';
import { Helmet } from 'react-helmet';

const SESSION_STORAGE_KEYS = {
  PROFILES: 'cached_profiles',
  CATALOGS: 'cached_catalogs',
  TIMESTAMP: 'cache_timestamp',
  CACHE_DURATION: 1000 * 60 * 30, // 30 minutes
};

const getTopSkills = (profile, count = 4) => {
  if (!profile.skills) return [];

  // Convert skills object to array of skill objects with levels
  const skillsArray = Object.entries(profile.skills).reduce(
    (acc, [category, skills]) => {
      const categorySkills = skills.map((skill) => ({
        name: skill,
        level: profile.proficiencyLevels?.[skill] || 1,
        category,
      }));
      return [...acc, ...categorySkills];
    },
    [],
  );

  // Sort by proficiency level and return top N skills
  return skillsArray.sort((a, b) => b.level - a.level).slice(0, count);
};

function SkillExploration() {
  const { currentUser } = useAuth();
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [sortBy, setSortBy] = useState('rating');
  const [showSignInPrompt, setShowSignInPrompt] = useState(false);
  const [visibleProfiles, setVisibleProfiles] = useState(6);
  const [contactOptions, setContactOptions] = useState(null);
  const [showContactModal, setShowContactModal] = useState(false);
  const [error, setError] = useState(null);
  const [showFilters, setShowFilters] = useState(false);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [experienceLevel, setExperienceLevel] = useState('all');
  const [availabilityFilter, setAvailabilityFilter] = useState('all');
  const [rateRange, setRateRange] = useState({ min: 0, max: 1000 });
  const [selectedMainSkill, setSelectedMainSkill] = useState('all');
  const [selectedSubSkills, setSelectedSubSkills] = useState([]);
  const [catalogs, setCatalogs] = useState(null);
  const [showSubSkills, setShowSubSkills] = useState(false);

  const categories = [
    'All Skills',
    'Development',
    'Design',
    'Marketing',
    'Content',
    'Business',
  ];

  const experienceLevels = ['Beginner', 'Intermediate', 'Expert'];
  const availabilityOptions = ['Available Now', 'Part-time', 'Full-time'];

  const getSkillCategories = () => {
    const skillCategories = {
      all: 'All Skills',
      ...catalogs?.skills,
    };
    return skillCategories;
  };

  const filterProfiles = useCallback(() => {
    return profiles
      .filter((profile) => {
        const matchesSearch =
          searchTerm === '' ||
          profile.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          Object.values(profile.skills || {})
            .flat()
            .some((skill) =>
              skill.toLowerCase().includes(searchTerm.toLowerCase()),
            );

        const matchesMainSkill =
          selectedMainSkill === 'all' ||
          (profile.skills && profile.skills[selectedMainSkill]?.length > 0);

        const matchesSubSkills =
          selectedSubSkills.length === 0 ||
          selectedSubSkills.every((subSkill) =>
            Object.values(profile.skills || {})
              .flat()
              .includes(subSkill),
          );

        const matchesRate =
          profile.hourlyRate >= rateRange.min &&
          profile.hourlyRate <= rateRange.max;

        const matchesAvailability =
          availabilityFilter === 'all' ||
          profile.availabilityStatus === availabilityFilter;

        const matchesExperience =
          experienceLevel === 'all' ||
          (profile.yearsOfExperience &&
            experienceLevel === 'beginner' &&
            profile.yearsOfExperience <= 2) ||
          (experienceLevel === 'intermediate' &&
            profile.yearsOfExperience > 2 &&
            profile.yearsOfExperience <= 5) ||
          (experienceLevel === 'expert' && profile.yearsOfExperience > 5);

        return (
          matchesSearch &&
          matchesMainSkill &&
          matchesSubSkills &&
          matchesRate &&
          matchesAvailability &&
          matchesExperience
        );
      })
      .sort((a, b) => {
        switch (sortBy) {
          case 'rating':
            return (b.rating || 0) - (a.rating || 0);
          case 'experience':
            return (b.yearsOfExperience || 0) - (a.yearsOfExperience || 0);
          case 'rate':
            return (a.hourlyRate || 0) - (b.hourlyRate || 0);
          default:
            return 0;
        }
      });
  }, [
    profiles,
    searchTerm,
    selectedMainSkill,
    selectedSubSkills,
    rateRange,
    availabilityFilter,
    experienceLevel,
    sortBy,
  ]);

  const resetFilters = () => {
    setSelectedCategory('all');
    setSelectedSkills([]);
    setExperienceLevel('all');
    setAvailabilityFilter('all');
    setRateRange({ min: 0, max: 1000 });
    setSortBy('rating');
  };

  useEffect(() => {
    fetchProfiles();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const fetchProfiles = async () => {
    try {
      setLoading(true);
      setError(null);

      // Check cache first
      const cachedData = sessionStorage.getItem(SESSION_STORAGE_KEYS.PROFILES);
      const timestamp = sessionStorage.getItem(SESSION_STORAGE_KEYS.TIMESTAMP);
      const now = Date.now();

      if (
        cachedData &&
        timestamp &&
        now - parseInt(timestamp) < SESSION_STORAGE_KEYS.CACHE_DURATION
      ) {
        setProfiles(JSON.parse(cachedData));
        setLoading(false);
        return;
      }

      const fetchedProfiles = await getPublicProfiles();
      setProfiles(fetchedProfiles);

      // Cache the results
      sessionStorage.setItem(
        SESSION_STORAGE_KEYS.PROFILES,
        JSON.stringify(fetchedProfiles),
      );
      sessionStorage.setItem(SESSION_STORAGE_KEYS.TIMESTAMP, now.toString());
    } catch (error) {
      setError('Failed to load profiles. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = () => {
    if (!currentUser && window.scrollY > 300) {
      setShowSignInPrompt(true);
    }
  };

  const handleLoadMore = () => {
    if (!currentUser) {
      setShowSignInPrompt(true);
      return;
    }
    setVisibleProfiles((prev) => prev + 6);
  };

  const getSkillsArray = (profile) => {
    if (!profile.skills) return [];
    return Object.values(profile.skills).flat().filter(Boolean);
  };

  const handleQuickContact = (profile) => {
    if (!profile) return;

    const contactOptions = [];
    if (profile.showEmail && profile.email) {
      contactOptions.push({
        type: 'email',
        value: profile.email,
        action: () => (window.location.href = `mailto:${profile.email}`),
      });
    }
    if (profile.showPhone && profile.phone) {
      contactOptions.push({
        type: 'phone',
        value: profile.phone,
        action: () => (window.location.href = `tel:${profile.phone}`),
      });
    }
    if (profile.showWhatsapp && profile.phone) {
      contactOptions.push({
        type: 'whatsapp',
        value: profile.phone,
        action: () =>
          (window.location.href = `https://wa.me/${profile.phone.replace(
            /[^0-9]/g,
            '',
          )}`),
      });
    }

    if (contactOptions.length === 1) {
      contactOptions[0].action();
      return;
    }

    if (contactOptions.length > 1) {
      setContactOptions({ profile, options: contactOptions });
      setShowContactModal(true);
    }
  };

  const renderContactModal = () => {
    if (!contactOptions) return null;

    return (
      <div className="modal-overlay" onClick={() => setShowContactModal(false)}>
        <div className="contact-modal" onClick={(e) => e.stopPropagation()}>
          <h3>Contact {contactOptions.profile.name}</h3>
          <div className="contact-options">
            {contactOptions.options.map((option, index) => (
              <button
                key={index}
                className="contact-option-btn"
                onClick={() => {
                  option.action();
                  setShowContactModal(false);
                }}
              >
                {option.type === 'email' && <FaEnvelope />}
                {option.type === 'phone' && <FaPhone />}
                {option.type === 'whatsapp' && <FaWhatsapp />}
                <span>
                  {option.type.charAt(0).toUpperCase() + option.type.slice(1)}
                </span>
              </button>
            ))}
          </div>
          <button
            className="close-btn"
            onClick={() => setShowContactModal(false)}
          >
            Close
          </button>
        </div>
      </div>
    );
  };

  const renderFilterPanel = () => (
    <div className={`filter-panel ${showFilters ? 'show' : ''}`}>
      <div className="filter-panel-content">
        <div className="filter-header">
          <h3>Advanced Filters</h3>
          <button
            className="close-filters"
            onClick={() => setShowFilters(false)}
          >
            <FaTimes />
          </button>
        </div>

        <div className="filter-sections">
          <div className="filter-section">
            <h4>Skill Category</h4>
            <select
              value={selectedMainSkill}
              onChange={(e) => {
                setSelectedMainSkill(e.target.value);
                setShowSubSkills(e.target.value !== 'all');
                setSelectedSubSkills([]);
              }}
              className="filter-select"
            >
              <option value="all">All Categories</option>
              {Object.keys(getSkillCategories()).map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>

          {selectedMainSkill !== 'all' && (
            <div className="filter-section">
              <h4>Specific Skills</h4>
              <div className="filter-options">
                {catalogs?.skills[selectedMainSkill]?.map((skill) => (
                  <label key={skill} className="filter-option">
                    <input
                      type="checkbox"
                      checked={selectedSubSkills.includes(skill)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedSubSkills([...selectedSubSkills, skill]);
                        } else {
                          setSelectedSubSkills(
                            selectedSubSkills.filter((s) => s !== skill),
                          );
                        }
                      }}
                    />
                    <span>{skill}</span>
                  </label>
                ))}
              </div>
            </div>
          )}

          <div className="filter-section">
            <h4>Experience Level</h4>
            <div className="filter-options">
              {['all', 'beginner', 'intermediate', 'expert'].map((level) => (
                <label key={level} className="filter-option">
                  <input
                    type="radio"
                    name="experience"
                    checked={experienceLevel === level}
                    onChange={() => setExperienceLevel(level)}
                  />
                  <span>{level.charAt(0).toUpperCase() + level.slice(1)}</span>
                </label>
              ))}
            </div>
          </div>

          <div className="filter-section">
            <h4>Availability</h4>
            <div className="filter-options">
              {['all', 'available', 'part-time', 'full-time'].map((status) => (
                <label key={status} className="filter-option">
                  <input
                    type="radio"
                    name="availability"
                    checked={availabilityFilter === status}
                    onChange={() => setAvailabilityFilter(status)}
                  />
                  <span>
                    {status === 'all'
                      ? 'All'
                      : status.charAt(0).toUpperCase() + status.slice(1)}
                  </span>
                </label>
              ))}
            </div>
          </div>

          <div className="filter-section">
            <h4>Hourly Rate Range ($)</h4>
            <div className="rate-range">
              <input
                type="number"
                min="0"
                max={rateRange.max}
                value={rateRange.min}
                onChange={(e) =>
                  setRateRange({ ...rateRange, min: Number(e.target.value) })
                }
                placeholder="Min"
              />
              <span>to</span>
              <input
                type="number"
                min={rateRange.min}
                value={rateRange.max}
                onChange={(e) =>
                  setRateRange({ ...rateRange, max: Number(e.target.value) })
                }
                placeholder="Max"
              />
            </div>
          </div>
        </div>

        <div className="filter-actions">
          <button
            onClick={() => {
              setSelectedMainSkill('all');
              setSelectedSubSkills([]);
              setRateRange({ min: 0, max: 1000 });
              setAvailabilityFilter('all');
              setExperienceLevel('all');
            }}
            className="reset-filters"
          >
            Reset Filters
          </button>
          <button
            onClick={() => setShowFilters(false)}
            className="apply-filters"
          >
            Apply Filters
          </button>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    // Remove or comment out all console.log statements
  }, [profiles]);

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  useEffect(() => {
    const fetchCatalogs = async () => {
      try {
        // Check cache first
        const cachedCatalogs = sessionStorage.getItem(
          SESSION_STORAGE_KEYS.CATALOGS,
        );
        if (cachedCatalogs) {
          setCatalogs(JSON.parse(cachedCatalogs));
          return;
        }

        const fetchedCatalogs = await getCatalogs();
        if (fetchedCatalogs) {
          setCatalogs(fetchedCatalogs);
          // Cache the results
          sessionStorage.setItem(
            SESSION_STORAGE_KEYS.CATALOGS,
            JSON.stringify(fetchedCatalogs),
          );
        }
      } catch (err) {
        // Silent error handling for catalogs
      }
    };

    fetchCatalogs();
  }, []);

  const clearAllFilters = () => {
    setSearchTerm('');
    setSelectedMainSkill('all');
    setSelectedSubSkills([]);
    setExperienceLevel('all');
    setAvailabilityFilter('all');
    setRateRange({ min: 0, max: 1000 });
  };

  // Add SEO data using useMemo to optimize performance
  const seoData = useMemo(() => {
    const totalProfiles = filterProfiles().length;
    const availableSkills = catalogs?.skills
      ? Object.keys(catalogs.skills).length
      : 0;

    return {
      title: `Explore ${totalProfiles}+ Professional Skills & Expert Freelancers | Online Entrepreneurs Club`,
      description: `Connect with verified professionals across ${availableSkills} skill categories including development, design, marketing, and more. Find the perfect match for your projects or showcase your expertise.`,
      keywords: [
        ...Object.keys(catalogs?.skills || {}),
        'freelancers',
        'professional skills',
        'expert developers',
        'designers',
        'marketers',
        'remote work',
        'hire freelancers',
        'freelance projects',
        'professional network',
        'skill marketplace',
      ].join(', '),
      url: `${process.env.REACT_APP_BASE_URL}/explore-skills`,
      image: `${process.env.REACT_APP_BASE_URL}/images/skills.png`,
    };
  }, [catalogs, filterProfiles]);

  return (
    <div className={`skill-exploration ${!currentUser ? 'public-view' : ''}`}>
      {/* Add dynamic SEO tags */}
      <Helmet>
        {/* Primary Meta Tags */}
        <title>{seoData.title}</title>
        <meta name="title" content={seoData.title} />
        <meta name="description" content={seoData.description} />
        <meta name="keywords" content={seoData.keywords} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={seoData.url} />
        <meta property="og:title" content={seoData.title} />
        <meta property="og:description" content={seoData.description} />
        <meta property="og:image" content={seoData.image} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:url" content={seoData.url} />
        <meta name="twitter:title" content={seoData.title} />
        <meta name="twitter:description" content={seoData.description} />
        <meta name="twitter:image" content={seoData.image} />

        {/* Additional Meta Tags */}
        <meta name="robots" content="index, follow" />
        <meta name="language" content="English" />
        <link rel="canonical" href={seoData.url} />

        {/* Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            name: 'Explore Professional Skills',
            description: seoData.description,
            provider: {
              '@type': 'Organization',
              name: 'Online Entrepreneurs Club',
              url: process.env.REACT_APP_BASE_URL,
            },
            offers: {
              '@type': 'AggregateOffer',
              description: `Professional freelance services across ${Object.keys(
                catalogs?.skills || {},
              ).join(', ')}`,
              offerCount: filterProfiles().length,
            },
            audience: {
              '@type': 'Audience',
              audienceType: [
                'Businesses',
                'Entrepreneurs',
                'Freelancers',
                'Professional Service Providers',
              ],
            },
          })}
        </script>
      </Helmet>

      <div className="skill-exploration-header">
        <h1>Explore Skills</h1>
        {currentUser && (
          <div className="search-filters">
            <div className="main-filters-row">
              <div className="search-bar">
                <FaSearch className="search-icon" />
                <input
                  type="text"
                  placeholder="Search by skill or member name..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {searchTerm && (
                  <button
                    className="clear-search-btn"
                    onClick={() => setSearchTerm('')}
                  >
                    <FaTimes />
                  </button>
                )}
              </div>

              <div className="filter-row">
                <div className="main-skill-select">
                  <select
                    value={selectedMainSkill}
                    onChange={(e) => {
                      setSelectedMainSkill(e.target.value);
                      setSelectedSubSkills([]);
                    }}
                  >
                    <option value="all">Select Skill Category</option>
                    {Object.keys(getSkillCategories())
                      .filter((cat) => cat !== 'all')
                      .map((category) => (
                        <option key={category} value={category}>
                          {category}
                        </option>
                      ))}
                  </select>
                </div>

                <div className="quick-filters">
                  <select
                    value={availabilityFilter}
                    onChange={(e) => setAvailabilityFilter(e.target.value)}
                    className="quick-filter-select"
                  >
                    <option value="all">Any Availability</option>
                    <option value="available">Available Now</option>
                    <option value="part-time">Part-time</option>
                    <option value="full-time">Full-time</option>
                  </select>

                  <select
                    value={experienceLevel}
                    onChange={(e) => setExperienceLevel(e.target.value)}
                    className="quick-filter-select"
                  >
                    <option value="all">Any Experience</option>
                    <option value="2">0-2 years</option>
                    <option value="5">2-5 years</option>
                    <option value="10">5-10 years</option>
                    <option value="10+">10+ years</option>
                  </select>

                  <button
                    className={`filter-toggle-btn ${
                      showFilters ? 'active' : ''
                    }`}
                    onClick={toggleFilters}
                  >
                    <FaFilterSolid /> More Filters
                  </button>
                </div>
              </div>
            </div>

            {selectedMainSkill !== 'all' && (
              <div className="sub-skills-section">
                <div className="sub-skills-container">
                  <div className="sub-skills-wrapper">
                    {catalogs?.skills[selectedMainSkill]?.map((skill) => (
                      <label
                        key={skill}
                        className={`sub-skill-chip ${
                          selectedSubSkills.includes(skill) ? 'selected' : ''
                        }`}
                      >
                        <input
                          type="checkbox"
                          checked={selectedSubSkills.includes(skill)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedSubSkills([
                                ...selectedSubSkills,
                                skill,
                              ]);
                            } else {
                              setSelectedSubSkills(
                                selectedSubSkills.filter((s) => s !== skill),
                              );
                            }
                          }}
                        />
                        <span>{skill}</span>
                      </label>
                    ))}
                  </div>
                  {(searchTerm ||
                    selectedMainSkill !== 'all' ||
                    selectedSubSkills.length > 0 ||
                    experienceLevel !== 'all' ||
                    availabilityFilter !== 'all') && (
                    <button
                      className="clear-all-filters"
                      onClick={clearAllFilters}
                    >
                      <FaTimes /> Clear All Filters
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <div
        className={`filter-overlay ${showFilters ? 'show' : ''}`}
        onClick={() => setShowFilters(false)}
      />

      {loading && (
        <div className="loading-state">
          <div className="loading-spinner"></div>
          <p>Loading profiles...</p>
        </div>
      )}

      {error && (
        <div className="error-message">
          <p>{error}</p>
          <button onClick={fetchProfiles}>Try Again</button>
        </div>
      )}

      {!loading && !error && profiles.length === 0 && (
        <div className="no-profiles-message">
          <p>No public profiles found.</p>
        </div>
      )}

      {!loading && !error && profiles.length > 0 && (
        <>
          <div className="profiles-grid">
            {filterProfiles()
              .slice(0, currentUser ? visibleProfiles : 10)
              .map((profile) => (
                <div key={profile.id} className="skill-card">
                  <div className="skill-card-header">
                    <div className="skill-avatar-wrapper">
                      <img
                        src={profile.profilePictureUrl || '/default-avatar.png'}
                        alt={profile.name}
                        className="skill-avatar"
                      />
                    </div>
                    <div className="skill-info">
                      <div className="skill-name-wrapper">
                        <h3>
                          {profile.fullName || profile.name || 'Anonymous'}
                        </h3>
                        {profile.tagline && (
                          <p className="skill-tagline">{profile.tagline}</p>
                        )}
                      </div>
                      <div className="skill-meta">
                        <div className="location-info">
                          <FaMapMarkerAlt /> {profile.location || 'Global'}
                        </div>
                        <div
                          className={`availability-badge ${
                            profile.availabilityStatus || 'offline'
                          }`}
                        >
                          {profile.availabilityStatus === 'available'
                            ? 'Available'
                            : 'Unavailable'}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="skill-card-body">
                    <div className="expertise-tags">
                      {getTopSkills(profile, 6).map((skill, index) => (
                        <span key={index} className="expertise-tag">
                          {skill.name}
                        </span>
                      ))}
                      {getSkillsArray(profile).length > 6 && (
                        <span className="more-skills-tag">
                          +{getSkillsArray(profile).length - 6}
                        </span>
                      )}
                    </div>

                    <div className="skill-highlights-row">
                      <div className="highlight-item">
                        <FaStar className="highlight-icon" />
                        <span className="highlight-value">
                          {profile.rating?.toFixed(1) || '0.0'}
                        </span>
                        <span className="highlight-label">Rating</span>
                      </div>
                      <div className="highlight-item">
                        <FaImage className="highlight-icon" />
                        <span className="highlight-value">
                          {profile.portfolioItems?.length || 0}
                        </span>
                        <span className="highlight-label">Portfolio</span>
                      </div>
                      <div className="highlight-item">
                        <FaHeart className="highlight-icon" />
                        <span className="highlight-value">
                          {profile.loves || 0}
                        </span>
                        <span className="highlight-label">Loves</span>
                      </div>
                      {profile.hourlyRate && (
                        <div className="highlight-item rate">
                          <span className="highlight-value">
                            ${profile.hourlyRate}
                          </span>
                          <span className="highlight-label">/hour</span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="skill-card-footer">
                    {profile.yearsOfExperience && (
                      <div className="experience-tag">
                        <FaBriefcase className="experience-icon" />
                        <span>{profile.yearsOfExperience}+ years</span>
                      </div>
                    )}
                    <div className="card-actions">
                      <Link
                        to={`/profile/${profile.id}`}
                        className="view-profile-btn"
                      >
                        View Profile
                      </Link>
                      <button
                        className="quick-contact-btn"
                        onClick={() => handleQuickContact(profile)}
                        title="Quick Contact"
                      >
                        <FaEnvelope />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {!currentUser && (
            <div className="sign-in-prompt-container">
              <div className="blur-overlay"></div>
              <div className="sign-in-prompt">
                <div className="prompt-content">
                  <FaLock className="lock-icon" />
                  <h3>Connect with the Right Talent & Opportunities</h3>
                  <p>
                    Join our thriving community of professionals and businesses
                    to unlock the full potential of skill-based collaboration.
                  </p>

                  <div className="audience-benefits">
                    <div className="benefit-group">
                      <h4>
                        <FaBriefcase /> For Businesses
                      </h4>
                      <ul className="benefit-list">
                        <li>
                          <FaCheck /> Access {filterProfiles().length - 10}+
                          verified professional profiles
                        </li>
                        <li>
                          <FaCheck /> Advanced filtering & matching system
                        </li>
                        <li>
                          <FaCheck /> Direct messaging with professionals
                        </li>
                        <li>
                          <FaCheck /> Verified reviews and portfolios
                        </li>
                      </ul>
                    </div>

                    <div className="benefit-group">
                      <h4>
                        <FaUser /> For Professionals
                      </h4>
                      <ul className="benefit-list">
                        <li>
                          <FaCheck /> Showcase your skills to potential clients
                        </li>
                        <li>
                          <FaCheck /> Get discovered by relevant businesses
                        </li>
                        <li>
                          <FaCheck /> Build your professional network
                        </li>
                        <li>
                          <FaCheck /> Manage your availability & preferences
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="prompt-actions">
                    <Link to="/login" className="sign-in-btn primary">
                      Sign In <FaArrowRight />
                    </Link>
                    <Link to="/register" className="sign-in-btn secondary">
                      Create Free Account
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}

          {currentUser && visibleProfiles < filterProfiles().length && (
            <button className="load-more-btn show" onClick={handleLoadMore}>
              <span>Load More</span>
              <FaChevronDown className="load-more-icon" />
            </button>
          )}
        </>
      )}

      {showContactModal && renderContactModal()}

      {renderFilterPanel()}
    </div>
  );
}

export default SkillExploration;
