import React, { useState, useEffect } from 'react';
import {
  getPageContent,
  updatePageContent,
} from '../services/firestoreService';

function PageContentManagement() {
  const [pageContent, setPageContent] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingSection, setEditingSection] = useState(null);

  useEffect(() => {
    fetchPageContent();
  }, []);

  const fetchPageContent = async () => {
    setLoading(true);
    setError(null);
    try {
      const content = await getPageContent();
      setPageContent(content || { home: '', about: '', contact: '' });
    } catch (err) {
      console.error('Error fetching page content:', err);
      setError('Failed to fetch page content. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateContent = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      await updatePageContent(pageContent);
      setEditingSection(null);
    } catch (err) {
      console.error('Error updating page content:', err);
      setError('Failed to update page content. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading page content...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="page-content-management">
      <h2 className="section-title">Page Content Management</h2>
      {Object.entries(pageContent).map(([section, content]) => (
        <div key={section} className="content-section">
          <h3>{section.charAt(0).toUpperCase() + section.slice(1)} Page</h3>
          {editingSection === section ? (
            <form onSubmit={handleUpdateContent}>
              <textarea
                value={content}
                onChange={(e) =>
                  setPageContent({ ...pageContent, [section]: e.target.value })
                }
                rows="10"
                cols="50"
              ></textarea>
              <div className="button-group">
                <button type="submit" className="btn" disabled={loading}>
                  Save Changes
                </button>
                <button
                  onClick={() => setEditingSection(null)}
                  className="btn btn-secondary"
                  disabled={loading}
                >
                  Cancel
                </button>
              </div>
            </form>
          ) : (
            <>
              <p>{content}</p>
              <button
                onClick={() => setEditingSection(section)}
                className="btn"
                disabled={loading}
              >
                Edit
              </button>
            </>
          )}
        </div>
      ))}
    </div>
  );
}

export default PageContentManagement;
