import React, { useEffect, useRef, useState } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import PropTypes from 'prop-types';
import '../styles/leaflet.css';
import { countries } from 'countries-list';
import '../styles/WorldMap.css';

const WORLD_GEOJSON_URL =
  'https://raw.githubusercontent.com/datasets/geo-countries/master/data/countries.geojson';

const MapController = ({ isInteractive }) => {
  const map = useMap();

  useEffect(() => {
    if (!map) return;

    const initMap = () => {
      // Set optimal view settings
      map.setView([30, 0], 2, { animate: false });
      map.setMinZoom(2);
      map.setMaxZoom(2);

      // Configure interactions based on page type
      if (!isInteractive) {
        map.dragging.disable();
        map.touchZoom.disable();
        map.doubleClickZoom.disable();
        map.scrollWheelZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        if (map.tap) map.tap.disable();
      }

      // Set map bounds
      const bounds = L.latLngBounds(L.latLng(-60, -180), L.latLng(85, 180));
      map.setMaxBounds(bounds);

      // Force resize for proper rendering
      setTimeout(() => {
        map.invalidateSize();
      }, 250);
    };

    // Wait for map to be ready
    if (map._container) {
      initMap();
    } else {
      map.once('load', initMap);
    }

    return () => {
      // Cleanup
      map.off('load');
      map.stopLocate();
    };
  }, [map, isInteractive]);

  return null;
};

MapController.propTypes = {
  isInteractive: PropTypes.bool,
};

MapController.defaultProps = {
  isInteractive: true,
};

const WorldMap = ({
  selectedCountries = [],
  onCountryClick = () => {},
  isInteractive = true,
}) => {
  const [geoJsonData, setGeoJsonData] = useState(null);
  const [hoveredCountry, setHoveredCountry] = useState(null);
  const mapRef = useRef(null);
  const geoJsonRef = useRef();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(WORLD_GEOJSON_URL);
        const data = await response.json();
        setGeoJsonData(data);
      } catch (error) {
        console.error('Error loading world map data:', error);
      }
    };
    fetchData();
  }, []);

  const getCountryStyle = (feature) => {
    const countryCode = feature.properties.ISO_A2;
    const isSelected = selectedCountries?.includes(countryCode);
    const isHovered = hoveredCountry === countryCode;

    return {
      fillColor: isSelected ? '#00f0ff' : '#1a1f2e',
      fillOpacity: isHovered ? 0.5 : isSelected ? 0.25 : 0.15,
      color: isSelected ? '#00f0ff' : '#304050',
      weight: isSelected ? 1.5 : 0.5,
      opacity: isSelected ? 0.8 : 0.5,
      className: `country-path ${isSelected ? 'selected-country' : ''} ${
        isHovered ? 'hovered-country' : ''
      }`,
    };
  };

  const onEachFeature = (feature, layer) => {
    if (!feature.properties) return;

    const countryCode = feature.properties.ISO_A2;
    const countryName = countries[countryCode]?.name || feature.properties.NAME;

    if (isInteractive) {
      layer.on({
        mouseover: (e) => {
          setHoveredCountry(countryCode);
          const layer = e.target;
          layer.setStyle({
            fillOpacity: 0.5,
            fillColor: selectedCountries?.includes(countryCode)
              ? '#00f0ff'
              : '#4a5568',
          });
        },
        mouseout: (e) => {
          setHoveredCountry(null);
          const layer = e.target;
          layer.setStyle(getCountryStyle(feature));
        },
        click: () => {
          onCountryClick(countryCode);
        },
      });
    }

    if (selectedCountries?.includes(countryCode)) {
      layer.bindTooltip(countryName, {
        permanent: true,
        direction: 'center',
        className: 'country-tooltip selected',
      });
    }
  };

  if (!geoJsonData) {
    return <div className="map-loading">Loading map...</div>;
  }

  return (
    <div className="world-map-container">
      <MapContainer
        ref={mapRef}
        key="map-container"
        center={[30, 0]}
        zoom={2}
        style={{
          height: '450px',
          width: '100%',
          background: '#0a0e17',
        }}
        zoomControl={false}
        attributionControl={false}
        worldCopyJump={true}
        maxBoundsViscosity={1}
        minZoom={2}
        maxZoom={2}
      >
        <MapController isInteractive={isInteractive} />
        <TileLayer
          url="https://cartodb-basemaps-{s}.global.ssl.fastly.net/dark_nolabels/{z}/{x}/{y}.png"
          noWrap={true}
        />
        <GeoJSON
          ref={geoJsonRef}
          key={JSON.stringify(selectedCountries)} // Force re-render when selection changes
          data={geoJsonData}
          style={getCountryStyle}
          onEachFeature={onEachFeature}
        />
      </MapContainer>
      {isInteractive && (
        <div className="map-controls">
          <div className="map-info">
            <span>Click on countries to select/deselect</span>
            <span>{selectedCountries.length} countries selected</span>
          </div>
        </div>
      )}
    </div>
  );
};

WorldMap.propTypes = {
  selectedCountries: PropTypes.arrayOf(PropTypes.string),
  onCountryClick: PropTypes.func,
  isInteractive: PropTypes.bool,
};

WorldMap.defaultProps = {
  selectedCountries: [],
  onCountryClick: () => {},
  isInteractive: true,
};

export default WorldMap;
