export const calculateProfileCompletion = (userProfile) => {
  if (!userProfile) return 0;

  const requiredFields = {
    // Personal Details
    fullName: { weight: 8, type: 'string' },
    bio: { weight: 8, type: 'string' },
    profilePictureUrl: { weight: 4, type: 'string' },
    tagline: { weight: 4, type: 'string' },
    location: { weight: 4, type: 'string' },
    yearsOfExperience: { weight: 4, type: 'string' },

    // Contact Details
    email: { weight: 6, type: 'string' },
    phone: { weight: 6, type: 'string' },
    socialLinks: { weight: 6, type: 'object' },

    // Skills & Expertise
    skills: { weight: 8, type: 'object' },
    proficiencyLevels: { weight: 8, type: 'object' },
    technologies: { weight: 6, type: 'object' },
    platforms: { weight: 6, type: 'object' },

    // Languages & Experience
    languages: { weight: 4, type: 'array' },
    workExperience: { weight: 6, type: 'array' },

    // Portfolio & Testimonials
    portfolioItems: { weight: 6, type: 'array' },
    testimonials: { weight: 4, type: 'array' },

    // Additional Info
    countriesServed: { weight: 4, type: 'array' },
    awardsAndCertifications: { weight: 4, type: 'array' },
  };

  let totalWeight = 0;
  let completedWeight = 0;

  Object.entries(requiredFields).forEach(([field, config]) => {
    totalWeight += config.weight;

    if (userProfile[field]) {
      switch (config.type) {
        case 'string':
          if (
            typeof userProfile[field] === 'string' &&
            userProfile[field].trim() !== ''
          ) {
            completedWeight += config.weight;
          }
          break;

        case 'array':
          if (
            Array.isArray(userProfile[field]) &&
            userProfile[field].length > 0
          ) {
            completedWeight += config.weight;
          }
          break;

        case 'object':
          if (
            typeof userProfile[field] === 'object' &&
            !Array.isArray(userProfile[field]) &&
            Object.keys(userProfile[field] || {}).length > 0
          ) {
            completedWeight += config.weight;
          }
          break;
      }
    }
  });

  return Math.round((completedWeight / totalWeight) * 100);
};

export const getProfileCompletionDetails = (userProfile) => {
  const completionPercentage = calculateProfileCompletion(userProfile);

  // Return detailed breakdown of completion
  return {
    percentage: completionPercentage,
    nextSteps: getNextSteps(userProfile),
    completedSections: getCompletedSections(userProfile),
    remainingSections: getRemainingSections(userProfile),
  };
};

const getNextSteps = (userProfile) => {
  const steps = [];

  if (!userProfile.fullName || !userProfile.bio) {
    steps.push('Complete your personal details');
  }
  if (!userProfile.email || !userProfile.phone) {
    steps.push('Add contact information');
  }
  if (!userProfile.skills || Object.keys(userProfile.skills).length === 0) {
    steps.push('Add your skills and expertise');
  }
  if (!userProfile.workExperience || userProfile.workExperience.length === 0) {
    steps.push('Add work experience');
  }
  if (!userProfile.portfolioItems || userProfile.portfolioItems.length === 0) {
    steps.push('Add portfolio items');
  }

  return steps;
};

const getCompletedSections = (userProfile) => {
  const completed = [];

  if (userProfile.fullName && userProfile.bio) {
    completed.push('Personal Details');
  }
  if (userProfile.email && userProfile.phone) {
    completed.push('Contact Information');
  }
  if (userProfile.skills && Object.keys(userProfile.skills).length > 0) {
    completed.push('Skills & Expertise');
  }
  if (userProfile.workExperience && userProfile.workExperience.length > 0) {
    completed.push('Work Experience');
  }
  if (userProfile.portfolioItems && userProfile.portfolioItems.length > 0) {
    completed.push('Portfolio');
  }

  return completed;
};

const getRemainingSections = (userProfile) => {
  const allSections = [
    'Personal Details',
    'Contact Information',
    'Skills & Expertise',
    'Work Experience',
    'Portfolio',
    'Testimonials',
    'Languages',
    'Countries Served',
    'Awards & Certifications',
  ];

  const completed = getCompletedSections(userProfile);
  return allSections.filter((section) => !completed.includes(section));
};
