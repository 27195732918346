import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

function MemberContent() {
  const [memberContent, setMemberContent] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchMemberContent();
  }, []);

  const fetchMemberContent = async () => {
    setLoading(true);
    setError(null);
    try {
      const contentQuery = query(
        collection(db, 'memberContent'),
        where('isPublic', '==', false),
      );
      const contentSnapshot = await getDocs(contentQuery);
      const contentList = contentSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMemberContent(contentList);
    } catch (err) {
      console.error('Error fetching member content:', err);
      setError('Failed to fetch member content');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <div>Loading member content...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="member-content">
      <h2>Member-Only Content</h2>
      {memberContent.map((content) => (
        <div key={content.id} className="content-item">
          <h3>{content.title}</h3>
          <p>{content.description}</p>
          {content.type === 'product' && <p>Price: ${content.price}</p>}
          {content.type === 'service' && <p>Service Fee: ${content.fee}</p>}
          <a
            href={content.link}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-primary"
          >
            View {content.type === 'product' ? 'Product' : 'Service'}
          </a>
        </div>
      ))}
    </div>
  );
}

export default MemberContent;
