import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import {
  doc,
  setDoc,
  getDoc,
  collection,
  addDoc,
  updateDoc,
  serverTimestamp,
  increment,
  arrayUnion,
  getDocs,
} from 'firebase/firestore';
import PropTypes from 'prop-types';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(() => {
    // Initialize from localStorage if available
    const savedUser = localStorage.getItem('authUser');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [loading, setLoading] = useState(true);

  async function signup(email, password) {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );
    await createUserProfile(userCredential.user);
    return userCredential;
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  async function signInWithGoogle() {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      return result;
    } catch (error) {
      console.error('Error in signInWithGoogle:', error);
      throw error;
    }
  }

  async function logout() {
    try {
      await signOut(auth);
      localStorage.removeItem('authUser');
      setCurrentUser(null);
    } catch (error) {
      console.error('Error logging out:', error);
      throw error;
    }
  }

  async function createUserProfile(user) {
    const userRef = doc(db, 'users', user.uid);
    const userSnap = await getDoc(userRef);

    if (!userSnap.exists()) {
      const { displayName, email, photoURL } = user;
      await setDoc(userRef, {
        displayName,
        email,
        photoURL,
        createdAt: serverTimestamp(),
        skills: {},
        socialLinks: {},
        profilePicture: {
          url: photoURL,
          updatedAt: serverTimestamp(),
          provider: 'google',
        },
      });
    }
  }

  async function updateProfilePicture(userId, photoURL) {
    const userRef = doc(db, 'users', userId);
    await updateDoc(userRef, {
      photoURL: photoURL,
      'profilePicture.url': photoURL,
      'profilePicture.updatedAt': serverTimestamp(),
    });
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'users', user.uid));
          const userData = userDoc.data();
          const authUser = {
            ...user,
            isAdmin: userData?.isAdmin || false,
          };
          // Save to localStorage
          localStorage.setItem('authUser', JSON.stringify(authUser));
          setCurrentUser(authUser);
        } catch (error) {
          console.error('Error fetching user data:', error);
          localStorage.removeItem('authUser');
          setCurrentUser(null);
        }
      } else {
        localStorage.removeItem('authUser');
        setCurrentUser(null);
      }
      setLoading(false);
    });

    // Listen for storage events (for cross-tab synchronization)
    const handleStorageChange = (e) => {
      if (e.key === 'authUser') {
        setCurrentUser(e.newValue ? JSON.parse(e.newValue) : null);
      }
    };
    window.addEventListener('storage', handleStorageChange);

    return () => {
      unsubscribe();
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const requestInvitationCode = async (email, name) => {
    try {
      const inviteRequestRef = collection(db, 'invitationRequests');
      await addDoc(inviteRequestRef, {
        email,
        name,
        status: 'pending',
        createdAt: serverTimestamp(),
        requestDate: new Date().toISOString(),
        metadata: {
          userAgent: window.navigator.userAgent,
          platform: window.navigator.platform,
        },
      });

      console.log('Invitation request created successfully');
      return true;
    } catch (error) {
      console.error('Error requesting invitation:', error);
      throw new Error('Failed to submit invitation request. Please try again.');
    }
  };

  const value = {
    currentUser,
    signup,
    login,
    signInWithGoogle,
    logout,
    requestInvitationCode,
    updateProfilePicture,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

// Update the isNewUser function
const isNewUser = async (uid) => {
  try {
    const userDoc = await getDoc(doc(db, 'users', uid));
    console.log('Checking if user exists:', uid, !userDoc.exists());
    return !userDoc.exists();
  } catch (error) {
    console.error('Error checking if user is new:', error);
    return true;
  }
};
