import React from 'react';
import PropTypes from 'prop-types';
import '../styles/profileWizardMascot.css';

const messages = [
  "Let's make your profile shine!",
  "You're doing great! Keep it up!",
  'Almost there! Your profile is looking awesome!',
  "Wow, you're a superstar! This profile is amazing!",
];

const ProfileWizardMascot = ({ step }) => {
  return (
    <div className="profile-wizard-mascot">
      <div className="mascot-content">
        <img
          src="https://png.pngtree.com/png-vector/20240719/ourmid/pngtree-sport-mascot-of-a-gorilla-bodybuilder-with-dumbbells-png-image_12980166.png"
          alt="Profile Wizard Mascot"
        />
        <p>{messages[Math.min(step, messages.length - 1)]}</p>
      </div>
    </div>
  );
};

ProfileWizardMascot.propTypes = {
  step: PropTypes.number.isRequired,
};

export default ProfileWizardMascot;
