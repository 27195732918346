import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { FaGoogle } from 'react-icons/fa';
import { getDoc, doc } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { signOut } from 'firebase/auth';
import '../styles/Login.css';

function Login() {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    error: '',
    loading: false,
  });

  const { login, signInWithGoogle } = useAuth();
  const navigate = useNavigate();

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setFormData((prev) => ({ ...prev, error: '', loading: true }));
      const result = await login(formData.email, formData.password);

      // Check if user exists and is admin
      const userDoc = await getDoc(doc(db, 'users', result.user.uid));
      const userData = userDoc.data();

      if (userData?.isAdmin) {
        navigate('/admin');
      } else {
        navigate('/user-dashboard');
      }
    } catch (error) {
      console.error('Login error:', error);
      setFormData((prev) => ({
        ...prev,
        error: 'Invalid email or password',
        loading: false,
      }));
    }
  }

  async function handleGoogleSignIn() {
    try {
      setFormData((prev) => ({ ...prev, error: '', loading: true }));
      const result = await signInWithGoogle();

      if (!result?.user?.uid) {
        throw new Error('No user ID found after Google sign in');
      }

      // Check if user exists in our database
      const userDoc = await getDoc(doc(db, 'users', result.user.uid));
      const userData = userDoc.data();

      if (!userDoc.exists()) {
        // Sign out the user immediately
        await signOut(auth);
        setFormData((prev) => ({
          ...prev,
          error: 'Please register with an invitation code first',
          loading: false,
        }));
        navigate('/register', {
          state: {
            message: 'Please register with an invitation code first',
            email: result.user.email,
          },
        });
        return;
      }

      // Check if user is admin
      if (userData?.isAdmin) {
        navigate('/admin');
      } else {
        navigate('/user-dashboard');
      }
    } catch (error) {
      console.error('Login error:', error);
      setFormData((prev) => ({
        ...prev,
        error: error.message || 'Failed to sign in',
        loading: false,
      }));
    } finally {
      setFormData((prev) => ({ ...prev, loading: false }));
    }
  }

  return (
    <div className="login-container">
      <div className="login-card">
        <h2 className="login-title">Log In</h2>
        {formData.error && (
          <div className="error-message">{formData.error}</div>
        )}

        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  email: e.target.value,
                }))
              }
              required
              className="form-input"
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={formData.password}
              onChange={(e) =>
                setFormData((prev) => ({
                  ...prev,
                  password: e.target.value,
                }))
              }
              required
              className="form-input"
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-block"
            disabled={formData.loading}
          >
            {formData.loading ? 'Logging in...' : 'Log In'}
          </button>
        </form>

        <div className="divider">
          <span>OR</span>
        </div>

        <div className="google-signin">
          <button
            onClick={handleGoogleSignIn}
            className="btn btn-google btn-block"
            disabled={formData.loading}
          >
            <FaGoogle className="google-icon" />
            Sign in with Google
          </button>
        </div>

        <div className="login-footer">
          <p>
            Need an account?{' '}
            <Link to="/register" className="signup-link">
              Sign Up
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
