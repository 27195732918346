import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from 'firebase/firestore';
import PropTypes from 'prop-types';

const PRICING_TYPES = {
  FIXED: 'fixed',
  STARTING_FROM: 'starting_from',
  ON_REQUEST: 'on_request',
};

function UserServices({ userId, handleImageUpload }) {
  const [services, setServices] = useState([]);
  const [newService, setNewService] = useState({
    title: '',
    description: '',
    price: '',
    pricingType: PRICING_TYPES.FIXED,
    serviceLink: '',
    sampleLink: '',
    imageUrl: '',
    category: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [editingService, setEditingService] = useState(null);

  useEffect(() => {
    fetchServices();
  }, [userId]);

  const fetchServices = async () => {
    setLoading(true);
    setError(null);
    try {
      const servicesQuery = query(
        collection(db, 'services'),
        where('userId', '==', userId),
      );
      const servicesSnapshot = await getDocs(servicesQuery);
      const servicesList = servicesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setServices(servicesList);
    } catch (err) {
      console.error('Error fetching services:', err);
      setError('Failed to fetch services');
    } finally {
      setLoading(false);
    }
  };

  const addService = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      let imageUrl = newService.imageUrl;
      if (imageFile) {
        imageUrl = await handleImageUpload(imageFile, 'service');
      }
      await addDoc(collection(db, 'services'), {
        ...newService,
        imageUrl,
        userId,
      });
      setNewService({ title: '', description: '', price: '', imageUrl: '' });
      setImageFile(null);
      await fetchServices();
    } catch (err) {
      console.error('Error adding service:', err);
      setError('Failed to add service');
    } finally {
      setLoading(false);
    }
  };

  const updateService = async (id, updatedService) => {
    setLoading(true);
    setError(null);
    try {
      let imageUrl = updatedService.imageUrl;
      if (imageFile) {
        imageUrl = await handleImageUpload(imageFile, 'service');
      }
      await updateDoc(doc(db, 'services', id), { ...updatedService, imageUrl });
      setImageFile(null);
      await fetchServices();
    } catch (err) {
      console.error('Error updating service:', err);
      setError('Failed to update service');
    } finally {
      setLoading(false);
    }
  };

  const deleteService = async (id) => {
    if (window.confirm('Are you sure you want to delete this service?')) {
      setLoading(true);
      setError(null);
      try {
        await deleteDoc(doc(db, 'services', id));
        await fetchServices();
      } catch (err) {
        console.error('Error deleting service:', err);
        setError('Failed to delete service');
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading) return <div>Loading services...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="user-services">
      <h2>My Services</h2>
      <form
        onSubmit={
          editingService
            ? (e) => {
                e.preventDefault();
                updateService(editingService.id, newService);
                setEditingService(null);
              }
            : addService
        }
        className="service-form"
      >
        <input
          type="text"
          placeholder="Service Title"
          value={newService.title}
          onChange={(e) =>
            setNewService({ ...newService, title: e.target.value })
          }
          required
        />
        <textarea
          placeholder="Service Description"
          value={newService.description}
          onChange={(e) =>
            setNewService({ ...newService, description: e.target.value })
          }
          required
        ></textarea>
        <div className="service-form-section">
          <div className="form-group">
            <label>Pricing Type</label>
            <select
              value={newService.pricingType}
              onChange={(e) =>
                setNewService((prev) => ({
                  ...prev,
                  pricingType: e.target.value,
                  price:
                    e.target.value === PRICING_TYPES.ON_REQUEST
                      ? ''
                      : prev.price,
                }))
              }
              className="form-select"
            >
              <option value={PRICING_TYPES.FIXED}>Fixed Price</option>
              <option value={PRICING_TYPES.STARTING_FROM}>Starting From</option>
              <option value={PRICING_TYPES.ON_REQUEST}>On Request</option>
            </select>
          </div>

          {newService.pricingType !== PRICING_TYPES.ON_REQUEST && (
            <div className="form-group">
              <label>Price (USD)</label>
              <input
                type="number"
                value={newService.price}
                onChange={(e) =>
                  setNewService((prev) => ({
                    ...prev,
                    price: e.target.value,
                  }))
                }
                placeholder="Enter price"
                className="form-input"
              />
            </div>
          )}

          <div className="form-group">
            <label>Service Link (Optional)</label>
            <input
              type="url"
              value={newService.serviceLink}
              onChange={(e) =>
                setNewService((prev) => ({
                  ...prev,
                  serviceLink: e.target.value,
                }))
              }
              placeholder="https://example.com/service"
              className="form-input"
            />
          </div>

          <div className="form-group">
            <label>Sample/Demo Link (Optional)</label>
            <input
              type="url"
              value={newService.sampleLink}
              onChange={(e) =>
                setNewService((prev) => ({
                  ...prev,
                  sampleLink: e.target.value,
                }))
              }
              placeholder="https://example.com/sample"
              className="form-input"
            />
          </div>
        </div>
        <input
          type="text"
          placeholder="Image URL"
          value={newService.imageUrl}
          onChange={(e) =>
            setNewService({ ...newService, imageUrl: e.target.value })
          }
        />
        <label className="file-input-label">
          Upload Image
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImageFile(e.target.files[0])}
            className="file-input"
          />
        </label>
        {imageFile && <span className="file-name">{imageFile.name}</span>}
        <button type="submit" className="btn btn-primary">
          {editingService ? 'Update Service' : 'Add Service'}
        </button>
        {editingService && (
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => {
              setEditingService(null);
              setNewService({
                title: '',
                description: '',
                price: '',
                imageUrl: '',
              });
              setImageFile(null);
            }}
          >
            Cancel Edit
          </button>
        )}
      </form>
      <div className="services-list">
        {services.map((service) => (
          <div key={service.id} className="service-card">
            {service.imageUrl && (
              <img
                src={service.imageUrl}
                alt={service.title}
                className="service-image"
              />
            )}
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            <p className="service-price">Price: ${service.price}</p>
            <div className="service-actions">
              <button
                onClick={() => {
                  setEditingService(service);
                  setNewService(service);
                }}
                className="btn btn-secondary btn-block"
              >
                Edit
              </button>
              <button
                onClick={() => deleteService(service.id)}
                className="btn btn-danger btn-block"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

UserServices.propTypes = {
  userId: PropTypes.string.isRequired,
  handleImageUpload: PropTypes.func.isRequired,
};

export default UserServices;
