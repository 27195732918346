import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Navigate, Link } from 'react-router-dom';
import { db, storage } from '../firebase';
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
  limit,
  setDoc,
  deleteDoc,
  onSnapshot,
  orderBy,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import MemberContent from './MemberContent';
import UserServices from './UserServices';
import ProfileWizard from './ProfileWizard';
import '../styles/UserDashboard.css'; // Import a CSS file for styling
import {
  FaHeart,
  FaEnvelope,
  FaCheck,
  FaTrash,
  FaPhone,
  FaWhatsapp,
  FaCopy,
  FaUsers,
  FaCheckCircle,
  FaExclamationCircle,
} from 'react-icons/fa';
import {
  BADGES,
  BADGE_CATEGORIES,
  checkBadgeEligibility,
} from '../data/badges';
import {
  calculateProfileCompletion,
  getProfileCompletionDetails,
} from '../utils/profileUtils';

function UserDashboard() {
  const [selectedOption, setSelectedOption] = useState(null);
  const { currentUser } = useAuth();
  const [userProfile, setUserProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [upcomingEvents, setUpcomingEvents] = useState([]);
  const [memberProducts, setMemberProducts] = useState([]);
  const [invitation, setInvitation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [loveCount, setLoveCount] = useState(0);
  const [showBadgeModal, setShowBadgeModal] = useState(false);

  useEffect(() => {
    if (currentUser) {
      fetchUserProfile();
      fetchUpcomingEvents();
      fetchMemberProducts();
      fetchOrCreateInvitation();
      const fetchLoveCount = async () => {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setLoveCount(userDoc.data().loveCount || 0);
        }
      };

      const fetchMessages = () => {
        const messagesQuery = query(
          collection(db, 'messages'),
          where('to', '==', currentUser.uid),
          orderBy('createdAt', 'desc'),
        );

        const unsubscribeMessages = onSnapshot(messagesQuery, (snapshot) => {
          const newMessages = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate(),
          }));
          setMessages(newMessages);
          setUnreadCount(newMessages.filter((msg) => !msg.read).length);
        });

        return unsubscribeMessages;
      };

      fetchLoveCount();
      const unsubscribe = fetchMessages();
      return () => {
        if (typeof unsubscribe === 'function') {
          unsubscribe();
        }
      };
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      console.log('Setting up message listener for user:', currentUser.uid);

      const messagesQuery = query(
        collection(db, 'messages'),
        where('to', '==', currentUser.uid),
        orderBy('createdAt', 'desc'),
      );

      const unsubscribe = onSnapshot(
        messagesQuery,
        (snapshot) => {
          console.log(
            'Message snapshot received:',
            snapshot.docs.length,
            'messages',
          );
          const newMessages = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt?.toDate(),
          }));
          setMessages(newMessages);
          setUnreadCount(newMessages.filter((msg) => !msg.read).length);
        },
        (error) => {
          console.error('Error in message listener:', error);
        },
      );

      return () => unsubscribe();
    }
  }, [currentUser]);

  const fetchUserProfile = async () => {
    setLoading(true);
    setError(null);
    try {
      const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
      if (userDoc.exists()) {
        setUserProfile(userDoc.data());
      } else {
        setError('User profile not found');
      }
    } catch (err) {
      console.error('Error fetching user profile:', err);
      setError('Failed to fetch user profile');
    } finally {
      setLoading(false);
    }
  };

  const fetchUpcomingEvents = async () => {
    try {
      const eventsRef = collection(db, 'events');
      const q = query(eventsRef, where('date', '>=', new Date()), limit(3));
      const querySnapshot = await getDocs(q);
      const events = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUpcomingEvents(events);
    } catch (err) {
      console.error('Error fetching upcoming events:', err);
    }
  };

  const fetchMemberProducts = async () => {
    try {
      const productsRef = collection(db, 'products');
      const q = query(productsRef, where('memberOnly', '==', true), limit(3));
      const querySnapshot = await getDocs(q);
      const products = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMemberProducts(products);
    } catch (err) {
      console.error('Error fetching member products:', err);
    }
  };

  const fetchOrCreateInvitation = async () => {
    try {
      const codeRef = doc(db, 'invitationCodes', currentUser.uid);
      const codeDoc = await getDoc(codeRef);

      if (codeDoc.exists()) {
        setInvitation(codeDoc.data());
      } else {
        const newCode = generateInviteCode();
        const invitationData = {
          code: newCode,
          userId: currentUser.uid,
          usedCount: 0,
          maxUses: 5,
          usedBy: [],
        };
        await setDoc(codeRef, invitationData);
        setInvitation(invitationData);
      }
    } catch (err) {
      console.error('Error fetching or creating invitation:', err);
    }
  };

  const generateInviteCode = () => {
    return Math.random().toString(36).substring(2, 10).toUpperCase();
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => alert('Copied to clipboard!'),
      (err) => console.error('Could not copy text: ', err),
    );
  };

  const updateUserProfile = async (updatedProfile) => {
    setLoading(true);
    setError(null);
    try {
      await updateDoc(doc(db, 'users', currentUser.uid), updatedProfile);
      setUserProfile(updatedProfile);
    } catch (err) {
      console.error('Error updating user profile:', err);
      setError('Failed to update user profile');
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (file, type) => {
    if (!file) return null;
    const storageRef = ref(
      storage,
      `user-images/${currentUser.uid}/${type}/${file.name}`,
    );
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  const handleProfileWizardComplete = async (updatedProfile) => {
    await updateUserProfile(updatedProfile);
    setSelectedOption(null); // Return to dashboard home after completing the wizard
  };

  const markAsRead = async (messageId) => {
    await updateDoc(doc(db, 'messages', messageId), {
      read: true,
    });
  };

  const deleteMessage = async (messageId) => {
    if (window.confirm('Are you sure you want to delete this message?')) {
      await deleteDoc(doc(db, 'messages', messageId));
    }
  };

  // Helper function for date formatting
  const formatDuration = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : new Date();
    const startYear = start.getFullYear();
    const endYear = end.getFullYear();
    const startMonth = start.toLocaleString('default', { month: 'short' });
    const endMonth = end.toLocaleString('default', { month: 'short' });

    return endDate
      ? `${startMonth} ${startYear} - ${endMonth} ${endYear}`
      : `${startMonth} ${startYear} - Present`;
  };

  // Experience timeline rendering function
  const renderExperienceTimeline = () => {
    if (!userProfile?.experience?.length) {
      return <p>No experience added yet.</p>;
    }

    return (
      <div className="experience-section">
        <h3>Professional Experience</h3>
        <div className="experience-timeline">
          {userProfile.experience
            .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
            .map((exp, index) => (
              <div key={index} className="experience-item">
                <div className="experience-header">
                  <h4 className="experience-title">{exp.jobTitle}</h4>
                  <span className="experience-date">
                    {formatDuration(exp.startDate, exp.endDate)}
                  </span>
                </div>
                <div className="experience-company">{exp.company}</div>
                <p className="experience-description">{exp.description}</p>
              </div>
            ))}
        </div>
      </div>
    );
  };

  // Content rendering function
  function renderContent() {
    switch (selectedOption) {
      case 'profile-wizard':
        return (
          <ProfileWizard
            userProfile={userProfile}
            onComplete={handleProfileWizardComplete}
          />
        );
      case 'member-content':
        return <MemberContent />;
      case 'services':
        return (
          <UserServices
            userId={currentUser.uid}
            handleImageUpload={handleImageUpload}
          />
        );
      case 'experience':
        return renderExperienceTimeline();
      default:
        return (
          <div className="user-home">
            <h2>Welcome, {userProfile?.name || currentUser.email}!</h2>
            <div className="dashboard-grid">
              <div className="dashboard-column">
                <div className="profile-completion card">
                  <h3>Profile Completion</h3>
                  <div className="progress-container">
                    <div className="progress-bar">
                      <div
                        className="progress"
                        style={{
                          width: `${
                            calculateProfileCompletion(userProfile) || 0
                          }%`,
                        }}
                      ></div>
                    </div>
                    <p>
                      {calculateProfileCompletion(userProfile) || 0}% complete
                    </p>
                  </div>

                  {(calculateProfileCompletion(userProfile) || 0) < 100 && (
                    <>
                      <div className="next-steps">
                        <h4>Next Steps to Complete Your Profile:</h4>
                        {renderCompletionDetails(userProfile)}
                      </div>
                      <button
                        onClick={() => setSelectedOption('profile-wizard')}
                        className="complete-profile-btn"
                      >
                        Complete Your Profile
                      </button>
                    </>
                  )}
                </div>
                <div className="summary-card card">
                  <h3>Manage Invitations</h3>
                  {invitation ? (
                    <div className="invitation-details">
                      <div className="invitation-code">
                        <span className="code-value">{invitation.code}</span>
                        <button
                          className="copy-btn"
                          onClick={() =>
                            copyToClipboard(
                              `${window.location.origin}/register?invite=${invitation.code}`,
                            )
                          }
                        >
                          <FaCopy />
                        </button>
                      </div>
                      <div className="invitation-stats">
                        <div className="stat-item">
                          <div className="stat-value">
                            {invitation.maxUses - invitation.usedCount}
                          </div>
                          <div className="stat-label">Remaining Invites</div>
                        </div>
                        <div className="stat-item">
                          <div className="stat-value">
                            {invitation.usedCount}
                          </div>
                          <div className="stat-label">Successfully Used</div>
                        </div>
                      </div>
                      <button
                        onClick={() =>
                          copyToClipboard(
                            `${window.location.origin}/register?invite=${invitation.code}`,
                          )
                        }
                        className="btn"
                      >
                        Share Invitation Link
                      </button>
                    </div>
                  ) : (
                    <p>Loading invitation details...</p>
                  )}
                </div>
              </div>
              <div className="dashboard-column">
                <div className="summary-card card">
                  <h3>Upcoming Events</h3>
                  {upcomingEvents.length > 0 ? (
                    <ul className="event-list">
                      {upcomingEvents.map((event) => (
                        <li key={event.id} className="event-item">
                          <span className="event-title">{event.title}</span>
                          <span className="event-date">
                            {new Date(event.date).toLocaleDateString()}
                          </span>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No upcoming events</p>
                  )}
                  <Link to="/events" className="btn">
                    View All Events
                  </Link>
                </div>
                <div className="summary-card card">
                  <h3>Member-Only Products</h3>
                  {memberProducts.length > 0 ? (
                    <ul className="product-list">
                      {memberProducts.map((product) => (
                        <li key={product.id} className="product-item">
                          <span className="product-name">{product.name}</span>
                          <span className="product-price">
                            ${product.price.toFixed(2)}
                          </span>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No member-only products available</p>
                  )}
                  <button
                    onClick={() => setSelectedOption('member-content')}
                    className="btn"
                  >
                    View All Products
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
    }
  }

  // Add this function to handle contact actions
  const handleContactAction = (type, contact) => {
    switch (type) {
      case 'email':
        window.location.href = `mailto:${contact}`;
        break;
      case 'phone':
        window.location.href = `tel:${contact}`;
        break;
      case 'whatsapp':
        window.open(
          `https://wa.me/${contact.replace(/[^0-9]/g, '')}`,
          '_blank',
        );
        break;
      default:
        break;
    }
  };

  const renderBadgeProgress = () => {
    if (!userProfile) return null;

    const earnedBadges = Object.values(BADGES).filter((badge) =>
      checkBadgeEligibility(badge, userProfile),
    );

    const nextBadges = Object.values(BADGES).filter(
      (badge) => !checkBadgeEligibility(badge, userProfile),
    );

    return (
      <div className="badges-progress card">
        <h3>Your Badges</h3>
        <div className="earned-badges">
          {earnedBadges.map((badge) => (
            <div key={badge.id} className="badge-item earned">
              <span className="badge-icon">{badge.icon}</span>
              <div className="badge-info">
                <h4>{badge.name}</h4>
                <p>{badge.description}</p>
              </div>
            </div>
          ))}
        </div>

        <h4>Next Achievements</h4>
        <div className="next-badges">
          {nextBadges.slice(0, 3).map((badge) => (
            <div key={badge.id} className="badge-item locked">
              <span className="badge-icon locked">{badge.icon}</span>
              <div className="badge-info">
                <h4>{badge.name}</h4>
                <p>{badge.description}</p>
                <div className="badge-tips">
                  {getBadgeTips(badge, userProfile)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const getBadgeTips = (badge, profile) => {
    const { criteria } = badge;

    if (criteria.requiredFields) {
      const missingFields = criteria.requiredFields.filter(
        (field) => !profile[field],
      );
      if (missingFields.length > 0) {
        return `Complete these fields: ${missingFields.join(', ')}`;
      }
    }

    if (criteria.completionPercentage) {
      const current = profile.profileCompletionPercentage || 0;
      return `Profile completion: ${current}% / ${criteria.completionPercentage}%`;
    }

    if (criteria.countriesCount) {
      const current = profile.countriesServed?.length || 0;
      return `Countries served: ${current} / ${criteria.countriesCount}`;
    }

    if (criteria.servicesCount) {
      const current = profile.servicesCount || 0;
      return `Services added: ${current} / ${criteria.servicesCount}`;
    }

    if (criteria.lovesCount) {
      const current = profile.loveCount || 0;
      return `Profile loves: ${current} / ${criteria.lovesCount}`;
    }

    if (criteria.invitationsUsed) {
      const current = profile.successfulInvites || 0;
      return `Successful invites: ${current} / ${criteria.invitationsUsed}`;
    }

    return 'Keep engaging with the community!';
  };

  const renderBadgeModal = () => {
    const earnedBadges = Object.values(BADGES).filter((badge) =>
      checkBadgeEligibility(badge, userProfile),
    );

    const nextBadges = Object.values(BADGES)
      .filter((badge) => !checkBadgeEligibility(badge, userProfile))
      .slice(0, 3); // Show only next 3 badges

    return (
      <div className="modal-overlay" onClick={() => setShowBadgeModal(false)}>
        <div className="badges-modal" onClick={(e) => e.stopPropagation()}>
          <button
            className="close-btn"
            onClick={() => setShowBadgeModal(false)}
          >
            ×
          </button>

          <div className="badges-modal-content">
            <div className="earned-badges-section">
              <h3>Earned Badges</h3>
              <div className="earned-badges-grid">
                {earnedBadges.map((badge) => (
                  <div key={badge.id} className="badge-card earned">
                    <div className="badge-icon-large">{badge.icon}</div>
                    <div className="badge-info">
                      <h4>{badge.name}</h4>
                      <p>{badge.description}</p>
                      <span className="earned-date">
                        Earned on {new Date().toLocaleDateString()}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="upcoming-badges-section">
              <h3>Next Achievements</h3>
              <div className="upcoming-badges-grid">
                {nextBadges.map((badge) => (
                  <div key={badge.id} className="badge-card upcoming">
                    <div className="badge-icon-large locked">{badge.icon}</div>
                    <div className="badge-info">
                      <h4>{badge.name}</h4>
                      <p>{badge.description}</p>
                      <div className="badge-requirements">
                        <h5>Requirements:</h5>
                        <ul>
                          {Object.entries(badge.criteria).map(
                            ([key, value]) => (
                              <li key={key}>
                                {getBadgeTips(badge, userProfile)}
                              </li>
                            ),
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderCompletionDetails = (profile) => {
    const details = getProfileCompletionDetails(profile) || {
      incomplete: [],
      complete: [],
    };

    return (
      <div className="completion-details">
        {details.incomplete?.map((item, index) => (
          <div key={index} className="completion-item">
            <FaExclamationCircle className="incomplete-icon" />
            <span>{item}</span>
          </div>
        ))}
        {details.complete?.map((item, index) => (
          <div key={index} className="completion-item completed">
            <FaCheckCircle className="complete-icon" />
            <span>{item}</span>
          </div>
        ))}
      </div>
    );
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (loading) return <div>Loading user profile...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="user-dashboard">
      <h1 className="page-title">User Dashboard</h1>
      <div className="user-nav">
        <button
          onClick={() => setSelectedOption(null)}
          className={`btn ${!selectedOption ? 'active' : ''}`}
        >
          Dashboard Home
        </button>
        <button
          onClick={() => setSelectedOption('profile-wizard')}
          className={`btn ${
            selectedOption === 'profile-wizard' ? 'active' : ''
          }`}
        >
          Profile Wizard
        </button>
        <button
          onClick={() => setSelectedOption('member-content')}
          className={`btn ${
            selectedOption === 'member-content' ? 'active' : ''
          }`}
        >
          Member Content
        </button>
        <button
          onClick={() => setSelectedOption('services')}
          className={`btn ${selectedOption === 'services' ? 'active' : ''}`}
        >
          My Services
        </button>
        <Link
          to={`/profile/${currentUser.uid}`}
          className="btn"
          target="_blank"
        >
          View Public Profile
        </Link>
      </div>

      <div className="dashboard-stats">
        <div className="stat-card love-count">
          <FaHeart />
          <div className="stat-info">
            <span className="stat-value">{loveCount}</span>
            <span className="stat-label">Profile Loves</span>
          </div>
        </div>

        <div
          className="stat-card messages"
          onClick={() => setShowMessageModal(true)}
        >
          <div className="message-icon">
            <FaEnvelope />
            {unreadCount > 0 && (
              <span className="unread-badge">{unreadCount}</span>
            )}
          </div>
          <div className="stat-info">
            <span className="stat-value">{messages.length}</span>
            <span className="stat-label">Messages</span>
          </div>
        </div>

        <div
          className="stat-card badges-overview"
          onClick={() => setShowBadgeModal(true)}
        >
          <div className="badges-summary">
            <div className="earned-count">
              <span className="badge-icon">🏆</span>
              <div className="stat-info">
                <span className="stat-value">
                  {
                    Object.values(BADGES).filter((badge) =>
                      checkBadgeEligibility(badge, userProfile),
                    ).length
                  }
                </span>
                <span className="stat-label">Badges Earned</span>
              </div>
            </div>
            <div className="next-badge">
              <span className="next-badge-name">
                {Object.values(BADGES).find(
                  (badge) => !checkBadgeEligibility(badge, userProfile),
                )?.name || 'All badges earned!'}
              </span>
              <span className="next-label">Next badge</span>
            </div>
          </div>
        </div>
      </div>

      <div className="user-content">{renderContent()}</div>

      {showMessageModal && (
        <div
          className="modal-overlay"
          onClick={() => {
            setShowMessageModal(false);
            setSelectedMessage(null);
          }}
        >
          <div className="messages-modal" onClick={(e) => e.stopPropagation()}>
            <button
              className="close-btn"
              onClick={() => {
                setShowMessageModal(false);
                setSelectedMessage(null);
              }}
            >
              ×
            </button>

            <h2>{selectedMessage ? 'Message Details' : 'Messages'}</h2>

            {selectedMessage ? (
              <div className="message-details">
                <div className="sender-info">
                  <h3>{selectedMessage.fromName || 'Anonymous'}</h3>
                  <p className="message-date">
                    {selectedMessage.createdAt?.toLocaleDateString()}{' '}
                    {selectedMessage.createdAt?.toLocaleTimeString()}
                  </p>
                </div>

                <div className="contact-info">
                  {selectedMessage.senderEmail && (
                    <div className="contact-item">
                      <FaEnvelope />
                      <span>{selectedMessage.senderEmail}</span>
                      <div className="contact-actions">
                        <button
                          className="contact-btn"
                          onClick={() =>
                            handleContactAction(
                              'email',
                              selectedMessage.senderEmail,
                            )
                          }
                        >
                          Reply via Email
                        </button>
                      </div>
                    </div>
                  )}

                  {selectedMessage.senderPhone && (
                    <div className="contact-item">
                      <FaPhone />
                      <span>{selectedMessage.senderPhone}</span>
                      <div className="contact-actions">
                        <button
                          className="contact-btn"
                          onClick={() =>
                            handleContactAction(
                              'phone',
                              selectedMessage.senderPhone,
                            )
                          }
                        >
                          Call
                        </button>
                        <button
                          className="contact-btn"
                          onClick={() =>
                            handleContactAction(
                              'whatsapp',
                              selectedMessage.senderPhone,
                            )
                          }
                        >
                          WhatsApp
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <div className="message-content">
                  <p>{selectedMessage.message}</p>
                </div>

                <div className="modal-actions">
                  <button
                    className="btn btn-secondary"
                    onClick={() => setSelectedMessage(null)}
                  >
                    Back to Messages
                  </button>
                </div>
              </div>
            ) : (
              <div className="messages-list">
                {messages.map((message) => (
                  <div
                    key={message.id}
                    className={`message-item ${!message.read ? 'unread' : ''}`}
                    onClick={() => {
                      setSelectedMessage(message);
                      if (!message.read) markAsRead(message.id);
                    }}
                  >
                    <div className="message-header">
                      <span className="sender">
                        {message.fromName || 'Anonymous'}
                      </span>
                      <span className="date">
                        {message.createdAt?.toLocaleDateString()}
                      </span>
                    </div>
                    <p className="message-preview">
                      {message.message.substring(0, 100)}...
                    </p>
                    <div className="contact-options">
                      <button
                        className={`contact-btn outline ${
                          !message.senderEmail ? 'disabled' : ''
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (message.senderEmail) {
                            handleContactAction('email', message.senderEmail);
                          }
                        }}
                      >
                        <FaEnvelope /> Reply via Email
                      </button>
                      <button
                        className={`contact-btn outline ${
                          !message.senderPhone ? 'disabled' : ''
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (message.senderPhone) {
                            handleContactAction('phone', message.senderPhone);
                          }
                        }}
                      >
                        <FaPhone /> Call
                      </button>
                      <button
                        className={`contact-btn outline ${
                          !message.senderPhone ? 'disabled' : ''
                        }`}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (message.senderPhone) {
                            handleContactAction(
                              'whatsapp',
                              message.senderPhone,
                            );
                          }
                        }}
                      >
                        <FaWhatsapp /> WhatsApp
                      </button>
                    </div>
                    <div className="message-actions">
                      <button
                        className="action-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          markAsRead(message.id);
                        }}
                      >
                        <FaCheck />
                      </button>
                      <button
                        className="action-btn delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          deleteMessage(message.id);
                        }}
                      >
                        <FaTrash />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}
      {showBadgeModal && renderBadgeModal()}
    </div>
  );
}

export default UserDashboard;
