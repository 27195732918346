import React, { useState } from 'react';
import {
  createTestInvitationCode,
  validateInviteCode,
} from '../services/firestoreService';

function TestInvite() {
  const [message, setMessage] = useState('');
  const [testCode, setTestCode] = useState('');

  const handleCreateCode = async () => {
    try {
      const code = await createTestInvitationCode();
      setTestCode(code);
      setMessage(`Created test code: ${code}`);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  const handleValidateCode = async () => {
    try {
      const result = await validateInviteCode(testCode);
      setMessage(`Code is valid! Data: ${JSON.stringify(result)}`);
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <h2>Test Invitation System</h2>
      <button onClick={handleCreateCode}>Create Test Code</button>
      <button onClick={handleValidateCode}>Validate Test Code</button>
      <p>Current Code: {testCode}</p>
      <p>Message: {message}</p>
    </div>
  );
}

export default TestInvite;
