import React from 'react';
import { motion } from 'framer-motion';
import {
  FaUsers,
  FaHandshake,
  FaLightbulb,
  FaHeart,
  FaLandmark,
  FaNetworkWired,
  FaUniversity,
  FaGlobe,
  FaFacebook,
  FaUserShield,
  FaUserCog,
} from 'react-icons/fa';
import '../styles/global.css';
import '../styles/About.css';

function About() {
  const executiveCommittee = [
    {
      name: 'Madusanka Premarathne',
      role: 'Executive Director',
      image: '/images/team/madusanka.jpg',
      facebook: 'https://web.facebook.com/madusankapremaratne',
      position:
        'Executive Director at Knovik | Digital Marketing Specialist | Business Growth Strategist',
    },
    {
      name: 'Janith Wickramasinghe',
      role: 'Executive Committee Member',
      image: '/images/team/janith.jpg',
      facebook: 'https://web.facebook.com/maduwantha.wickramasinghe',
      position: 'Tech Community Leader | Full Stack Developer',
    },
    {
      name: 'Ashan Madusanka',
      role: 'Executive Committee Member',
      image: '/images/team/ashan.jpg',
      facebook: 'https://web.facebook.com/ashan.m.gamage',
      position:
        'Co-Founder/Producer at Master Trading Academy | Digital Marketing Professional',
    },
    {
      name: 'Amila Gunawardana',
      role: 'Executive Committee Member',
      image: '/images/team/amila.jpg',
      facebook: 'https://web.facebook.com/angciw',
      position:
        'Digital Marketing Expert | Business Growth Strategist | Entrepreneur',
    },
    {
      name: 'Charith Weerasooriya',
      role: 'Executive Committee Member',
      image: '/images/team/charith.jpg',
      facebook: 'https://web.facebook.com/charithco',
      position:
        'Founder at Blace Talk | Visual Design Specialist | Creative Director',
    },
    {
      name: 'Madushka Dissanayaka',
      role: 'Executive Committee Member',
      image: '/images/team/madushka.jpg',
      facebook: 'https://web.facebook.com/MadushkaD',
      position:
        'Founder at What We Have Done | Social Media Specialist | Digital Strategist',
    },
    {
      name: 'Kosala Sandaruwan',
      role: 'Executive Committee Member',
      image: '/images/team/kosala.jpg',
      facebook: 'https://www.facebook.com/kosala8',
      position:
        'Digital Marketing Professional | Business Development Expert | Entrepreneur',
    },
  ];

  const moderators = [
    {
      name: 'Dinushan Randil',
      role: 'Group Moderator',
      image: '/images/team/dinushan.jpg',
      facebook: 'https://www.facebook.com/dinushan.randil',
      position:
        'Digital Marketing Specialist | Freelance Consultant | Business Development Expert',
    },
    {
      name: 'Kalana Heshan',
      role: 'Group Moderator',
      image: '/images/team/kalana.jpg',
      facebook: 'https://www.facebook.com/kalanaheshanwa',
      position:
        'Professional Freelancer | Digital Marketing Expert | Content Creator',
    },
    {
      name: 'Mawantha Dilshan',
      role: 'Group Moderator',
      image: '/images/team/mawantha.jpg',
      facebook: 'https://www.facebook.com/mawanthadil',
      position:
        'Founder & CEO at Ideacade | Digital Solutions Provider | Tech Entrepreneur',
    },
    {
      name: 'Yohan Rangana',
      role: 'Group Moderator',
      image: '/images/team/yohan.jpg',
      facebook: 'https://www.facebook.com/yohanrw',
      position:
        'Managing Director at 3team International | Business Development Strategist',
    },
    {
      name: 'Sajith Tharaka',
      role: 'Group Moderator',
      image: '/images/team/sajith.jpg',
      facebook: 'https://www.facebook.com/infosajith',
      position:
        'Founder at PEEK Hosting | Tech Solutions Provider | Cloud Infrastructure Expert',
    },
    {
      name: 'Lahiru Tharaka',
      role: 'Group Moderator',
      image: '/images/team/lahiru.jpg',
      facebook: 'https://www.facebook.com/HiruTharke',
      position:
        'Professional Freelancer | Digital Marketing Specialist | Content Strategist',
    },
    {
      name: 'Rumesh Nonis',
      role: 'Group Moderator',
      image: '/images/team/rumesh.jpg',
      facebook: 'https://www.facebook.com/rumeshnoniss',
      position:
        'Account Manager at Forex Trading | Financial Markets Expert | Trading Consultant',
    },
    {
      name: 'Ashan Yasiru',
      role: 'Group Moderator',
      image: '/images/team/ashan-yasiru.jpg',
      facebook: 'https://www.facebook.com/ashan.yasiru',
      position:
        'Co-Founder at Master Trading Academy | Trading Expert | Financial Educator',
    },
    {
      name: 'Radika Dilanka',
      role: 'Group Moderator',
      image: '/images/team/radika.jpg',
      facebook: 'https://www.facebook.com/worldofrapture',
      position:
        'ReactJs Development Instructor | Full Stack Developer | Tech Education Specialist',
    },
    {
      name: 'Kosala Edirisinghe',
      role: 'Group Moderator',
      image: '/images/team/kosala-e.jpg',
      facebook: 'https://www.facebook.com/profile.php?id=100011498166901',
      position:
        'Founder & CEO at EIC Group | Business Development Expert | Digital Entrepreneur',
    },
  ];

  return (
    <div className="about-page">
      <motion.section
        className="hero section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        <div className="container">
          <h1 className="section-title">Our Story</h1>
          <div className="hero-description">
            Born in 2010, OECSL has been at the forefront of Sri Lanka&apos;s
            digital transformation for over 14 years. From its humble beginnings
            as a small tech enthusiasts&apos; group, we&apos;ve grown into the
            nation&apos;s largest community dedicated to empowering Sri Lankan
            youth in technology and innovation.
          </div>
        </div>
      </motion.section>

      <motion.section
        className="founder-message section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <div className="container">
          <h2 className="section-title">Founder&apos;s Message</h2>
          <div className="founder-content">
            <div className="founder-image">
              <img src="/images/founder.jpg" alt="Lasantha Wickramasinghe" />
              <h3>Lasantha Wickramasinghe</h3>
              <p className="founder-title">Founder & Chairman</p>
            </div>
            <div className="founder-text">
              <p>
                What began as a simple Fiverr Club has blossomed into the
                vibrant community now known as the Online Entrepreneurs Club.
                From our inception, the vision has remained steadfast - creating
                a platform where knowledge sharing and collaboration flourish
                within our Sri Lankan digital community.
              </p>
              <p>
                Our community&apos;s core purpose has always been multifaceted:
                fostering knowledge exchange, building collaborative networks,
                empowering Sri Lankan youth to generate foreign revenue, and
                establishing recognition for freelance professions. Together,
                we&apos;ve built an ecosystem that nurtures freelancers and
                techpreneurs across Sri Lanka.
              </p>
              <p>
                The OECSL mission extends far beyond community building. Through
                collective effort, we&apos;ve bridged crucial gaps by connecting
                government bodies, service providers, financial institutions,
                international platforms, and communities. This extensive network
                continues to create unprecedented opportunities and recognition
                for Sri Lankan digital talent in the global arena.
              </p>
            </div>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="organization-structure section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
        <div className="container">
          <h2 className="section-title">Organization Structure</h2>

          <div className="org-social">
            <a
              href="https://facebook.com/groups/oecsl"
              className="social-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook /> Facebook Group
            </a>
            <a
              href="https://facebook.com/oecsl"
              className="social-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaFacebook /> Facebook Page
            </a>
          </div>

          <div className="org-hierarchy">
            <div className="org-founder">
              <div className="member-card founder">
                <img
                  src="/images/team/lasantha.jpg"
                  alt="Lasantha Wickramasinghe"
                />
                <div className="member-info">
                  <h3>Lasantha Wickramasinghe</h3>
                  <span className="role">Founder & Chairman</span>
                </div>
              </div>
            </div>

            <div className="org-level executive">
              <h3 className="level-title">
                <FaUserShield /> Executive Committee
              </h3>
              <div className="members-grid">
                {executiveCommittee.map((member) => (
                  <div key={member.name} className="member-card">
                    <img src={member.image} alt={member.name} />
                    <div className="member-info">
                      <h4>{member.name}</h4>
                      <span className="role">{member.role}</span>
                      <span className="position">{member.position}</span>
                      <a
                        href={member.facebook}
                        className="social-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebook />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="org-level moderators">
              <h3 className="level-title">
                <FaUserCog /> Group Moderators
              </h3>
              <div className="members-grid">
                {moderators.map((member) => (
                  <div key={member.name} className="member-card">
                    <img src={member.image} alt={member.name} />
                    <div className="member-info">
                      <h4>{member.name}</h4>
                      <span className="role">{member.role}</span>
                      <span className="position">{member.position}</span>
                      <a
                        href={member.facebook}
                        className="social-link"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFacebook />
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="milestones section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
        <div className="container">
          <h2 className="section-title">Key Milestones</h2>
          <div className="milestones-grid">
            <div className="milestone-card">
              <div className="milestone-icon">
                <i className="fas fa-users"></i>
              </div>
              <h3>Community Growth</h3>
              <p>
                From Fiverr Club to Sri Lanka&apos;s largest digital
                entrepreneur community
              </p>
            </div>
            <div className="milestone-card">
              <div className="milestone-icon">
                <i className="fas fa-handshake"></i>
              </div>
              <h3>Strategic Partnerships</h3>
              <p>
                Collaborations with ICTA, Sri Lanka Telecom, and other key
                institutions
              </p>
            </div>
            <div className="milestone-card">
              <div className="milestone-icon">
                <i className="fas fa-certificate"></i>
              </div>
              <h3>Legal Recognition</h3>
              <p>
                Incorporated as Online Entrepreneurs Club Limited and trademark
                registration
              </p>
            </div>
            <div className="milestone-card">
              <div className="milestone-icon">
                <i className="fas fa-globe"></i>
              </div>
              <h3>National Impact</h3>
              <p>
                Nationwide programs and initiatives reaching all 25 districts
              </p>
            </div>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="journey section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        <div className="container">
          <h2 className="section-title">The Journey</h2>
          <div className="timeline">
            <div className="timeline-item">
              <div className="year">2010</div>
              <h3>The Foundation</h3>
              <p>
                Established by a group of visionary tech enthusiasts with the
                dream of making Sri Lanka a technology hub. Started mentoring
                young developers and organizing tech meetups.
              </p>
            </div>

            <div className="timeline-item">
              <div className="year">2014</div>
              <h3>Digital Innovation Era</h3>
              <p>
                Launched first nationwide tech innovation challenge. Partnered
                with universities to create tech entrepreneurship programs.
                Reached 10,000 active members across the country.
              </p>
            </div>

            <div className="timeline-item">
              <div className="year">2018</div>
              <h3>Community Impact & Growth</h3>
              <p>
                Organized flood recovery donation campaign for victims in Sri
                Lanka. Sri Lankan projects from our community gained
                international recognition. Established partnerships with global
                tech companies and started youth innovation grants program.
              </p>
            </div>

            <div className="timeline-item">
              <div className="year">2020</div>
              <h3>Digital Transformation Leadership</h3>
              <p>
                Led Sri Lanka&apos;s digital adaptation during the pandemic.
                Trained 50,000+ youth in digital skills. Launched rural tech
                entrepreneurship initiatives. Collaborated with ICTA for
                nationwide events and knowledge sharing programs.
              </p>
            </div>

            <div className="timeline-item">
              <div className="year">2022</div>
              <h3>Official Incorporation</h3>
              <p>
                Incorporated in Sri Lanka as Online Entrepreneurs Club Limited
                by guarantee. Appointed executive committee and new moderator
                panel. Signed MOU with Sri Lanka Telecom to provide better
                telecommunication solutions for freelancers.
              </p>
            </div>

            <div className="timeline-item">
              <div className="year">2023</div>
              <h3>Regional Expansion</h3>
              <p>
                Held major freelancer event in Polonnaruwa in collaboration with
                Young Entrepreneurs Community. Expanded programs to reach rural
                areas. Enhanced partnerships for nationwide impact.
              </p>
            </div>

            <div className="timeline-item">
              <div className="year">2024</div>
              <h3>Institutional Strengthening</h3>
              <p>
                Copyrighted trademark logo under Sri Lanka Intellectual Property
                Act. 175,000+ members strong. Driving national digital economy
                initiatives. Creating opportunities for the next generation of
                Sri Lankan innovators and entrepreneurs.
              </p>
            </div>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="ecosystem section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        <div className="container">
          <h2 className="section-title">Our Ecosystem</h2>
          <div className="ecosystem-grid">
            <motion.div
              className="ecosystem-card"
              whileHover={{
                scale: 1.05,
                boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)',
              }}
              transition={{ duration: 0.2 }}
            >
              <div className="ecosystem-icon">
                <FaLandmark className="icon" />
              </div>
              <h3>Government Collaboration</h3>
              <p>
                Working closely with government institutions to create policies
                and initiatives supporting digital entrepreneurs
              </p>
              <div className="ecosystem-stats">
                <span>10+ Government Partners</span>
              </div>
            </motion.div>

            <motion.div
              className="ecosystem-card"
              whileHover={{
                scale: 1.05,
                boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)',
              }}
              transition={{ duration: 0.2 }}
            >
              <div className="ecosystem-icon">
                <FaNetworkWired className="icon" />
              </div>
              <h3>Service Provider Network</h3>
              <p>
                Partnerships with telecom, banking, and other service providers
                to create tailored solutions for freelancers
              </p>
              <div className="ecosystem-stats">
                <span>25+ Service Partners</span>
              </div>
            </motion.div>

            <motion.div
              className="ecosystem-card"
              whileHover={{
                scale: 1.05,
                boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)',
              }}
              transition={{ duration: 0.2 }}
            >
              <div className="ecosystem-icon">
                <FaUniversity className="icon" />
              </div>
              <h3>Financial Integration</h3>
              <p>
                Collaborations with financial institutions to facilitate
                international transactions and financial services
              </p>
              <div className="ecosystem-stats">
                <span>15+ Financial Partners</span>
              </div>
            </motion.div>

            <motion.div
              className="ecosystem-card"
              whileHover={{
                scale: 1.05,
                boxShadow: '0 8px 30px rgba(0, 0, 0, 0.12)',
              }}
              transition={{ duration: 0.2 }}
            >
              <div className="ecosystem-icon">
                <FaGlobe className="icon" />
              </div>
              <h3>International Relations</h3>
              <p>
                Connections with global platforms and communities to create
                opportunities for Sri Lankan talent
              </p>
              <div className="ecosystem-stats">
                <span>30+ Global Partners</span>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="values section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        <div className="container">
          <h2 className="section-title">Our Core Values</h2>
          <div className="values-grid">
            <motion.div
              className="value-item"
              whileHover={{
                scale: 1.05,
                boxShadow: '0 0 25px rgba(0, 240, 255, 0.2)',
              }}
            >
              <FaUsers className="value-icon" />
              <h3>Youth Empowerment</h3>
              <p>
                Nurturing young talent and creating pathways for Sri Lankan
                youth to become global technology leaders and innovators.
              </p>
              <div className="value-highlight">
                <span>50,000+ Youth Trained</span>
              </div>
            </motion.div>

            <motion.div
              className="value-item"
              whileHover={{
                scale: 1.05,
                boxShadow: '0 0 25px rgba(0, 240, 255, 0.2)',
              }}
            >
              <FaHandshake className="value-icon" />
              <h3>National Development</h3>
              <p>
                Contributing to Sri Lanka&apos;s economic growth through
                technology innovation and digital entrepreneurship initiatives.
              </p>
              <div className="value-highlight">
                <span>25 Districts Impacted</span>
              </div>
            </motion.div>

            <motion.div
              className="value-item"
              whileHover={{
                scale: 1.05,
                boxShadow: '0 0 25px rgba(0, 240, 255, 0.2)',
              }}
            >
              <FaLightbulb className="value-icon" />
              <h3>Innovation Culture</h3>
              <p>
                Fostering a culture of innovation and problem-solving to address
                local challenges with global technology solutions.
              </p>
              <div className="value-highlight">
                <span>1000+ Startups Launched</span>
              </div>
            </motion.div>

            <motion.div
              className="value-item"
              whileHover={{
                scale: 1.05,
                boxShadow: '0 0 25px rgba(0, 240, 255, 0.2)',
              }}
            >
              <FaHeart className="value-icon" />
              <h3>Community Impact</h3>
              <p>
                Building a stronger Sri Lanka through technology education,
                mentorship, and creating sustainable employment opportunities.
              </p>
              <div className="value-highlight">
                <span>14 Years of Service</span>
              </div>
            </motion.div>
          </div>
        </div>
      </motion.section>

      <motion.section
        className="future section"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 1 }}
      >
        <div className="container">
          <h2 className="section-title">Looking Forward</h2>
          <div className="future-description">
            As we continue our 14-year journey, our commitment to Sri
            Lanka&apos;s technological advancement grows stronger. We&apos;re
            expanding our initiatives in AI, blockchain, and emerging
            technologies, while ensuring these advancements benefit every corner
            of our nation.
          </div>
          <div className="future-description">
            Join us in building a technologically empowered Sri Lanka, where
            every young person has the opportunity to contribute to our
            nation&apos;s digital future.
          </div>
        </div>
      </motion.section>
    </div>
  );
}

export default About;
