import React, { useState, useEffect } from 'react';
import { db, storage } from '../firebase';
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

function EventManagement() {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [newEvent, setNewEvent] = useState({
    title: '',
    date: '',
    description: '',
    imageUrl: '',
    reservationLink: '',
    liveStreamLink: '',
  });
  const [editingEvent, setEditingEvent] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    setLoading(true);
    setError(null);
    try {
      const eventsCollection = collection(db, 'events');
      const eventSnapshot = await getDocs(eventsCollection);
      const eventList = eventSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setEvents(eventList);
    } catch (err) {
      console.error('Error fetching events:', err);
      setError('Failed to fetch events. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (file) => {
    if (!file) return null;
    const storageRef = ref(storage, `event-images/${file.name}`);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  const addEvent = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      let imageUrl = newEvent.imageUrl;
      if (imageFile) {
        imageUrl = await handleImageUpload(imageFile);
      }
      await addDoc(collection(db, 'events'), { ...newEvent, imageUrl });
      setNewEvent({
        title: '',
        date: '',
        description: '',
        imageUrl: '',
        reservationLink: '',
        liveStreamLink: '',
      });
      setImageFile(null);
      await fetchEvents();
    } catch (err) {
      console.error('Error adding event:', err);
      setError('Failed to add event. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const updateEvent = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    try {
      let imageUrl = editingEvent.imageUrl;
      if (imageFile) {
        imageUrl = await handleImageUpload(imageFile);
      }
      await updateDoc(doc(db, 'events', editingEvent.id), {
        ...editingEvent,
        imageUrl,
      });
      setEditingEvent(null);
      setImageFile(null);
      await fetchEvents();
    } catch (err) {
      console.error('Error updating event:', err);
      setError('Failed to update event. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const deleteEvent = async (id) => {
    if (window.confirm('Are you sure you want to delete this event?')) {
      setLoading(true);
      setError(null);
      try {
        await deleteDoc(doc(db, 'events', id));
        await fetchEvents();
      } catch (err) {
        console.error('Error deleting event:', err);
        setError('Failed to delete event. Please try again.');
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading) return <div>Loading events...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="admin-content">
      <h2 className="section-title">Event Management</h2>
      <form
        onSubmit={editingEvent ? updateEvent : addEvent}
        className="event-form card"
      >
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="title">Event Title</label>
            <input
              id="title"
              type="text"
              placeholder="Enter event title"
              value={editingEvent ? editingEvent.title : newEvent.title}
              onChange={(e) =>
                editingEvent
                  ? setEditingEvent({ ...editingEvent, title: e.target.value })
                  : setNewEvent({ ...newEvent, title: e.target.value })
              }
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="date">Event Date and Time</label>
            <input
              id="date"
              type="datetime-local"
              value={editingEvent ? editingEvent.date : newEvent.date}
              onChange={(e) =>
                editingEvent
                  ? setEditingEvent({ ...editingEvent, date: e.target.value })
                  : setNewEvent({ ...newEvent, date: e.target.value })
              }
              required
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="description">Event Description</label>
            <textarea
              id="description"
              placeholder="Enter event description"
              value={
                editingEvent ? editingEvent.description : newEvent.description
              }
              onChange={(e) =>
                editingEvent
                  ? setEditingEvent({
                      ...editingEvent,
                      description: e.target.value,
                    })
                  : setNewEvent({ ...newEvent, description: e.target.value })
              }
              required
            ></textarea>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="imageUrl">Image URL</label>
            <input
              id="imageUrl"
              type="text"
              placeholder="Enter image URL"
              value={editingEvent ? editingEvent.imageUrl : newEvent.imageUrl}
              onChange={(e) =>
                editingEvent
                  ? setEditingEvent({
                      ...editingEvent,
                      imageUrl: e.target.value,
                    })
                  : setNewEvent({ ...newEvent, imageUrl: e.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="imageFile" className="file-input-label">
              Upload Image
              <input
                id="imageFile"
                type="file"
                accept="image/*"
                onChange={(e) => setImageFile(e.target.files[0])}
                className="file-input"
              />
            </label>
            {imageFile && <span className="file-name">{imageFile.name}</span>}
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label htmlFor="reservationLink">Reservation Link</label>
            <input
              id="reservationLink"
              type="url"
              placeholder="Enter reservation link"
              value={
                editingEvent
                  ? editingEvent.reservationLink
                  : newEvent.reservationLink
              }
              onChange={(e) =>
                editingEvent
                  ? setEditingEvent({
                      ...editingEvent,
                      reservationLink: e.target.value,
                    })
                  : setNewEvent({
                      ...newEvent,
                      reservationLink: e.target.value,
                    })
              }
            />
          </div>
          <div className="form-group">
            <label htmlFor="liveStreamLink">Live Stream Link</label>
            <input
              id="liveStreamLink"
              type="url"
              placeholder="Enter live stream link"
              value={
                editingEvent
                  ? editingEvent.liveStreamLink
                  : newEvent.liveStreamLink
              }
              onChange={(e) =>
                editingEvent
                  ? setEditingEvent({
                      ...editingEvent,
                      liveStreamLink: e.target.value,
                    })
                  : setNewEvent({ ...newEvent, liveStreamLink: e.target.value })
              }
            />
          </div>
        </div>
        <div className="form-actions">
          <button type="submit" className="btn btn-primary" disabled={loading}>
            {editingEvent ? 'Update Event' : 'Add Event'}
          </button>
          {editingEvent && (
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => setEditingEvent(null)}
              disabled={loading}
            >
              Cancel Edit
            </button>
          )}
        </div>
      </form>
      <div className="card">
        <h3>Existing Events</h3>
        {events.length > 0 ? (
          <table>
            <thead>
              <tr>
                <th>Title</th>
                <th>Date</th>
                <th>Description</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event) => (
                <tr key={event.id}>
                  <td>{event.title}</td>
                  <td>{new Date(event.date).toLocaleString()}</td>
                  <td>{event.description.substring(0, 50)}...</td>
                  <td>
                    <button
                      className="btn btn-warning"
                      onClick={() => setEditingEvent(event)}
                      disabled={loading}
                    >
                      Edit
                    </button>
                    <button
                      className="btn btn-danger"
                      onClick={() => deleteEvent(event.id)}
                      disabled={loading}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No events found.</p>
        )}
      </div>
    </div>
  );
}

export default EventManagement;
