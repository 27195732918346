import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Navigate } from 'react-router-dom';
import UserManagement from './UserManagement';
import EventManagement from './EventManagement';
import PostManagement from './PostManagement';
import PageContentManagement from './PageContentManagement';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';
import CatalogManagement from './CatalogManagement';
import BadgeManagement from './BadgeManagement';
import FeedbackManagement from './FeedbackManagement';

function Admin() {
  const [selectedOption, setSelectedOption] = useState(null);
  const { currentUser } = useAuth();
  const [stats, setStats] = useState({
    users: 0,
    events: 0,
    posts: 0,
  });

  useEffect(() => {
    fetchStats();
  }, []);

  const fetchStats = async () => {
    try {
      const usersCount = await getDocs(collection(db, 'users'));
      const eventsCount = await getDocs(collection(db, 'events'));
      const postsCount = await getDocs(collection(db, 'posts'));

      setStats({
        users: usersCount.size,
        events: eventsCount.size,
        posts: postsCount.size,
      });
    } catch (error) {
      console.error('Error fetching stats:', error);
    }
  };

  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  if (!currentUser.isAdmin) {
    return (
      <div className="container">
        <h1>Access Denied</h1>
        <p>You do not have permission to access the admin dashboard.</p>
      </div>
    );
  }

  const renderContent = () => {
    switch (selectedOption) {
      case 'users':
        return <UserManagement />;
      case 'events':
        return <EventManagement />;
      case 'posts':
        return <PostManagement />;
      case 'content':
        return <PageContentManagement />;
      case 'catalogs':
        return <CatalogManagement />;
      case 'badges':
        return <BadgeManagement />;
      case 'feedback':
        if (!currentUser?.isAdmin) {
          return (
            <div className="access-denied">
              <h3>Access Denied</h3>
              <p>You need admin privileges to access this section.</p>
            </div>
          );
        }
        return <FeedbackManagement />;
      default:
        return (
          <div className="admin-home">
            <h2>Welcome to the Admin Dashboard</h2>
            <div className="admin-stats">
              <div className="stat-card">
                <h3>Users</h3>
                <p>{stats.users}</p>
                <button
                  onClick={() => setSelectedOption('users')}
                  className="btn btn-primary"
                >
                  Manage Users
                </button>
              </div>
              <div className="stat-card">
                <h3>Events</h3>
                <p>{stats.events}</p>
                <button
                  onClick={() => setSelectedOption('events')}
                  className="btn btn-primary"
                >
                  Manage Events
                </button>
              </div>
              <div className="stat-card">
                <h3>Posts</h3>
                <p>{stats.posts}</p>
                <button
                  onClick={() => setSelectedOption('posts')}
                  className="btn btn-primary"
                >
                  Manage Posts
                </button>
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="admin-dashboard">
      <h1 className="page-title">Admin Dashboard</h1>
      <div className="admin-nav">
        <button
          onClick={() => setSelectedOption(null)}
          className={`btn ${!selectedOption ? 'active' : ''}`}
        >
          Dashboard Home
        </button>
        <button
          onClick={() => setSelectedOption('users')}
          className={`btn ${selectedOption === 'users' ? 'active' : ''}`}
        >
          Manage Users
        </button>
        <button
          onClick={() => setSelectedOption('events')}
          className={`btn ${selectedOption === 'events' ? 'active' : ''}`}
        >
          Manage Events
        </button>
        <button
          onClick={() => setSelectedOption('posts')}
          className={`btn ${selectedOption === 'posts' ? 'active' : ''}`}
        >
          Manage Posts
        </button>
        <button
          onClick={() => setSelectedOption('content')}
          className={`btn ${selectedOption === 'content' ? 'active' : ''}`}
        >
          Manage Page Content
        </button>
        <button
          onClick={() => setSelectedOption('catalogs')}
          className={`btn ${selectedOption === 'catalogs' ? 'active' : ''}`}
        >
          Manage Catalogs
        </button>
        <button
          onClick={() => setSelectedOption('badges')}
          className={`btn ${selectedOption === 'badges' ? 'active' : ''}`}
        >
          Manage Badges
        </button>
        <button
          onClick={() => setSelectedOption('feedback')}
          className={`btn ${selectedOption === 'feedback' ? 'active' : ''}`}
        >
          Manage Feedback
        </button>
      </div>
      <div className="admin-content">{renderContent()}</div>
    </div>
  );
}

export default Admin;
